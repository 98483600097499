import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Select, message } from "antd";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "../../../components/authorize/Authorize";
import { Loader } from "../../../components/loader/Loader";
import { Permissions } from "../../nav/Nav";
import { navSelector } from "../../nav/navSlice";
import styles from "../Profile.module.css";
import { createLocation, getInfo, profileSelector } from "../profileSlice";

export function LocationInfo(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);
  const { details, initLoading, loading } = useSelector(profileSelector);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey:
        process.env.NODE_ENV === "development"
          ? "AIzaSyAeFNDHgCLe3HPdusNULKUX5n2ivC0HHJA"
          : "AIzaSyB85I8bH46p3ZTmNBYdnoatQl3iPw25ls0",
    });
  const [locationChosen, setLocationChosen] = useState();
  const [predictionOptions, setPredictionOptions] = useState([]);

  useEffect(() => {
    if (currentNode.id) {
      dispatch(getInfo({ id: currentNode.id }));
    }
  }, [dispatch, currentNode.id]);

  useEffect(() => {
    if (placePredictions.length) {
      setPredictionOptions(
        placePredictions.map((p) => (
          <Select.Option key={p.place_id} value={p.place_id}>
            {p.description}
          </Select.Option>
        ))
      );
    }
  }, [placePredictions]);

  useEffect(() => {
    if (details.id) {
      form.setFieldsValue({
        address: details.address,
      });
    }
  }, [details, form]);

  const getPlaceDetails = (val) => {
    placesService.getDetails(
      {
        placeId: val,
      },
      (p) => {
        setLocationChosen({
          name: p.name,
          address_components: p.address_components,
          address: p.formatted_address,
          geometry: {
            location: {
              lat: p.geometry.location.lat(),
              lng: p.geometry.location.lng(),
            },
          },
          place_id: p.place_id,
          utc_offset_minutes: p.utc_offset_minutes,
          vicinity: p.vicinity,
          types: p.types,
          zip: p.address_components.find((a) => a.types.includes("postal_code"))
            ?.short_name,
          state: p.address_components.find(
            (a) =>
              a.types.includes("administrative_area_level_1") &&
              a.types.includes("political")
          )?.short_name,
          country: p.address_components.find(
            (a) => a.types.includes("country") && a.types.includes("political")
          )?.short_name,
        });
      }
    );
  };

  const handleSubmit = () => {
    if (locationChosen) {
      dispatch(
        createLocation({
          id: details.id,
          data: { location: locationChosen },
        })
      ).then((res) => {
        if (res.type === "node/locations/create/fulfilled") {
          message.success({
            content: "Locations updated successfully.",
            icon: <CheckCircleOutlined />,
          });
          props.onUpdated(res.payload);
        }
      });
    }
  };

  return (
    <>
      <div>
        <div className="subtitle desc-color">Locations</div>
      </div>
      {initLoading ? (
        <div className={styles.loadingContainer}>
          <Loader size={65} />
        </div>
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          requiredMark={false}
          colon={false}
          layout="vertical"
          className={`${styles.form} mt-24`}
          disabled={
            currentNode?.id &&
            !currentNode?.permissions?.includes(Permissions.data)
          }
        >
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item
              name="address"
              label="Primary address"
              rules={[
                {
                  required: true,
                  message: "Please input your organizations's address!",
                },
              ]}
            >
              <Select
                showSearch
                className="ta-l"
                suffixIcon={null}
                defaultActiveFirstOption={false}
                filterOption={false}
                notFoundContent={null}
                onSearch={(val) => {
                  getPlacePredictions({
                    input: val,
                    componentRestrictions: {
                      // Restrict search to US territories
                      country: ["us", "pr", "vi", "gu", "mp"],
                    },
                  });
                }}
                onChange={getPlaceDetails}
              >
                {predictionOptions}
              </Select>
            </Form.Item>
          </Authorize>
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item className="mt-40">
              <Button htmlType="submit" size="large" loading={loading}>
                Save Changes
              </Button>
            </Form.Item>
          </Authorize>
        </Form>
      )}
    </>
  );
}
