import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  loggedIn: false,
  currentUser: {},
  loading: false,
  validateLoading: true,
  errors: null,
  passwordForgot: false,
  passwordReset: false,
  search: {
    results: [],
    loading: false,
    errors: null,
    totalPages: null,
    totalCount: null,
  },
};

export const login = createAsyncThunk("user/login", async (data, thunk) => {
  try {
    let response = await API.auth.login(data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const validate = createAsyncThunk("user/validate", async (_, thunk) => {
  try {
    let response = await API.auth.validate();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getUser = createAsyncThunk("user/sessions", async (_, thunk) => {
  try {
    let response = await API.auth.getUser();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (data, thunk) => {
    try {
      let response = await API.auth.forgotPassword(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data, thunk) => {
    try {
      let response = await API.auth.resetPassword(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (data, thunk) => {
    try {
      let response = await API.auth.register({ ...data });
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk("user/logout", async (_, thunk) => {
  try {
    let response = await API.auth.logout();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateUser = createAsyncThunk(
  "user/update",
  async (data, thunk) => {
    try {
      let response = await API.auth.update(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const waitlist = createAsyncThunk(
  "user/waitlist",
  async (data, thunk) => {
    try {
      let response = await API.auth.waitlist(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateHasSeen = createAsyncThunk(
  "user/has_seen",
  async (data, thunk) => {
    try {
      let response = await API.auth.has_seen(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const searchNonprofit = createAsyncThunk(
  "user/search",
  async (data, thunk) => {
    try {
      let response = await API.discover.publicSearch(
        data.query,
        data.page,
        data.per
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const verify = createAsyncThunk("user/verify", async (data, thunk) => {
  try {
    let response = await API.auth.verify(data);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const verifySend = createAsyncThunk(
  "user/verifySend",
  async (_, thunk) => {
    try {
      let response = await API.auth.verifySend();
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const validateEmail = createAsyncThunk(
  "node/validateEmail",
  async (data, thunk) => {
    try {
      let response = await API.auth.validateEmail(data.nodeId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const validateCode = createAsyncThunk(
  "node/validateCode",
  async (data, thunk) => {
    try {
      let response = await API.auth.validateCode(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const extRequestAdmin = createAsyncThunk(
  "node/admins/extRequest",
  async (data, thunk) => {
    try {
      let response = await API.profile.admins.extRequest(
        data.nodeId,
        data.data
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const requestAdmin = createAsyncThunk(
  "node/admins/request",
  async (data, thunk) => {
    try {
      let response = await API.profile.admins.request(data.nodeId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const claim = createAsyncThunk(
  "node/admins/claim",
  async (data, thunk) => {
    try {
      let response = await API.profile.admins.claim(data.nodeId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loggedIn = false;
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedIn = true;
      state.currentUser = action.payload;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
    [validate.pending]: (state) => {
      state.validateLoading = true;
    },
    [validate.fulfilled]: (state, action) => {
      state.validateLoading = false;
      state.currentUser = action.payload;
    },
    [validate.rejected]: (state, action) => {
      state.validateLoading = false;
      state.errors = action.payload?.errors;
    },
    [getUser.pending]: (state) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
    [register.pending]: (state) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      state.loggedIn = true;
      state.currentUser = action.payload;
    },
    [register.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors
        ? action.payload.errors
        : ["Sign Up failed, try again later"];
    },
    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
    [searchNonprofit.pending]: (state) => {
      state.search.loading = true;
    },
    [searchNonprofit.fulfilled]: (state, action) => {
      state.search.loading = false;
      state.search.results = action.payload.results;
      state.search.totalPages = action.payload.total_pages;
      state.search.totalCount = action.payload.total_count;
    },
    [searchNonprofit.rejected]: (state, action) => {
      state.search.loading = false;
      state.search.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors } = authSlice.actions;

export const authSelector = (state) => state.auth;
export const searchSelector = (state) => state.auth.search;

export default authSlice.reducer;
