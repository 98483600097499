import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  nodes: [],
  currentNode: {},
  loading: true,
  errors: null,
  requested: {
    nodes: [],
    loading: false,
    errors: null,
  },
};

export const getAdminNodes = createAsyncThunk(
  "user/admin_nodes",
  async (_, thunk) => {
    try {
      let response = await API.auth.adminNodes();
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getRequestedNodes = createAsyncThunk(
  "user/requested_nodes",
  async (_, thunk) => {
    try {
      let response = await API.auth.requestedNodes();
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    updateCurrentNode: (state, action) => {
      state.currentNode = action.payload;
    },
    changeCurrentNode: (state, action) => {
      state.currentNode = action.payload
        ? state.nodes.find((o) => o.id === action.payload)
        : {};
    },
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getAdminNodes.pending]: (state) => {
      state.loading = true;
    },
    [getAdminNodes.fulfilled]: (state, action) => {
      state.loading = false;
      state.nodes = action.payload.nodes;
      state.currentNode =
        action.payload.nodes.length > 0 ? action.payload.nodes[0] : {};
    },
    [getAdminNodes.rejected]: (state, action) => {
      state.errors = action.payload?.errors;
    },
    [getRequestedNodes.pending]: (state) => {
      state.requested.loading = true;
    },
    [getRequestedNodes.fulfilled]: (state, action) => {
      state.requested.loading = false;
      state.requested.nodes = action.payload.nodes;
    },
    [getRequestedNodes.rejected]: (state, action) => {
      state.requested.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors, updateCurrentNode, changeCurrentNode } =
  navSlice.actions;

export const navSelector = (state) => state.nav;
export const requestedSelector = (state) => state.nav.requested;

export default navSlice.reducer;
