import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/img/bg/setup.svg";
import { Logo } from "../../components/logo/Logo";
import { Paginate } from "../../components/paginate/Paginate";
import tracker from "../../tracker";
import styles from "./Auth.module.css";
import { Header } from "./Header";
import {
  authSelector,
  clearErrors,
  searchNonprofit,
  searchSelector,
  validate,
} from "./authSlice";

const PER_PAGE = 5;

export function Search() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector(authSelector);
  const { results, loading, errors, totalPages, totalCount } =
    useSelector(searchSelector);
  const [query, setQuery] = useState("");
  const [searched, setSearched] = useState("");
  const [initLoad, setInitLoad] = useState(false);
  const [selected, setSelected] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [fromPlatform, setFromPlatform] = useState("");

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
    if (new URLSearchParams(window.location.search).get("src") === "platform") {
      dispatch(validate());
      setFromPlatform(true);
    }
  }, [dispatch]);

  const search = () => {
    if (query.length > 0) {
      setSearched(query);
      setCurrentPage(1);
      dispatch(searchNonprofit({ query: query, page: 1, per: PER_PAGE }));
      setInitLoad(true);
    }

    //// TRACK
    tracker.track(`signup-search-click`, {
      query: query,
    });
    ////
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(searchNonprofit({ query: query, page: page, per: PER_PAGE }));
  };

  const formatEin = (ein) => {
    return ein?.replace(/(\d{2})(\d{7})/, "$1-$2");
  };

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      />
      <Header />

      <div className={styles.containerLg}>
        <div className={styles.content}>
          <Space.Compact align="start" size={0} block={true}>
            <div className={styles.infoContainer}>
              <div className={styles.info}>
                <div className={`accent accent-color`}>Account Type</div>
                <div className="mt-16">
                  <Row align="middle">
                    <Col span={14}>
                      <div className="subtitle">Organization</div>
                    </Col>
                    <Col span={10} className="ta-r">
                      <div className="tag yellow">Most powerful</div>
                    </Col>
                  </Row>
                  <div className="mt-16 sub-color">
                    Unlock the power of AI for your nonprofit. Let's streamline
                    your workflow, buy discounted goods, and maximize your
                    impact.
                  </div>
                  <div className="mt-16 sub-color">
                    <ul>
                      <li>Free unlimited AI Assistant</li>
                      <li>
                        Ability to connect multiple data sources, including 990
                        forms
                      </li>
                      <li>Ability to invite multiple users</li>
                      <li>Access to a discount retail marketplace</li>
                      <li>Requires and EIN and Vetting</li>
                      <li>Personal playground for private data</li>
                    </ul>
                  </div>
                </div>
              </div>
              {!currentUser?.id && (
                <div className={`${styles.tag} mt-16`}>
                  <div className="white-color">Want to skip these steps?</div>
                  <div
                    className="mt-16 link white-color underline pointer"
                    onClick={() => navigate("/register")}
                  >
                    Make a personal account so you can quickly try it out.
                  </div>
                </div>
              )}
            </div>

            {selected?.id ? (
              <div className="w-100">
                <div className="pt-40 pl-40 pr-40">
                  <Button
                    size="large"
                    type="link"
                    onClick={() => setSelected({})}
                  >
                    <ArrowLeftOutlined />
                    &nbsp;Back
                  </Button>
                </div>
                <div className={styles.divider} />
                <div className="pt-24 pl-40 pr-40 pb-40">
                  <div className={styles.searchCardStatic}>
                    <Space size={16}>
                      <div>
                        <Logo
                          size="xxl"
                          type="custom"
                          data={{ name: selected.name, src: selected.logo }}
                        />
                      </div>
                      <div>
                        <div className="bold-body">{selected.name}</div>
                        <div className="sub-color">{selected.address}</div>
                        <div className="sub-color">
                          EIN: {formatEin(selected.ein)}
                        </div>
                        <div className="mt-8">
                          {selected.sponsored && (
                            <div className="tag red mr-8">
                              Fiscally Sponsored
                            </div>
                          )}
                          {selected.claimed ? (
                            <div className="tag green">Claimed</div>
                          ) : (
                            <div className="tag yellow">Unclaimed</div>
                          )}
                        </div>
                      </div>
                    </Space>
                  </div>
                  <div className={styles.claimBox}>
                    {selected.claimed ? (
                      <div>
                        <div className="desc-color">
                          Are you a part of this organization?
                        </div>
                        <div className="mt-16">
                          <Button
                            size="large"
                            onClick={() =>
                              navigate(
                                `/register/${encodeURIComponent(
                                  window.btoa(JSON.stringify(selected))
                                )}${fromPlatform ? "?src=platform" : ""}`
                              )
                            }
                          >
                            Request access
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="desc-color">
                          Would you like to claim this account?
                        </div>
                        <div className="mt-16">
                          <Button
                            size="large"
                            onClick={() =>
                              navigate(
                                `/register/${encodeURIComponent(
                                  window.btoa(JSON.stringify(selected))
                                )}${fromPlatform ? "?src=platform" : ""}`
                              )
                            }
                          >
                            Claim Account
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={`desc-color`}>
                    Can't find your organization?{" "}
                    <span
                      className="link underline pointer"
                      onClick={() =>
                        window.open("mailto:hello@humanitas.ai", "_blank")
                      }
                    >
                      Contact support
                    </span>{" "}
                    if you believe this is an error.
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-100">
                <div className="pt-40 pl-40 pr-40">
                  <div className="h1">Find your organization</div>
                  <div className="mt-16 mb-8 sub-color">
                    Search by EIN or name to find your organization to get
                    started.
                  </div>
                </div>
                <div className="search-box">
                  <Input
                    allowClear
                    placeholder="Search"
                    size="large"
                    onChange={(e) => setQuery(e.target.value)}
                    onPressEnter={() => search()}
                    suffix={
                      <Button
                        size="large"
                        type={query.length > 0 ? "primary" : "default"}
                        className={styles.searchBtn}
                        loading={loading}
                        icon={<SearchOutlined />}
                        onClick={() => search()}
                      ></Button>
                    }
                  />
                </div>
                <div className={styles.searchContent}>
                  {!initLoad || loading ? (
                    <></>
                  ) : (
                    <>
                      <div className="desc-color">
                        {totalCount} Search{" "}
                        {totalCount === 1 ? "result" : "results"} for {searched}
                      </div>
                      <div className="mt-24">
                        {results.map((r) => (
                          <div
                            className={styles.searchCard}
                            key={r.id}
                            onClick={() => setSelected(r)}
                          >
                            <Space size={16}>
                              <div>
                                <Logo
                                  size="xxl"
                                  type="custom"
                                  data={{ name: r.name, src: r.logo }}
                                />
                              </div>
                              <div>
                                <div className="bold-body">{r.name}</div>
                                <div className="sub-color">{r.address}</div>
                                <div className="sub-color">
                                  EIN: {formatEin(r.ein)}
                                </div>
                                <div className="mt-8">
                                  {r.sponsored && (
                                    <div className="tag red mr-8">
                                      Fiscally Sponsored
                                    </div>
                                  )}
                                  {r.claimed ? (
                                    <div className="tag green">Claimed</div>
                                  ) : (
                                    <div className="tag yellow">Unclaimed</div>
                                  )}
                                </div>
                              </div>
                            </Space>
                          </div>
                        ))}
                      </div>
                      <div className="mt-24">
                        <Paginate
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                      <div className="mt-24 desc-color">
                        Can't find your organization?{" "}
                        <span
                          className="link underline pointer primary-color"
                          onClick={() =>
                            window.open("mailto:hello@humanitas.ai", "_blank")
                          }
                        >
                          Contact support
                        </span>{" "}
                        if you believe this is an error.
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Space.Compact>
        </div>
      </div>
    </>
  );
}
