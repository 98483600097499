import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FormOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Image,
  Modal,
  Row,
  Space,
  Table,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import analyzed from "../../../assets/img/analyzed.svg";
import shopping from "../../../assets/img/shopping.svg";
import { Loader } from "../../../components/loader/Loader";
import tracker from "../../../tracker";
import { navSelector } from "../../nav/navSlice";
import { EditItem } from "../../registry/EditItem";
import {
  approveAnalyzed,
  deleteAnalyzed,
  deleteNeed,
  getAllNeeds,
  needsSelector,
} from "../../registry/registrySlice";
import styles from "../Data.module.css";
import { getStatus } from "../dataSlice";

export function ApproveReceipt() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, errors } = useSelector(needsSelector);
  const [reviewOpened, setReviewOpened] = useState(false);
  const { currentNode } = useSelector(navSelector);
  const [editOpened, setEditOpened] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [completeOpened, setCompleteOpened] = useState(false);
  const [confirmOpened, setConfirmOpened] = useState(false);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getStatus({ nodeId: currentNode.id })).then((res) => {
      if (res.type === "data/status/fulfilled") {
        if (res.payload.receipt.unapproved_c === 0) {
          navigate("/data");
        }
      }
    });
  }, [currentNode]);

  const getAnalyzedNeeds = () => {
    dispatch(getAllNeeds({ nodeId: currentNode.id, approved: false }));
  };

  useEffect(() => {
    getAnalyzedNeeds();
  }, [currentNode]);

  const openEdit = (item) => {
    setCurrentItem(item);
    setEditOpened(true);
  };

  const approveAnalyzedItems = () => {
    dispatch(approveAnalyzed({ nodeId: currentNode.id })).then((res) => {
      if (res.type === "registry/approveAnalyzed/fulfilled") {
        setReviewOpened(false);
        setCompleteOpened(true);
      }
    });
  };

  const deleteAnalyzedItems = () => {
    dispatch(deleteAnalyzed({ nodeId: currentNode.id })).then((res) => {
      if (res.type === "registry/deleteAnalyzed/fulfilled") {
        navigate("/data");
        message.success({
          content: "Receipt analysis deleted successfully",
          icon: <CheckCircleOutlined />,
        });
      }
    });
  };

  const destroy = (id) => {
    dispatch(deleteNeed({ nodeId: currentNode.id, id: id })).then((res) => {
      if (res.type === "registry/deleteNeed/fulfilled") {
        getAnalyzedNeeds();
      }
    });
  };

  const columns = [
    {
      title: "Extracted",
      dataIndex: "sys_notes",
      key: "sys_notes",
    },
    {
      title: "Item Name",
      dataIndex: ["catalogue"],
      key: "name",
      render: (c) => (
        <Space align="center">
          <Image src={c.image} height={24} width={24} preview={false} />
          <div className={styles.tableText}>{c.name}</div>
        </Space>
      ),
    },
    {
      title: "Size",
      dataIndex: "sizes",
      key: "sizes",
      render: (val) => (val.length > 0 ? val.join(", ") : "Any"),
    },
    {
      title: "Brand",
      dataIndex: "brands",
      key: "brands",
      render: (val) => (val.length > 0 ? val.join(", ") : "Any"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      render: (f) => {
        switch (f) {
          case 1:
            return "Every Week";
          case 2:
            return "Every 2 Weeks";
          case 3:
            return "Every 4 Weeks";
          case 4:
            return "Every 6 Weeks";
          default:
            return "";
        }
      },
    },
    {
      title: <div className="ta-c">Edit</div>,
      key: "edit",
      render: (c) => (
        <div className="ta-c">
          <FormOutlined onClick={() => openEdit(c)} />
        </div>
      ),
    },
    {
      title: <div className="ta-c">Remove</div>,
      key: "remove",
      render: (c) => (
        <div className="ta-c">
          <DeleteOutlined onClick={() => destroy(c.id)} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={`${styles.notification} ${styles.green}`}>
        <Row align="middle" className={styles.content}>
          <Col span={20}>
            <Space size={16}>
              <CheckCircleOutlined className={styles.icon} />
              <div className={styles.type}>Receipt Analysis Complete.</div>
            </Space>
          </Col>
          <Col span={4} className="ta-r">
            <Button
              type="link"
              size="large"
              onClick={() => setReviewOpened(true)}
            >
              Review&nbsp;
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <div className="py-24">
        <div className="px-40">
          <img height="230" alt="Analyzed Receipts" src={analyzed} />
          <div className="mt-24 h1">Your In-Kind Registry is Ready!</div>
          <div className={`mt-24 ${styles.analyzeContent}`}>
            Time to review our suggestions and customize them to suit your
            specific needs.
          </div>
          <div className="mt-40">
            <div>
              <Button
                type="primary"
                size="large"
                onClick={() => setReviewOpened(true)}
              >
                Review Analysis&nbsp;
                <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        open={reviewOpened}
        footer={null}
        closable={false}
        width={1032}
        style={{ top: 80 }}
      >
        <div className={styles.approveModal}>
          <div>
            <Row>
              <Col span={20}>
                <Space size={16}>
                  <Avatar size={40} icon={<ShoppingCartOutlined />} />
                  <div className="h1">
                    Review additions to your In-Kind Registry
                  </div>
                </Space>
              </Col>
              <Col span={4} className="ta-r">
                <Button size="large" onClick={() => setReviewOpened(false)}>
                  Close
                </Button>
              </Col>
            </Row>
          </div>
          <div className={`mt-24 ${styles.reviewContent}`}>
            Our receipt analysis identified these items. Check this list over
            for any necessary changes. Please note that more specific requests
            may take longer to fulfill.
          </div>
          <div className="mt-40">
            <Row>
              <Col span={12}>
                <Button
                  size="large"
                  danger
                  onClick={() => setConfirmOpened(true)}
                >
                  <Space size={16}>
                    <div>Delete list</div>
                    <div>
                      <DeleteOutlined />
                    </div>
                  </Space>
                </Button>
              </Col>
              <Col span={12} className="ta-r">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => approveAnalyzedItems()}
                  disabled={data.length === 0}
                >
                  <Space size={16}>
                    <div>Add items to registry</div>
                    <div>
                      <ArrowRightOutlined />
                    </div>
                  </Space>
                </Button>
              </Col>
            </Row>
            <div className="mt-24">
              <Table
                bordered
                loading={{
                  indicator: <Loader size="65" />,
                  spinning: loading,
                }}
                pagination={{ position: ["none", "none"] }}
                className={`table-striped`}
                dataSource={data}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        open={editOpened}
        footer={null}
        closable={false}
        width={1000}
        style={{ top: 80 }}
      >
        <EditItem
          type="edit"
          onSuccess={() => {
            getAnalyzedNeeds();
            setEditOpened(false);
          }}
          onCancel={() => setEditOpened(false)}
          item={currentItem}
        />
      </Modal>
      <Modal
        destroyOnClose
        open={completeOpened}
        footer={null}
        closable={false}
        width={1032}
      >
        <img height="250" alt="Completed" src={shopping} />
        <div className="mt-40 h2">
          Your receipt items have been added to your registry.
        </div>
        <div className="mt-32" style={{ width: "400px" }}>
          It's time to add any additional items you might need. When you're
          satisfied with your registry go to the deliveries tab to submit your
          finalized item requests.
        </div>
        <div className="mt-40">
          <Space size={40}>
            <Button
              size="large"
              type="primary"
              onClick={() => navigate("/registry/add")}
            >
              <Space size={16}>
                <div>Add more items</div>
                <div>
                  <ArrowRightOutlined />
                </div>
              </Space>
            </Button>
            <Button size="large" onClick={() => navigate("/registry")}>
              View Registry
            </Button>
          </Space>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        open={confirmOpened}
        footer={null}
        closable={false}
        width={560}
      >
        <div className="ta-c">
          <div className="h2">Delete entire item list and analysis?</div>
          <div className="mt-24">
            You will lose all your processed additions to the In-Kind Registry
            and have to start over.
          </div>
          <div className="mt-32">
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  danger
                  size="large"
                  className="w-100"
                  onClick={() => deleteAnalyzedItems()}
                >
                  <Space size={16}>
                    <div>Delete list</div>
                    <div>
                      <DeleteOutlined />
                    </div>
                  </Space>
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className="w-100"
                  onClick={() => {
                    setConfirmOpened(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}
