import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import bg from "../../assets/img/bg/setup.svg";
import { Loader } from "../../components/loader/Loader";
import tracker from "../../tracker";
import styles from "./Auth.module.css";
import { Header } from "./Header";
import {
  authSelector,
  claim,
  clearErrors,
  extRequestAdmin,
  register,
  requestAdmin,
  validate,
  validateCode,
  validateEmail,
} from "./authSlice";

export function RegisterNode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector(authSelector);
  const { loading, errors } = useSelector(authSelector);
  const { data } = useParams();
  const [step, setStep] = useState();
  const [selected, setSelected] = useState({});
  const [codeLoading, setCodeLoading] = useState(true);
  const [validateLoading, setValidateLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [position, setPosition] = useState("");
  const [confirmForm] = Form.useForm();
  const [registerForm] = Form.useForm();

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
    if (new URLSearchParams(window.location.search).get("src") === "platform") {
      dispatch(validate());
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentUser.id) {
      confirmForm.setFieldsValue({ email: currentUser.email });
      registerForm.setFieldsValue({
        f_name: currentUser.f_name,
        l_name: currentUser.l_name,
        email: currentUser.email,
      });
    }
  }, [currentUser, confirmForm, registerForm]);

  useEffect(() => {
    if (data) {
      try {
        let parsed = JSON.parse(window.atob(data));
        if (parsed?.id) {
          setSelected(parsed);
          if (parsed?.claimed) {
            setStep(3);
          } else {
            setStep(1);
          }
          setCodeLoading(false);
        } else if (parsed?.code) {
          dispatch(validateCode({ code: parsed.code })).then((res) => {
            if (res.type === "node/validateCode/fulfilled") {
              setEmail(res.payload.email);
              setSelected(res.payload.node);
              setStep(2);
              setCode(parsed.code);
              setCodeLoading(false);
            } else {
              message.error({
                content: "Invalid Invite Code",
                icon: <CloseCircleOutlined />,
              });
            }
          });
        }
      } catch (error) {
        navigate("/");
      }
    }
  }, [data, dispatch]);

  const formatEin = (ein) => {
    return ein?.replace(/(\d{2})(\d{7})/, "$1-$2");
  };

  const handleValidate = (values) => {
    setShowError(false);
    setShowSuccess(false);
    setValidateLoading(true);
    dispatch(validateEmail({ nodeId: selected.id, data: values })).then(
      (res) => {
        setValidateLoading(false);
        if (res.type === "node/validateEmail/fulfilled") {
          if (res.payload?.validation) {
            setEmail(values.email);
            setPosition(values.position);
            setShowSuccess(true);
          } else {
            setShowError(true);
          }
        } else {
          setShowError(true);
        }
      }
    );
  };

  const handleRegister = (values) => {
    let params = values;
    if (selected?.id) {
      params = { ...params, node_id: selected.id, position: position };
    }
    if (code.length > 0) {
      params = { ...params, invite_na: code };
    }
    dispatch(register(params)).then((res) => {
      if (res.type === "user/register/fulfilled") {
        navigate("/");
      }
    });
  };

  const handleRequest = (values) => {
    let req;
    if (currentUser?.id) {
      req = dispatch(
        requestAdmin({
          nodeId: selected.id,
          data: { position: values.position },
        })
      );
    } else {
      req = dispatch(extRequestAdmin({ nodeId: selected.id, data: values }));
    }
    req.then((res) => {
      if (
        res.type === currentUser?.id
          ? "node/admins/request/fulfilled"
          : "node/admins/extRequest/fulfilled"
      ) {
        setStep(4);
      } else {
        if (res.payload?.errors) {
          message.error({
            content: res.payload?.errors[0],
            icon: <CloseCircleOutlined />,
          });
        } else {
          message.error({
            content: "Something went wrong",
            icon: <CloseCircleOutlined />,
          });
        }
      }
    });
  };

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      />
      <Header />

      <div className={styles.containerLg}>
        <div className={styles.content}>
          {codeLoading ? (
            <div className={styles.infoContainer}>
              <div className={styles.loader}>
                <Loader size="85" />
              </div>
            </div>
          ) : (
            <Space.Compact align="start" size={0} block={true}>
              <div className={styles.infoContainer}>
                <div className={styles.info}>
                  <div className={styles.searchCardChosen}>
                    <div className="accent accent-color">
                      Organization Selected
                    </div>
                    <div className="bold-body mt-8">{selected.name}</div>
                    <div className="sub-color mt-8">{selected.address}</div>
                    <div className="sub-color mt-8">
                      EIN: {formatEin(selected.ein)}
                    </div>
                    <div className="mt-12">
                      {selected.sponsored && (
                        <div className="tag red mr-8">Fiscally Sponsored</div>
                      )}
                      {selected.claimed ? (
                        <div className="tag green">Claimed</div>
                      ) : (
                        <div className="tag yellow">Unclaimed</div>
                      )}
                    </div>
                  </div>
                  {code.length === 0 && (
                    <div className="mt-32">
                      <Space
                        size={16}
                        className="bold-body dark-color pointer"
                        onClick={() => navigate("/search")}
                      >
                        <ArrowLeftOutlined />
                        Change organization
                      </Space>
                    </div>
                  )}
                </div>
              </div>

              {step === 4 ? (
                <div className="p-40">
                  <div className={styles.widthMg}>
                    <div className="h2">Request sent</div>
                    <div className="mt-24 sub-color">
                      Your request has been sent to the admins of this
                      organizational account. You will receive an email update
                      when you've been granted access to this account.
                    </div>
                    {currentUser?.id && (
                      <Button
                        className="mt-40"
                        type="primary"
                        size="large"
                        onClick={() => navigate("/account?focus=orgs")}
                      >
                        Back to platform
                      </Button>
                    )}
                  </div>
                </div>
              ) : step === 3 ? (
                <div className="p-40">
                  <div className={styles.widthMg}>
                    <div className="h2">Request access to this account</div>
                    <div className="mt-24 sub-color">
                      We need more information to process this request.
                    </div>
                  </div>
                  <div className={`mt-40 ${styles.widthSm}`}>
                    <Form
                      form={registerForm}
                      layout="vertical"
                      onFinish={handleRequest}
                      requiredMark={false}
                    >
                      <Form.Item
                        label="First name"
                        name="f_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input first name",
                          },
                        ]}
                      >
                        <Input disabled={currentUser.id ? true : false} />
                      </Form.Item>
                      <Form.Item
                        label="Last name"
                        name="l_name"
                        rules={[
                          { required: true, message: "Please input last name" },
                        ]}
                        className="mt-16"
                      >
                        <Input disabled={currentUser.id ? true : false} />
                      </Form.Item>
                      <Form.Item
                        label="Email address"
                        name="email"
                        rules={[
                          { required: true, message: "Please input email" },
                        ]}
                        className="mt-16"
                      >
                        <Input disabled={currentUser.id ? true : false} />
                      </Form.Item>
                      <Form.Item
                        name="position"
                        label="Role"
                        className="mt-16"
                        rules={[
                          {
                            required: true,
                            message: "Please input a role",
                          },
                        ]}
                      >
                        <Select placeholder="Select a role" placement="topLeft">
                          <Select.Option value="Development">
                            Development
                          </Select.Option>
                          <Select.Option value="Finance">Finance</Select.Option>
                          <Select.Option value="Communications & Content">
                            Communications & Content
                          </Select.Option>
                          <Select.Option value="Information Technology (IT)">
                            Information Technology (IT)
                          </Select.Option>
                          <Select.Option value="Partnerships & Community Engagement">
                            Partnerships & Community Engagement
                          </Select.Option>
                          <Select.Option value="Executive Leadership">
                            Executive Leadership
                          </Select.Option>
                          <Select.Option value="Operations">
                            Operations
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="mt-40"
                      >
                        <Space size={16}>
                          Send request
                          <ArrowRightOutlined />
                        </Space>
                      </Button>
                    </Form>
                  </div>
                </div>
              ) : step === 2 ? (
                <div className="p-40">
                  <div className="h1">Finish sign up</div>
                  <div className={styles.widthMg}>
                    <div className="mt-24 sub-color">
                      Your credentials have been confirmed.
                    </div>
                  </div>
                  <div className={`mt-40 ${styles.widthSm}`}>
                    <Form
                      layout="vertical"
                      onFinish={handleRegister}
                      requiredMark={false}
                      initialValues={{
                        email: email,
                      }}
                    >
                      <Form.Item
                        label="First name"
                        name="f_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name",
                          },
                        ]}
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        className="mt-16"
                        label="Last name"
                        name="l_name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name",
                          },
                        ]}
                      >
                        <Input type="text" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Email"
                        className="mt-16"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          disabled={email.length > 0 ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Password"
                        className="mt-16"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password",
                          },
                        ]}
                      >
                        <Input.Password type="password" />
                      </Form.Item>
                      <div className="mt-40 primary-color">
                        By signing up you agree to our{" "}
                        <span
                          className="link underline pointer"
                          onClick={() =>
                            window.open(
                              "https://admin.humanitas.ai/terms",
                              "_blank"
                            )
                          }
                        >
                          Terms of Service
                        </span>{" "}
                        and{" "}
                        <span
                          className="link underline pointer"
                          onClick={() =>
                            window.open(
                              "https://admin.humanitas.ai/privacy",
                              "_blank"
                            )
                          }
                        >
                          Privacy Policy
                        </span>
                        .
                      </div>
                      <div className="mt-40">
                        <Button
                          type="primary"
                          size="large"
                          htmlType="submit"
                          loading={loading}
                        >
                          <Space size={16}>
                            Finish
                            <ArrowRightOutlined />
                          </Space>
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : step === 1 ? (
                <div className="p-40">
                  <div className={styles.widthMg}>
                    <div className="h2">
                      To claim this organization we need a little more
                      information
                    </div>
                    <div className="mt-24 sub-color">
                      We need to confirm your affiliation to keep your
                      organization's data secure. Does your organization not
                      have an email domain?{" "}
                      <span
                        className="link underline pointer primary-color"
                        onClick={() =>
                          window.open("mailto:hello@humanitas.ai", "_blank")
                        }
                      >
                        Contact support
                      </span>{" "}
                      for alternative vetting.
                    </div>
                  </div>
                  <div className={`mt-40 ${styles.widthSm}`}>
                    <Form
                      form={confirmForm}
                      layout="vertical"
                      onFinish={handleValidate}
                      requiredMark={false}
                    >
                      <Form.Item
                        name="email"
                        label="Email"
                        className="mt-40"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          disabled={currentUser.id ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        name="position"
                        label="What is your role?"
                        className="mt-24"
                        rules={[
                          {
                            required: true,
                            message: "Please input your role",
                          },
                        ]}
                      >
                        <Select placeholder="Select a role">
                          <Select.Option value="Development">
                            Development
                          </Select.Option>
                          <Select.Option value="Finance">Finance</Select.Option>
                          <Select.Option value="Communications & Content">
                            Communications & Content
                          </Select.Option>
                          <Select.Option value="Information Technology (IT)">
                            Information Technology (IT)
                          </Select.Option>
                          <Select.Option value="Partnerships & Community Engagement">
                            Partnerships & Community Engagement
                          </Select.Option>
                          <Select.Option value="Executive Leadership">
                            Executive Leadership
                          </Select.Option>
                          <Select.Option value="Operations">
                            Operations
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      {showError && (
                        <div className="error-box mt-24">
                          <div className="error-title">
                            Email unable to be confirmed
                          </div>
                          <ul className="mt-8">
                            <li>
                              Check to make sure the domain matches your
                              organization's website as written on your 990
                              form.
                            </li>
                            <li>
                              If you continue facing an issue with this
                              requirement,{" "}
                              <span
                                className="link underline pointer"
                                onClick={() =>
                                  window.open(
                                    "https://form.jotform.com/232995793802066",
                                    "_blank"
                                  )
                                }
                              >
                                request manual vetting
                              </span>
                              .
                            </li>
                          </ul>
                        </div>
                      )}
                      <div className="mt-40">
                        {showSuccess ? (
                          <Space size={24}>
                            <Space size={16}>
                              <div className="bold-body">Confirmed</div>
                              <div className="bold-body success-color">
                                <CheckCircleOutlined />
                              </div>
                            </Space>
                            {currentUser.id ? (
                              <Button
                                size="large"
                                type="primary"
                                onClick={() =>
                                  dispatch(
                                    claim({
                                      nodeId: selected.id,
                                      data: {
                                        position: position,
                                      },
                                    })
                                  ).then((res) => {
                                    if (
                                      res.type === "node/admins/claim/fulfilled"
                                    ) {
                                      navigate("/account/orgs");
                                      message.success({
                                        content:
                                          "Organization successfully claimed",
                                        icon: <CheckCircleOutlined />,
                                      });
                                    }
                                  })
                                }
                              >
                                <Space size={16}>
                                  Finish claiming account
                                  <ArrowRightOutlined />
                                </Space>
                              </Button>
                            ) : (
                              <Button size="large" onClick={() => setStep(2)}>
                                <Space size={16}>
                                  Finish sign up
                                  <ArrowRightOutlined />
                                </Space>
                              </Button>
                            )}
                          </Space>
                        ) : (
                          <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={validateLoading}
                          >
                            <Space size={16}>
                              Confirm
                              <ArrowRightOutlined />
                            </Space>
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Space.Compact>
          )}
        </div>
      </div>
    </>
  );
}
