import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import analyzing from "../../../assets/img/analyzing.svg";
import { Loader } from "../../../components/loader/Loader";
import tracker from "../../../tracker";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import { getStatus } from "../dataSlice";

export function AnalyzeReceipt() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(getStatus({ nodeId: currentNode.id })).then((res) => {
        if (res.type === "data/status/fulfilled") {
          if (res.payload.receipt.processing_c === 0) {
            clearInterval(timer);
            navigate("/data/receipts/approve");
          }
        }
      });
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  }, [currentNode]);

  return (
    <>
      <div className={styles.notification}>
        <Row align="middle" className={styles.content}>
          <Col span={24}>
            <Space size={16}>
              <Loader size="50" />
              <div className={styles.type}>Receipt Analysis in Progress</div>
            </Space>
          </Col>
        </Row>
      </div>
      <div className="py-24">
        <div className="px-40">
          <img height="230" alt="Analyzing Receipts" src={analyzing} />
          <div className="mt-24 h1">Analyzing your receipts</div>
          <div className={`mt-24 ${styles.analyzeContent}`}>
            We're looking for patterns and matching items with our retail
            partners. This could take some time. Don't worry, we'll notify you
            when we're done.
          </div>
          <div className="mt-40">
            <div>
              <Button size="large" onClick={() => navigate("/registry")}>
                Return to Registry&nbsp;
                <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
