import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Button, Image, Modal, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/loader/Loader";
import tracker from "../../tracker";
import { navSelector } from "../nav/navSlice";
import { EditItem } from "./EditItem";
import styles from "./Registry.module.css";
import { getAllNeeds, needsSelector } from "./registrySlice";

export function Needs(props) {
  const dispatch = useDispatch();
  const { data, loading, errors } = useSelector(needsSelector);
  const { currentNode } = useSelector(navSelector);
  const [editOpened, setEditOpened] = useState(false);
  const [currentItem, setCurrentItem] = useState();

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  const getApprovedNeeds = () => {
    dispatch(getAllNeeds({ nodeId: currentNode.id, approved: true }));
  };

  useEffect(() => {
    getApprovedNeeds();
  }, [currentNode]);

  const openEdit = (item) => {
    setCurrentItem(item);
    setEditOpened(true);
  };

  const columns = [
    {
      title: "Item Name",
      dataIndex: ["catalogue"],
      key: "name",
      render: (c) => (
        <Space align="center">
          <Image src={c.image} height={24} width={24} preview={false} />
          <div className={styles.tableText}>{c.name}</div>
        </Space>
      ),
    },
    {
      title: "Size",
      dataIndex: "sizes",
      key: "sizes",
      render: (val) => (val.length > 0 ? val.join(", ") : "Any"),
    },
    {
      title: "Brand",
      dataIndex: "brands",
      key: "brands",
      render: (val) => (val.length > 0 ? val.join(", ") : "Any"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      render: (f) => {
        switch (f) {
          case 1:
            return "Every Week";
          case 2:
            return "Every 2 Weeks";
          case 3:
            return "Every 4 Weeks";
          case 4:
            return "Every 6 Weeks";
          default:
            return "";
        }
      },
    },
    {
      title: "Need Status",
      dataIndex: "status",
      key: "status",
      render: (s) => {
        if (s) {
          return (
            <>
              <span className={`${styles.dot} ${styles.green}`}>●</span>Active
            </>
          );
        }
        return (
          <>
            <span className={`${styles.dot} ${styles.red}`}>●</span>
            &nbsp;Disabled
          </>
        );
      },
    },
    {
      title: <div className="ta-c">Edit</div>,
      key: "edit",
      render: (c) => (
        <div className="ta-c">
          <FormOutlined
            onClick={() => {
              openEdit(c);
              //// TRACK
              tracker.track(`registry-edit-click`, {
                needID: c.id,
                needName: c?.catalogue?.name,
              });
              ////
            }}
          />
        </div>
      ),
    },
    // {
    //   title: "Remove",
    //   key: "remove",
    //   render: (c) => (
    //     <div className="ta-c">
    //       <DeleteOutlined />
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      {!loading && data?.length === 0 && (
        <div className={styles.emptyTableContainer}>
          <div>Build your registry by clicking on Add More</div>
          <div className="mt-16 ta-c">
            <Button
              type="primary"
              size="large"
              onClick={() => props.gotoTab("add")}
            >
              Add Items&nbsp;
              <ArrowRightOutlined />
            </Button>
          </div>
        </div>
      )}
      <Table
        bordered
        loading={{
          indicator: <Loader size="65" />,
          spinning: loading,
        }}
        pagination={{ position: ["none", "none"] }}
        className={`table-striped ${
          !loading && data?.length === 0 && styles.emptyFade
        }`}
        dataSource={data}
        columns={columns}
      />
      <Modal
        destroyOnClose
        open={editOpened}
        footer={null}
        closable={false}
        width={1000}
        style={{ top: 80 }}
      >
        <EditItem
          type="edit"
          onSuccess={() => {
            getApprovedNeeds();
            setEditOpened(false);
          }}
          onCancel={() => {
            setEditOpened(false);
            tracker.track(`registry-edit-cancel`, {
              needID: currentItem.id,
              needName: currentItem?.catalogue?.name,
            });
          }}
          item={currentItem}
        />
      </Modal>
    </>
  );
}
