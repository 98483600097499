import { CloseCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Row, Space, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../../../components/loader/Loader";
import { navSelector } from "../../../nav/navSlice";
import styles from "../Widget.module.css";
import { getDataWidget, widgetDataSelector } from "../widgetSlice";

export function WidgetData() {
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);
  const { data, loading, errors } = useSelector(widgetDataSelector);

  useEffect(() => {
    dispatch(getDataWidget({ nodeId: currentNode.id }));
  }, [dispatch, currentNode]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  return (
    <>
      <div className="h2 darker-color">Your Humanitas</div>
      {loading ? (
        <div className={styles.loader}>
          <Loader size="70" />
        </div>
      ) : (
        <div className={`${styles.widget}`}>
          <Row align="middle">
            <Col span={16}>
              <div className="subtitle">Data Sources</div>
            </Col>
            <Col span={8} className="ta-r">
              <Link to="/data">
                <Space size={16} className="dark-color">
                  Go to Data Sources
                  <RightOutlined />
                </Space>
              </Link>
            </Col>
          </Row>
          <div className="mt-24">
            <Row gutter={16}>
              <Col span={8}>
                <div className={`${styles.statisticVal} h1`}>
                  {data?.data_source_type_c}
                </div>
                <div className="bold-body mt-4">Types of data sources</div>
              </Col>
              <Col span={8}>
                <div className={`${styles.statisticVal} h1`}>
                  {data?.data_source_c}
                </div>
                <div className="bold-body mt-4">Documents connected</div>
              </Col>
              <Col span={8}>
                <div className={`${styles.statisticVal} h1`}>
                  {data?.page_c}
                </div>
                <div className="bold-body mt-4">Pages analyzed</div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
