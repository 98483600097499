import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Modal, Row, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/loader/Loader";
import { Logo } from "../../../components/logo/Logo";
import { Permissions } from "../../nav/Nav";
import {
  changeCurrentNode,
  getAdminNodes,
  getRequestedNodes,
  navSelector,
  requestedSelector,
} from "../../nav/navSlice";
import {
  adminsSelector,
  clearErrors,
  deleteAdmin,
} from "../../profile/profileSlice";
import styles from "../Account.module.css";

export function Orgs(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nodes, loading, currentNode } = useSelector(navSelector);
  const { errors } = useSelector(adminsSelector);
  const { nodes: requestedNodes, loading: requestedLoading } =
    useSelector(requestedSelector);
  const [chosenNode, setChosenNode] = useState(null);
  const [confirmOpened, setConfirmOpened] = useState(false);

  useEffect(() => {
    dispatch(getRequestedNodes());
  }, [dispatch]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
      dispatch(clearErrors());
    }
  }, [errors]);

  const formatEin = (ein) => {
    return ein?.replace(/(\d{2})(\d{7})/, "$1-$2");
  };

  const getRole = (p) => {
    if (p.includes(Permissions.data) && p.includes(Permissions.team)) {
      return "Admin";
    } else if (p.includes(Permissions.data)) {
      return "Editor";
    } else {
      return "Viewer";
    }
  };

  return (
    <>
      <div className="subtitle">Your organizations</div>
      <div className="mt-40">
        <div className="bold-body">Active&nbsp;({nodes.length})</div>
        <div className={styles.border}></div>
        <div className="mt-16">
          {loading ? (
            <div className={styles.loadingContainer}>
              <Loader size={65} />
            </div>
          ) : (
            nodes.map((n) => (
              <div className={styles.box} key={n.id}>
                <Row align="middle">
                  <Col span={18}>
                    <Space size={16}>
                      <div>
                        <Logo
                          size="xxl"
                          type="custom"
                          data={{ name: n.name, src: n.logo }}
                        />
                      </div>
                      <div>
                        <div className="bold-body">
                          <Space size={16}>
                            <div className={styles.tagHeight}>{n.name}</div>
                            {currentNode?.id === n.id && (
                              <div className="tag ">Current</div>
                            )}
                          </Space>
                        </div>
                        <div className="sub-color mt-8">
                          {getRole(n.permissions)}
                        </div>
                      </div>
                    </Space>
                  </Col>
                  <Col span={6} className="ta-r">
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        theme: "dark",
                        items: [
                          {
                            key: "troubleshoot",
                            onClick: () => dispatch(changeCurrentNode(n.id)),
                            label: (
                              <div className={styles.navOption}>
                                Go to organization profile
                              </div>
                            ),
                          },
                          {
                            key: "welcome",
                            onClick: () => {
                              setChosenNode(n);
                              setConfirmOpened(true);
                            },
                            label: (
                              <div className={styles.navOption}>
                                Leave organization
                              </div>
                            ),
                          },
                        ],
                      }}
                      placement="bottomLeft"
                    >
                      <Button size="large">
                        <Space size={16}>
                          Options
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              </div>
            ))
          )}
        </div>
      </div>
      {requestedNodes.length > 0 && (
        <div className="mt-40">
          <div className="bold-body">
            Requested&nbsp;({requestedNodes.length})
          </div>
          <div className={styles.border}></div>
          <div className="mt-16">
            {requestedLoading ? (
              <div className={styles.loadingContainer}>
                <Loader size={65} />
              </div>
            ) : (
              requestedNodes.map((n) => (
                <div className={styles.box} key={n.id}>
                  <Row align="middle">
                    <Col span={18}>
                      <Space size={16}>
                        <div>
                          <Logo
                            size="xxl"
                            type="custom"
                            data={{ name: n.name, src: n.logo }}
                          />
                        </div>
                        <div>
                          <div className="bold-body">{n.name}</div>
                          <div className="sub-color">{n.address}</div>
                          <div className="sub-color">
                            EIN: {formatEin(n.ein)}
                          </div>
                          <div className="mt-8">
                            <div className="tag red">Pending approval</div>
                          </div>
                        </div>
                      </Space>
                    </Col>
                    <Col span={6} className="ta-r">
                      <Button
                        type="text"
                        size="large"
                        onClick={() => {
                          dispatch(
                            deleteAdmin({
                              id: n.id,
                              adminId: n.node_admin_id,
                            })
                          ).then((res) => {
                            if (res.type === "node/admins/delete/fulfilled") {
                              dispatch(getRequestedNodes());
                            }
                          });
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))
            )}
          </div>
        </div>
      )}
      <div className="mt-40">
        <div className="bold-body">Would you like to add an organization?</div>
        <div className="mt-8">
          <Button size="large" onClick={() => navigate(`/search?src=platform`)}>
            Find my organization
          </Button>
        </div>
      </div>
      <Modal
        destroyOnClose
        open={confirmOpened}
        footer={null}
        closable={false}
        width={424}
      >
        <div className="modalContainer">
          <Row align="middle">
            <Col span={20} className="modalHead">
              Your Organizations
            </Col>
            <Col span={4} className="ta-r modalClose">
              <CloseOutlined onClick={() => setConfirmOpened(false)} />
            </Col>
          </Row>
          <div className="mt-8">
            <div className="h2">Leave organization?</div>
          </div>
          <div className="mt-16 modalBody">
            You will have to add this organization again.
          </div>
          <div className="mt-24">
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  danger
                  type="primary"
                  size="large"
                  className="w-100"
                  onClick={() =>
                    dispatch(
                      deleteAdmin({
                        id: chosenNode.id,
                        adminId: chosenNode.node_admin_id,
                      })
                    ).then((res) => {
                      if (res.type === "node/admins/delete/fulfilled") {
                        dispatch(getAdminNodes());
                        setConfirmOpened(false);
                      }
                    })
                  }
                >
                  Leave
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className="w-100"
                  onClick={() => {
                    setConfirmOpened(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}
