import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  onboarding: {
    data: {},
    loading: false,
    errors: null,
  },
};

export const getAdminNodes = createAsyncThunk(
  "user/admin_nodes",
  async (_, thunk) => {
    try {
      let response = await API.auth.adminNodes();
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getAdminNodes.pending]: (state) => {
      state.loading = true;
    },
    [getAdminNodes.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.nodes && action.payload.nodes.length > 0) {
        state.nodes = action.payload.nodes;
        state.currentNode = action.payload.nodes[0];
      } else {
        state.errors = [
          "You need to be a nonprofit admin to access this dashboard",
        ];
      }
    },
    [getAdminNodes.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors } = homeSlice.actions;

export const onboardingSelector = (state) => state.onboarding;

export default homeSlice.reducer;
