import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import bg from "../../assets/img/bg/login.svg";
import logo from "../../assets/img/logo.svg";
import styles from "./Auth.module.css";
import { Header } from "./Header";

export function Intro() {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <Header />
      </div>

      <div className={styles.authContainer}>
        <div className={styles.content}>
          <div className="ta-c">
            <div>
              <img src={logo} alt="logo" height="48" />
            </div>
            <div className={`mt-40 ${styles.welcome}`}>Welcome</div>
            <div className="mt-40">
              <Button
                type="primary"
                size="large"
                className="w-100"
                onClick={() => navigate("/login")}
              >
                <Space size={12}>
                  Login
                  <ArrowRightOutlined />
                </Space>
              </Button>
            </div>
            <div className="mt-24">
              <Space>
                <div className={styles.label}>New to Humanitas? </div>
                <div>
                  <Link to="/signup" className="link">
                    Create an account
                  </Link>
                </div>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
