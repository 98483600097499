import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Col, Progress, Row, Space, Timeline, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import circle from "../../../../assets/img/circle.svg";
import computerLogo from "../../../../assets/img/widget_computer.svg";
import { Loader } from "../../../../components/loader/Loader";
import tracker from "../../../../tracker";
import { navSelector } from "../../../nav/navSlice";
import styles from "../Widget.module.css";
import { getOnboardingModules, widgetOnboardingSelector } from "../widgetSlice";

export function WidgetOnboarding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentNode } = useSelector(navSelector);
  const { data, loading, errors } = useSelector(widgetOnboardingSelector);
  const [showModules, setShowModules] = useState({});
  const [currentTaskStatus, setCurrentTaskStatus] = useState({});

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getOnboardingModules({ nodeId: currentNode.id }));
  }, [dispatch, currentNode]);

  useEffect(() => {
    setCurrentTaskStatus({});
    let m = {};
    data.forEach((d) => {
      for (let i = 0; i < d.tasks.length; i++) {
        m = { ...m, [d.id]: false };
        if (d.tasks[i].completed === false) {
          setCurrentTaskStatus({ ...currentTaskStatus, [d.id]: d.tasks[i].id });
          m = { ...m, [d.id]: true };
          break;
        }
      }
    });
    setShowModules(m);
  }, [data]);

  const changeCurrentStatus = (moduleId, taskId) => {
    setCurrentTaskStatus({ ...currentTaskStatus, [moduleId]: taskId });
  };

  return (
    <>
      <div className="h2 darker-color">What would you like to do today?</div>
      {loading ? (
        <div className={styles.loader}>
          <Loader size="70" />
        </div>
      ) : (
        <>
          {data.map((m) => {
            let percent =
              (m.tasks.filter((t) => t.completed).length * 100) /
              m.tasks.length;
            return (
              <div
                key={m.id}
                className={`${styles.widget} pointer ${
                  showModules[m.id] && "pb-0"
                }`}
                onClick={() =>
                  setShowModules((state) => {
                    return { ...state, [m.id]: !state[m.id] };
                  })
                }
              >
                <Row gutter={16}>
                  <Col span={7}>
                    <img src={computerLogo} alt="onboarding" width={190} />
                  </Col>
                  <Col span={17}>
                    <div>
                      <Row align="middle">
                        <Col span={12}>
                          {percent === 100 ? (
                            <Space size={16}>
                              <div className="tag green">Complete</div>
                            </Space>
                          ) : (
                            <Space>
                              <div className="tag yellow">Get started</div>
                            </Space>
                          )}
                        </Col>
                        <Col span={12} className="ta-r">
                          <div className={`${styles.secText}`}>
                            {m.tasks.length} tasks
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-12">
                      <Progress
                        size="large"
                        percent={percent}
                        showInfo={false}
                        strokeColor={percent === 100 ? "#7EAF2C" : "#1E5CCC"}
                      />
                    </div>
                    <div className="mt-12">
                      <Row align="middle">
                        <Col span={16}>
                          <div className="subtitle">{m.name}</div>
                        </Col>
                        <Col span={8} className="ta-r">
                          <div className={styles.cardSec}>
                            {showModules[m.id] ? (
                              <Space size={8}>
                                Hide
                                <UpOutlined />
                              </Space>
                            ) : (
                              <Space size={8}>
                                Show
                                <DownOutlined />
                              </Space>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {showModules[m.id] && (
                      <div className="mt-32">
                        <div
                          style={{
                            transition: "0.3s",
                            opacity: showModules[m.id] ? 1 : 0,
                          }}
                        >
                          <Timeline
                            items={m.tasks.map((t, i) => {
                              return {
                                dot: t.completed ? (
                                  <CheckCircleFilled
                                    style={{
                                      color: "#7EAF2C",
                                      fontSize: "20px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={circle}
                                    width={20}
                                    height={20}
                                    alt="circle"
                                  />
                                ),
                                children: (
                                  <div className={styles.timelineItem}>
                                    <div
                                      className={`${styles.head} pointer ${
                                        currentTaskStatus[m.id] === t.id &&
                                        styles.selected
                                      }`}
                                      onClick={(e) => {
                                        changeCurrentStatus(m.id, t.id);
                                        e.stopPropagation();
                                      }}
                                    >
                                      {t.name}
                                    </div>
                                    {currentTaskStatus[m.id] === t.id && (
                                      <div>
                                        <div className={`${styles.desc} mt-24`}>
                                          {t.desc}
                                        </div>
                                        <div className="mt-24">
                                          {t.completed ? (
                                            <Space size={24}>
                                              <div>
                                                <Space
                                                  size={8}
                                                  className={styles.complete}
                                                >
                                                  Complete
                                                  <CheckCircleOutlined />
                                                </Space>
                                              </div>
                                              {i !== m.tasks.length - 1 && (
                                                <Button
                                                  size="large"
                                                  onClick={(e) => {
                                                    setCurrentTaskStatus({
                                                      ...currentTaskStatus,
                                                      [m.id]: m.tasks[i + 1].id,
                                                    });
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  Next
                                                </Button>
                                              )}
                                            </Space>
                                          ) : (
                                            <Button
                                              type="primary"
                                              size="large"
                                              onClick={(e) => {
                                                //// TRACK
                                                tracker.track(
                                                  "onboarding-action-click",
                                                  {
                                                    moduleId: m.id,
                                                    moduleName: m.name,
                                                    taskId: t.id,
                                                    taskName: t.name,
                                                  }
                                                );
                                                ////
                                                navigate(
                                                  t.action.link +
                                                    "?src=onboarding"
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              {t.action.name}
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ),
                              };
                            })}
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
