import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import bg from "../../assets/img/bg/login.svg";
import tracker from "../../tracker";
import styles from "./Auth.module.css";
import { Header } from "./Header";
import { resetPassword } from "./authSlice";

export function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { code } = useParams();

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      resetPassword({
        ...values,
        reset_password_token: code,
      })
    ).then((res) => {
      setLoading(false);
      if (res.type === "user/resetPassword/fulfilled") {
        setSuccess(true);
      } else {
        message.error({
          content: res.payload.errors[0],
          icon: <CloseCircleOutlined />,
        });
      }
    });
  };

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      />
      <Header />

      <div className={styles.content}>
        {success ? (
          <>
            <div className="h1">Password Reset Successful</div>
            <div className={`mt-16 copy ${styles.subTitle}`}>
              Your password has been reset successfully.
            </div>
            <Button
              type="primary"
              size="large"
              className="mt-40 w-100"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </>
        ) : (
          <>
            <div className="h1">Reset Password</div>
            <div className={`mt-16 copy ${styles.subTitle}`}>
              Please create a new password to access your account.
            </div>
            <div>
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark={false}
              >
                <Form.Item
                  name="password"
                  label="New Password"
                  className="mt-40"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Type your new password"
                    type="password"
                  />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  label="Confirm Password"
                  className="mt-16"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your new password",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm your new password"
                    type="password"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="mt-40 w-100"
                  loading={loading}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        )}
        <div className={`mt-24`}>
          <span>Still having trouble?&nbsp;</span>
          <span className="link">
            <a
              href="mailto:hello@humanitas.ai"
              target="_blank"
              rel="noreferrer"
            >
              Contact support
            </a>
          </span>
        </div>
      </div>
    </>
  );
}
