import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Space } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import styles from "./Auth.module.css";
import { authSelector } from "./authSlice";

export function Header(props) {
  const { currentUser } = useSelector(authSelector);

  return (
    <>
      <div className={styles.head}>
        <Row align="middle">
          <Col span={4} className="pt-4">
            <Link to="/">
              <img src={logo} alt="logo" height="20" />
            </Link>
          </Col>
          {!props.noAction ? (
            <Col span={20} className="ta-r">
              {currentUser?.id ? (
                <Space className={styles.nav}>
                  <Link to="/home">
                    <Space size={16}>
                      <ArrowLeftOutlined />
                      <div className="link">Back to platform</div>
                    </Space>
                  </Link>
                </Space>
              ) : (
                <Space className={styles.nav}>
                  <div className={styles.label}>Already have an account? </div>
                  <div>
                    <Link to="/login" className="link">
                      Log In
                    </Link>
                  </div>
                </Space>
              )}
            </Col>
          ) : (
            <Col span={20} className="ta-r">
              <Space className={styles.nav}>&nbsp;</Space>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}
