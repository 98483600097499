import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  FileImageOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/buttons/BackButton";
import { Loader } from "../../../components/loader/Loader";
import tracker from "../../../tracker";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import { deleteFile, filesSelector, getFiles } from "../dataSlice";

const FILE_TYPE = 1;

export function AllReceipt() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentNode } = useSelector(navSelector);
  const { data, loading, errors } = useSelector(filesSelector);
  const [currentFile, setCurrentFile] = useState();
  const [confirmOpened, setConfirmOpened] = useState(false);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getFiles({ nodeId: currentNode.id, fileType: FILE_TYPE }));
  }, [dispatch, currentNode]);

  const destroy = () => {
    dispatch(deleteFile({ nodeId: currentNode.id, id: currentFile.id })).then(
      (res) => {
        if (res.type === "data/delete/fulfilled") {
          dispatch(getFiles({ nodeId: currentNode.id, fileType: 1 }));
          setConfirmOpened(false);
          message.success({
            content: "File Deleted Successfully",
            icon: <CheckCircleOutlined />,
          });
        }
      }
    );
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (val) => new Date(val).toLocaleDateString(),
    },
    {
      title: "Items",
      dataIndex: "needs_c",
      key: "needs_c",
    },
    {
      title: <div className="ta-c">View</div>,
      render: (f) => (
        <div className="ta-c">
          <a href={f.url} target="_blank" rel="noreferrer">
            <FileImageOutlined />
          </a>
        </div>
      ),
    },
    {
      title: <div className="ta-c">Remove</div>,
      render: (f) => (
        <div className="ta-c">
          <DeleteOutlined
            onClick={() => {
              setCurrentFile(f);
              setConfirmOpened(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="py-24">
        <div className="px-40">
          <BackButton />
          <div className={`${styles.title} mt-24`}>
            <Row>
              <Col span={7}>
                <div className="h1">
                  <CopyOutlined />
                  &nbsp;&nbsp;Receipts
                </div>
              </Col>
              <Col span={17}>
                <Button
                  size="large"
                  onClick={() => navigate("/data/receipts/add")}
                >
                  Connect&nbsp;
                  <PlusOutlined />
                </Button>
              </Col>
            </Row>
          </div>
          <div className={`${styles.subTitle}`}>
            Upload receipts for supplies your organization has purchased or
            received. Our AI will look for patterns and suggest an inventory
            list for your In-Kind Registry.
          </div>
        </div>
        {/* <div>
          <div className={styles.searchBox}>
            <Input
              allowClear
              placeholder="Search"
              size="large"
              onChange={(e) => setQuery(e.target.value)}
              onPressEnter={() => search()}
              suffix={
                <Button
                  size="large"
                  type={query.length > 0 ? "primary" : "default"}
                  className={styles.searchBtn}
                  // loading={loading}
                  icon={<SearchOutlined />}
                  onClick={() => search()}
                ></Button>
              }
            />
          </div>
        </div> */}
        <div className="px-40">
          <Table
            bordered
            loading={{
              indicator: <Loader size="65" />,
              spinning: loading,
            }}
            pagination={{ position: ["none", "none"] }}
            className={`table-striped`}
            dataSource={data}
            columns={columns}
          />
        </div>
      </div>
      <Modal destroyOnClose open={confirmOpened} footer={null} closable={false}>
        <div className="ta-c">
          <div className="h2">Delete receipt?</div>
          <div className="mt-24">
            Deleting this receipt only deletes it from our servers, it does not
            delete the associated items that have already been added to your
            registry.
          </div>
          <div className="mt-32">
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  danger
                  size="large"
                  className="w-100"
                  onClick={() => destroy()}
                >
                  <Space size={16}>
                    <div>Delete receipt</div>
                    <div>
                      <DeleteOutlined />
                    </div>
                  </Space>
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className="w-100"
                  onClick={() => {
                    setCurrentFile({});
                    setConfirmOpened(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}
