import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row, Space } from "antd";

export function Paginate(props) {
  return (
    <>
      <Row align="middle">
        <Col span={3}>
          {props.currentPage !== 1 && (
            <Space
              size={8}
              className="bold-body desc-color pointer"
              onClick={() => {
                let page = props.currentPage - 1;
                props.onPageChange(page);
              }}
            >
              <ArrowLeftOutlined />
              Back
            </Space>
          )}
        </Col>
        <Col span={18} className="ta-c">
          <div className="body-font">
            Page {props.currentPage} of {props.totalPages}
          </div>
        </Col>
        <Col span={3} className="ta-r">
          {props.currentPage !== props.totalPages && (
            <Space
              size={8}
              className="bold-body desc-color pointer"
              onClick={() => {
                let page = props.currentPage + 1;
                props.onPageChange(page);
              }}
            >
              Next
              <ArrowRightOutlined />
            </Space>
          )}
        </Col>
      </Row>
    </>
  );
}
