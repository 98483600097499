import { ArrowRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/img/bg/setup.svg";
import styles from "./Auth.module.css";
import { Header } from "./Header";
import { authSelector, clearErrors, register } from "./authSlice";

export function RegisterUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, errors } = useSelector(authSelector);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  const handleRegister = (values) => {
    dispatch(register(values)).then((res) => {
      if (res.type === "user/register/fulfilled") {
        navigate("/");
      }
    });
  };

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      />
      <Header />

      <div className={styles.containerLg}>
        <div className={styles.content}>
          <Space.Compact align="start" size={0} block={true}>
            <div className={styles.infoContainer}>
              <div className={styles.info}>
                <div className={`accent accent-color`}>Account Type</div>
                <div className="mt-16">
                  <Row align="middle">
                    <Col span={14}>
                      <div className="subtitle">Personal</div>
                    </Col>
                    <Col span={10} className="ta-r">
                      <div className="tag yellow">Limited</div>
                    </Col>
                  </Row>
                  <div className="mt-16 sub-color">
                    Quickly explore the platform to see what it's capable of.
                    You'll be able to join or create an organization account
                    later.
                  </div>
                  <div className="mt-16 sub-color">
                    <ul>
                      <li>Access to AI Assistant with usage cap</li>
                      <li>Ability to connect multiple data sources</li>
                      <li>Limited to 1 user</li>
                      <li>No need for an EIN or vetting</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={`${styles.tag} mt-16`}>
                <div className="white-color">
                  Want to experience the full power of what Humanitas can do?
                </div>
                <div
                  className="mt-16 link white-color underline pointer"
                  onClick={() => navigate("/search")}
                >
                  Create or join an organization account.
                </div>
              </div>
            </div>
            <div className="p-40">
              <div className="h1">Sign up</div>
              <div className={`mt-40 ${styles.widthSm}`}>
                <Form
                  layout="vertical"
                  onFinish={handleRegister}
                  requiredMark={false}
                >
                  <Form.Item
                    label="First name"
                    name="f_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name",
                      },
                    ]}
                  >
                    <Input type="text" />
                  </Form.Item>
                  <Form.Item
                    className="mt-16"
                    label="Last name"
                    name="l_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name",
                      },
                    ]}
                  >
                    <Input type="text" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    className="mt-16"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email",
                      },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    className="mt-16"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password",
                      },
                    ]}
                  >
                    <Input.Password type="password" />
                  </Form.Item>
                  <div className="mt-40 primary-color">
                    By signing up you agree to our{" "}
                    <span
                      className="link underline pointer"
                      onClick={() =>
                        window.open(
                          "https://admin.humanitas.ai/terms",
                          "_blank"
                        )
                      }
                    >
                      Terms of Service
                    </span>{" "}
                    and{" "}
                    <span
                      className="link underline pointer"
                      onClick={() =>
                        window.open(
                          "https://admin.humanitas.ai/privacy",
                          "_blank"
                        )
                      }
                    >
                      Privacy Policy
                    </span>
                    .
                  </div>
                  <div className="mt-40">
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                    >
                      <Space size={16}>
                        Finish
                        <ArrowRightOutlined />
                      </Space>
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Space.Compact>
        </div>
      </div>
    </>
  );
}
