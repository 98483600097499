import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import tracker from "../../../tracker";
import { authSelector, updateUser } from "../../auth/authSlice";
import styles from "../Account.module.css";

export function Password() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading } = useSelector(authSelector);

  const handleSubmit = (values) => {
    if (values["new"] !== values["verify"]) {
      message.error({
        content: "Verify Password doesn't match new password",
        icon: <CloseCircleOutlined />,
      });
      return;
    }
    if (values["new"].length < 8) {
      message.error({
        content: "Passwords should have a minimum of 8 characters",
        icon: <CloseCircleOutlined />,
      });
      return;
    }
    dispatch(
      updateUser({
        current_password: values["current"],
        password: values["new"],
      })
    ).then((res) => {
      if (res.type === "user/update/fulfilled") {
        message.success({
          content: "Password updated successfully",
          icon: <CheckCircleOutlined />,
        });
      }
    });

    //// TRACK
    tracker.track(`account-password-save`);
    ////
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
        colon={false}
        layout="vertical"
        className={styles.form}
      >
        <Form.Item
          name="current"
          label="Current Password"
          className={styles.item}
          rules={[
            {
              required: true,
              message: "Please input your Current Password!",
            },
          ]}
        >
          <Input.Password type="password" placeholder="Type your password" />
        </Form.Item>
        <Form.Item
          name="new"
          label="New Password"
          className={styles.item}
          rules={[
            {
              required: true,
              message: "Please input your New Password!",
            },
          ]}
        >
          <Input.Password type="password" placeholder="Minimum 8 characters" />
        </Form.Item>
        <Form.Item
          name="verify"
          label="Verify New Password"
          className={styles.item}
          rules={[
            {
              required: true,
              message: "Please input your New Password!",
            },
          ]}
        >
          <Input.Password type="password" placeholder="Verify new password" />
        </Form.Item>
        <Button
          htmlType="submit"
          size="large"
          className={styles.btn}
          loading={loading}
        >
          Save Changes
        </Button>
      </Form>
    </>
  );
}
