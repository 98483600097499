import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/img/logo.svg";
import { Loader } from "../../components/loader/Loader";
import tracker from "../../tracker";
import { navSelector } from "../nav/navSlice";
import styles from "./Registry.module.css";
import {
  createNeed,
  currentSelector,
  deleteNeed,
  getNeed,
  updateNeed,
} from "./registrySlice";

export function EditItem(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { currentNode } = useSelector(navSelector);
  const { data, loading, errors } = useSelector(currentSelector);
  const [brands, setBrands] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [status, setStatus] = useState(false);
  const [item, setItem] = useState();

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    setItem(data);
  }, [data]);

  useEffect(() => {
    if (props.item.id && props.type === "edit") {
      dispatch(getNeed({ nodeId: currentNode.id, id: props.item.id }));
    } else {
      setItem(props.item);
    }
  }, [props.item, props.type, currentNode.id, dispatch]);

  useEffect(() => {
    if (item?.catalogue) {
      setBrands(
        item.catalogue.brands.map((b) => {
          return { label: b, value: b };
        })
      );
      setSizes(
        item.catalogue.sizes.map((b) => {
          return { label: b, value: b };
        })
      );
      setStatus(item.status);
      form.setFieldsValue(item);
    }
  }, [item, form]);

  const update = (values) => {
    if (props.type === "edit") {
      dispatch(
        updateNeed({
          nodeId: currentNode.id,
          id: item.id,
          data: { ...values, status: status },
        })
      ).then((res) => {
        if (res.type === "registry/updateNeed/fulfilled") {
          props.onSuccess();
          message.success({
            content: "Item Updated Successfully",
            icon: <CheckCircleOutlined />,
          });
        } else {
          message.error({
            content: "Update Failed, try again later",
            icon: <CloseCircleOutlined />,
          });
        }
      });
    } else {
      dispatch(
        createNeed({
          nodeId: currentNode.id,
          data: { ...values, catalogue_id: item.catalogue.id, status: status },
        })
      ).then((res) => {
        if (res.type === "registry/createNeed/fulfilled") {
          props.onSuccess();
          message.success({
            content: "Item Updated Successfully",
            icon: <CheckCircleOutlined />,
          });
        } else {
          message.error({
            content: "Add Item Failed, try again later",
            icon: <CloseCircleOutlined />,
          });
        }
      });
    }

    tracker.track(`registry-edit-save`, {
      needID: item.id,
      needName: item?.catalogue?.name,
    });
  };

  const remove = () => {
    dispatch(
      deleteNeed({
        nodeId: currentNode.id,
        id: item.id,
      })
    ).then((res) => {
      if (res.type === "registry/deleteNeed/fulfilled") {
        props.onSuccess();
        message.success({
          content: "Item Deleted Successfully",
          icon: <CheckCircleOutlined />,
        });
      }
    });
    tracker.track(`registry-edit-del`, {
      needID: item.id,
      needName: item?.catalogue?.name,
    });
  };

  return (
    <Spin indicator={<Loader size="75" />} spinning={false}>
      <div className={styles.editContainer}>
        <Row align="top" gutter={40}>
          <Col span={11} style={{ height: "100%" }}>
            <div className={styles.type}>Customize Item</div>
            <div>
              <Row align="middle" className="mt-16" gutter={40}>
                <Col span={4}>
                  <Image
                    src={item?.catalogue?.image}
                    height={60}
                    width={60}
                    preview={false}
                  />
                </Col>
                <Col className={styles.name} span={20}>
                  {item?.catalogue?.name}
                </Col>
              </Row>
              <div className={styles.sub}>
                Brand availability is limited by the inventory of our local
                retail partners. More specific requests may take longer to fill.
                We are committed to supplying you with safe, high-quality
                products.
              </div>
            </div>
            <div className={styles.extra}>
              <Row>
                <Col span={6}>Active Need:</Col>
                <Col span={18} className={styles.val}>
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={status}
                    onChange={(val) => {
                      setStatus(val);
                      // update({ status: val });
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Tooltip title="The quantity of individual items rather than packages. Ex. if you would buy two packages of 6 paper towels, enter 12 as the quantity.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
              {props.type === "edit" && (
                <>
                  <Row className="mt-16" align="middle">
                    <Col span={6}>Added by:</Col>
                    <Col span={18} className={styles.val}>
                      <div className={styles.logo}>
                        <img src={logo} height="22" alt="Humanitas" />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-16">
                    <Col span={6}>Last Edit:</Col>
                    <Col span={18} className={styles.val}>
                      {new Date(item?.updated_at).toLocaleDateString()}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
          <Col span={13}>
            <div className="ta-r">
              <Button size="large" onClick={() => props.onCancel()}>
                Cancel
              </Button>
            </div>
            <div className="mt-40">
              <Form
                form={form}
                onFinish={update}
                requiredMark={false}
                colon={false}
                layout="vertical"
              >
                <Form.Item
                  name="brands"
                  label="Select Brands"
                  className={styles.item}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder={
                      <Row className={styles.defaultSelect} align="middle">
                        <Col className={styles.ph}>Recommended:</Col>
                        <Col className={styles.tag}>Any Brand</Col>
                      </Row>
                    }
                    options={brands}
                  />
                </Form.Item>
                <Form.Item
                  name="sizes"
                  label="Select Sizes"
                  className={styles.item}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={
                      <Row className={styles.defaultSelect} align="middle">
                        <Col className={styles.ph}>Recommended:</Col>
                        <Col className={styles.tag}>Any Size</Col>
                      </Row>
                    }
                    options={sizes}
                  />
                </Form.Item>
                <Form.Item
                  name="quantity"
                  label={
                    <>
                      Select Unit Quantity&nbsp;&nbsp;
                      <Tooltip
                        placement="bottom"
                        title="The quantity of individual items rather than packages. Ex. if you would buy two packages of 6 paper towels, enter 12 as the quantity."
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  className={styles.item}
                >
                  <InputNumber
                    className="w-100"
                    // addonAfter={
                    //   <Row className={styles.numChange}>
                    //     <Col span={12}>
                    //       <PlusOutlined />
                    //     </Col>
                    //     <Col span={12}>
                    //       <PlusOutlined />
                    //     </Col>
                    //   </Row>
                    // }
                  />
                </Form.Item>
                <Form.Item
                  name="frequency"
                  label="Select Frequency"
                  className={styles.item}
                >
                  <Select options={frequencyOptions} disabled />
                </Form.Item>
                <Form.Item
                  name="comments"
                  label="Additional Comments (Optional)"
                  className={styles.item}
                >
                  <Input.TextArea
                    placeholder="Tell us about any additional item specifications you feel are not represented."
                    maxLength={1000}
                    autoSize={{ minRows: 4 }}
                  />
                </Form.Item>
                <div className="ta-r">
                  <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    type="primary"
                    icon={props.type === "edit" ? <PlusOutlined /> : null}
                  >
                    {props.type === "edit" ? "Save Changes" : "Add to List"}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        {props.type === "edit" && (
          <div className={styles.removeBtn}>
            <Button danger size="large" onClick={() => remove()}>
              Remove&nbsp;&nbsp;
              <DeleteOutlined />
            </Button>
          </div>
        )}
      </div>
    </Spin>
  );
}

const frequencyOptions = [
  {
    value: 1,
    label: "Every Week",
  },
  {
    value: 2,
    label: "Every 2 Weeks",
  },
  {
    value: 3,
    label: "Every 4 Weeks",
  },
  {
    value: 4,
    label: "Every 6 Weeks",
  },
];
