import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/404.svg";

export function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="p-40">
      <div className="ta-c">
        <img src={logo} alt="logo" height="324" />
        <div className="h1 mt-40">Oops!</div>
        <div className="mt-40">
          This page is nowhere to be found. Try checking the url and trying
          again.
        </div>
        <Button
          className="mt-40"
          size="large"
          type="primary"
          onClick={() => navigate("/")}
        >
          Take me Home
        </Button>
      </div>
    </div>
  );
}
