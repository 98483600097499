import { CloseCircleOutlined, HeartOutlined } from "@ant-design/icons";
import { Tabs, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import tracker from "../../tracker";
import { navSelector, updateCurrentNode } from "../nav/navSlice";
import styles from "./Profile.module.css";
import { BasicInfo } from "./info/BasicInfo";
import { ContactInfo } from "./info/ContactInfo";
import { LocationInfo } from "./info/LocationInfo";
import { clearErrors, profileSelector } from "./profileSlice";
import { Team } from "./team/Team";

export function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentNode } = useSelector(navSelector);
  const { focus } = useParams();
  const { errors } = useSelector(profileSelector);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
      dispatch(clearErrors());
    }
  }, [errors]);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  return (
    <div className="py-40">
      <div className={`${styles.title} h1`}>
        <HeartOutlined />
        &nbsp;&nbsp;Organization Profile
      </div>
      <Tabs
        activeKey={focus?.length > 0 ? focus : "basic"}
        type="card"
        size="large"
        onTabClick={(val) => {
          navigate(`/profile/${val}`);
          //// TRACK
          tracker.track(`profile-${val}-tab`);
          ////
        }}
        items={[
          {
            label: `Basic Info`,
            key: "basic",
            children: (
              <div className={styles.content}>
                <BasicInfo
                  onUpdated={(data) => {
                    dispatch(
                      updateCurrentNode({
                        ...currentNode,
                        name: data.name,
                        logo: data.logo,
                      })
                    );
                  }}
                />
              </div>
            ),
          },
          {
            label: `Contact Info`,
            key: "contact",
            children: (
              <div className={styles.content}>
                <ContactInfo onUpdated={() => console.log("Updated")} />
              </div>
            ),
          },
          {
            label: `Locations`,
            key: "locations",
            children: (
              <div className={styles.content}>
                <LocationInfo onUpdated={() => console.log("Updated")} />
              </div>
            ),
          },
          {
            label: `Team Members`,
            key: "team",
            children: (
              <div className={styles.content}>
                <Team />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
