import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  FolderOpenOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  Progress,
  Row,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Authorize } from "../../../components/authorize/Authorize";
import { BackButton } from "../../../components/buttons/BackButton";
import { Loader } from "../../../components/loader/Loader";
import { Permissions } from "../../nav/Nav";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import {
  analyzeData,
  deleteFile,
  filesSelector,
  getFile,
  getFiles,
} from "../dataSlice";
import { CATEGORIES } from "./categories";

const FILE_TYPE = 3;
let AllTimers = {};

export function AllOther() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentNode } = useSelector(navSelector);
  const { data, loading, errors } = useSelector(filesSelector);
  const [files, setFiles] = useState([]);
  const [unconnectedFiles, setUnconnectedFiles] = useState([]);
  const [categories, setCategories] = useState(
    CATEGORIES.map((c) => {
      return { ...c, show: false };
    })
  );
  const [currentFile, setCurrentFile] = useState();
  const [confirmOpened, setConfirmOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getFiles({ nodeId: currentNode.id, fileType: FILE_TYPE }));
  }, [dispatch, currentNode]);

  const clearAllTimers = () => {
    for (let t in AllTimers) {
      clearInterval(AllTimers[t]);
    }
    AllTimers = {};
  };

  useEffect(() => {
    setFiles(data.filter((d) => d.status !== 1));
    setUnconnectedFiles(data.filter((d) => d.status === 1));

    data
      .filter((d) => d.status === 2)
      .forEach((f) => {
        console.log("Timer started for", f.id);
        AllTimers[f.id] = setInterval(() => {
          dispatch(getFile({ nodeId: currentNode.id, dataId: f.id })).then(
            (res) => {
              if (res.type === "data/show/fulfilled") {
                setFiles((state) => {
                  return state.map((s) => {
                    if (s.id === res.payload.id) {
                      s = res.payload;
                    }
                    return s;
                  });
                });
                if (res.payload.status === 3) {
                  console.log("Timer ended for", f.id);
                  clearInterval(AllTimers[f.id]);
                }
              }
            }
          );
        }, 5000);
      });
    return () => {
      console.log("All timers ended");
      clearAllTimers();
    };
  }, [data, currentNode.id, dispatch]);

  const showList = (t) => {
    if (loading) {
      return <Spin indicator={<Loader size={30} />} />;
    }
    let s = files.filter((d) => d.sec_file_type === t);
    let c = categories.filter((d) => d.value === t);
    if (s && s.length > 0) {
      return c[0].show ? (
        <div className="pr-16">
          <Space
            size={12}
            align="bottom"
            className="pointer"
            onClick={() => showMore(c[0].value, false)}
          >
            Hide
            <UpOutlined />
          </Space>
        </div>
      ) : (
        <div className="pr-16">
          <Space
            size={12}
            align="bottom"
            className="pointer"
            onClick={() => showMore(c[0].value, true)}
          >
            Show
            <DownOutlined />
          </Space>
        </div>
      );
    }
    return (
      <>
        <Authorize permission={Permissions.data}>
          <Button
            size="large"
            onClick={() => navigate(`/data/other/add/${t}`)}
            disabled={
              currentNode?.id &&
              !currentNode?.permissions?.includes(Permissions.data)
            }
          >
            Connect&nbsp;
            <PlusOutlined />
          </Button>
        </Authorize>
      </>
    );
  };

  const showMore = (t, show) => {
    setCategories((state) =>
      state.map((c) => {
        if (c.value === t) {
          c.show = show;
          return c;
        }
        return c;
      })
    );
  };

  const showStatus = (t) => {
    if (loading) {
      return <></>;
    }
    let s = files.filter((d) => d.sec_file_type === t);
    if (s && s.length > 0) {
      if (s.filter((f) => f.status === 2).length > 0) {
        return (
          <Space size={12}>
            <div>Analyzing...</div>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: "18px" }} spin />}
            />
          </Space>
        );
      } else if (s.filter((f) => f.status === 3).length > 0) {
        return (
          <Space size={12}>
            <div>{s.filter((f) => f.status === 3).length}&nbsp;Connected</div>
            <CheckCircleOutlined
              className="success-color"
              style={{ fontSize: "18px" }}
            />
          </Space>
        );
      }
    }
    return (
      <>
        Not Connected
        {/* &nbsp;&nbsp;<ExclamationCircleOutlined style={{ color: "#E85036" }} /> */}
      </>
    );
  };

  const showProgress = (t) => {
    let s = files.filter((d) => d.sec_file_type === t);
    let analyzing = s.filter((f) => f.status === 2);
    if (analyzing.length > 0) {
      let average =
        analyzing.reduce((total, item) => total + item.progress, 0) /
        analyzing.length;
      return (
        <div className={styles.progressContainer}>
          <Progress percent={average} showInfo={false} strokeColor="#1E5CCC" />
        </div>
      );
    }
    return <div className={styles.progressContainer}></div>;
  };

  const destroy = () => {
    dispatch(deleteFile({ nodeId: currentNode.id, id: currentFile.id })).then(
      (res) => {
        if (res.type === "data/delete/fulfilled") {
          setUnconnectedFiles((state) => {
            return state.filter((s) => s.id !== currentFile.id);
          });
          setFiles((state) => {
            return state.filter((s) => s.id !== currentFile.id);
          });
          setConfirmOpened(false);
          message.success({
            content: "File Deleted Successfully",
            icon: <CheckCircleOutlined />,
          });
        }
      }
    );
  };

  const analyze = () => {
    dispatch(
      analyzeData({
        nodeId: currentNode.id,
        data: {
          file_type: FILE_TYPE,
          ids: unconnectedFiles.map((f) => f.id),
        },
      })
    ).then((res) => {
      if (res.type === "data/analyze/fulfilled") {
        dispatch(getFiles({ nodeId: currentNode.id, fileType: FILE_TYPE }));
      }
    });
  };

  return (
    <>
      <div className="content-container">
        <div className="px-40">
          <BackButton />
          <div className={`${styles.title} mt-24`}>
            <Space size={56}>
              <Space size={16}>
                <div className="h1">
                  <FolderOpenOutlined />
                </div>
                <div className="h1">Other</div>
              </Space>
              <Authorize permission={Permissions.data}>
                <Button
                  size="large"
                  onClick={() => navigate("/data/other/add")}
                  disabled={
                    currentNode?.id &&
                    !currentNode?.permissions?.includes(Permissions.data)
                  }
                >
                  <Space size={16}>
                    Connect
                    <PlusOutlined />
                  </Space>
                </Button>
              </Authorize>
            </Space>
          </div>
          <div className={`${styles.subTitle}`}>
            Bring any document into play. From impact reports to blog posts,
            each unique file refines your AI Assistant, tuning it to assist you
            better.
          </div>
          {unconnectedFiles.length > 0 && (
            <div className={styles.errorContainer}>
              <div className="bold-body">
                <Space size={8} className={styles.errorTitle}>
                  <div className={styles.errorIcon}>
                    <ExclamationCircleOutlined />
                  </div>
                  Connection incomplete
                </Space>
              </div>
              <div className="mt-16">
                Finish connecting the following files to use them in your AI
                Assistant.
              </div>
              <div>
                {unconnectedFiles.map((f) => (
                  <div key={f.id} className="mt-24">
                    <div>
                      <Row align="middle" gutter={8}>
                        <Col span={5}>
                          <div className={styles.errorFileLg}>
                            {
                              CATEGORIES.filter(
                                (c) => c.value === f.sec_file_type
                              )[0]?.label
                            }
                          </div>
                        </Col>
                        <Col span={19}>
                          <div className={styles.errorFile}>
                            <Row align="middle">
                              <Col span={16}>
                                <Space size={12}>
                                  <FileOutlined />
                                  {f.name}
                                </Space>
                              </Col>
                              <Col span={6}>
                                <div>
                                  <a
                                    href={f.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View/Download&nbsp;&nbsp;
                                    <DownloadOutlined />
                                  </a>
                                </div>
                              </Col>
                              <Col span={2} className="ta-r">
                                <Authorize permission={Permissions.data}>
                                  <Button
                                    type="text"
                                    size="large"
                                    onClick={() => {
                                      setCurrentFile(f);
                                      setConfirmOpened(true);
                                    }}
                                    disabled={
                                      currentNode?.id &&
                                      !currentNode?.permissions?.includes(
                                        Permissions.data
                                      )
                                    }
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Authorize>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-24">
                <Authorize permission={Permissions.data}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => analyze()}
                    disabled={
                      currentNode?.id &&
                      !currentNode?.permissions?.includes(Permissions.data)
                    }
                  >
                    <Space size={16}>
                      Connect
                      <ArrowRightOutlined />
                    </Space>
                  </Button>
                </Authorize>
              </div>
            </div>
          )}
          <div>
            {categories.map((c) => (
              <div className={styles.listBox} key={c.value}>
                <div>
                  <Row className={styles.year}>
                    <Col span={17} className={styles.listName}>
                      <Space size={56}>
                        <div>{c.label}</div>
                        <div className={styles.listStatus}>
                          {showStatus(c.value)}
                        </div>
                      </Space>
                    </Col>
                    <Col span={7} className={styles.show}>
                      {showList(c.value)}
                    </Col>
                  </Row>
                  <div>{showProgress(c.value)}</div>
                </div>
                {c.show && (
                  <div>
                    <div
                      className={styles.details}
                      style={{ transition: "0.3s", opacity: c.show ? 1 : 0 }}
                    >
                      {files
                        .filter((d) => d.sec_file_type === c.value)
                        .map((m, i) => (
                          <div className={styles.fileBox} key={m.id}>
                            <div className={styles.sourceId}>
                              Source&nbsp;#{i + 1} -{" "}
                              {m.status === 2 ? (
                                <Space size={8}>
                                  <div>Analyzing...</div>
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{ fontSize: "14px" }}
                                        spin
                                      />
                                    }
                                  />
                                </Space>
                              ) : m.status === 3 ? (
                                <Space size={8}>
                                  <div>Connected</div>
                                  <CheckCircleOutlined
                                    className="success-color"
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  />
                                </Space>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className={styles.fileContainer}>
                              <div className={styles.file}>
                                <Row align="middle">
                                  <Col span={17}>
                                    <Space size={12}>
                                      <div className={styles.icon}>
                                        <FileOutlined />
                                      </div>
                                      <div className={styles.name}>
                                        {m.name}
                                      </div>
                                    </Space>
                                  </Col>
                                  <Col span={6} className={styles.download}>
                                    <div>
                                      <a
                                        href={m.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        View/Download&nbsp;&nbsp;
                                        <DownloadOutlined />
                                      </a>
                                    </div>
                                  </Col>
                                  <Col span={1} className={styles.del}>
                                    {m.status !== 2 && (
                                      <Authorize permission={Permissions.data}>
                                        <Button
                                          type="text"
                                          size="large"
                                          onClick={() => {
                                            setCurrentFile(m);
                                            setConfirmOpened(true);
                                          }}
                                          disabled={
                                            currentNode?.id &&
                                            !currentNode?.permissions?.includes(
                                              Permissions.data
                                            )
                                          }
                                        >
                                          <DeleteOutlined />
                                        </Button>
                                      </Authorize>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                              {m.status === 2 ? (
                                <div className="mt-8">
                                  <Progress
                                    percent={m.progress}
                                    showInfo={false}
                                    strokeColor="#1E5CCC"
                                  />
                                </div>
                              ) : m.status === 3 ? (
                                <div className="mt-16">
                                  <div className={styles.summaryTitle}>
                                    <Space size={16}>
                                      Basic Summary
                                      <Tooltip title="For a high level summary, try asking your AI assistant in-depth questions about this data source.">
                                        <QuestionCircleOutlined />
                                      </Tooltip>
                                    </Space>
                                  </div>
                                  <div className={styles.summaryBox}>
                                    {m?.summary?.text}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ))}
                      <div className="mt-40">
                        <Space size={24}>
                          <div>Connect additional files?</div>
                          <Authorize permission={Permissions.data}>
                            <Button
                              size="large"
                              onClick={() =>
                                navigate(`/data/other/add/${c.value}`)
                              }
                              disabled={
                                currentNode?.id &&
                                !currentNode?.permissions?.includes(
                                  Permissions.data
                                )
                              }
                            >
                              <Space size={16}>
                                Connect
                                <PlusOutlined />
                              </Space>
                            </Button>
                          </Authorize>
                        </Space>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        open={confirmOpened}
        footer={null}
        closable={false}
        width={424}
      >
        <div className="modalContainer">
          <Row align="middle">
            <Col span={20} className="modalHead">
              Data Sources
            </Col>
            <Col span={4} className="ta-r modalClose">
              <CloseOutlined onClick={() => setConfirmOpened(false)} />
            </Col>
          </Row>
          <div className="mt-8">
            <div className="h2">Delete data source?</div>
          </div>
          <div className="mt-16 modalBody">
            Your AI Assistant will no longer be able to access data from this
            file.
          </div>
          <div className="mt-24">
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  danger
                  type="primary"
                  size="large"
                  className="w-100"
                  onClick={() => destroy()}
                >
                  <Space size={16}>
                    <div>Delete file</div>
                    <div>
                      <DeleteOutlined />
                    </div>
                  </Space>
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className="w-100"
                  onClick={() => {
                    setCurrentFile({});
                    setConfirmOpened(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}
