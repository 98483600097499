import { CheckCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Form, Input, Row, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tracker from "../../../tracker";
import { authSelector, getUser, updateUser } from "../../auth/authSlice";
import styles from "../Account.module.css";

export function BasicInfo(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [logoFile, setLogoFile] = useState();
  const [logo, setLogo] = useState();
  const { currentUser, loading } = useSelector(authSelector);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser.id) {
      form.setFieldsValue(currentUser);
    }
  }, [currentUser, form]);

  const onLogochange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.file);
    reader.onload = () => {
      setLogo(reader.result);
    };
    setLogoFile(e.file);
  };

  const handleSubmit = (values) => {
    if (logoFile) {
      let fd = new FormData();
      fd.append("avatar", logoFile);
      for (let key in values) {
        fd.append(key, values[key]);
      }
      dispatch(updateUser(fd)).then((res) => {
        if (res.type === "user/update/fulfilled") {
          message.success({
            content: "Profile updated successfully",
            icon: <CheckCircleOutlined />,
          });
          props.onUpdated();
        }
      });
    } else {
      dispatch(updateUser(values)).then((res) => {
        if (res.type === "user/update/fulfilled") {
          message.success({
            content: "Profile updated successfully",
            icon: <CheckCircleOutlined />,
          });
          props.onUpdated();
        }
      });
    }

    //// TRACK
    tracker.track(`account-basic-save`);
    ////
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
        colon={false}
        layout="vertical"
        className={styles.form}
      >
        <Form.Item label="Profile Picture" className={styles.item}>
          <Row align="middle" gutter={40}>
            <Col span={6}>
              {logo || currentUser.avatar ? (
                <Avatar
                  className={styles.logo}
                  src={logo ? logo : currentUser.avatar}
                />
              ) : (
                <Avatar className={`${styles.logo} ${styles.empty}`} alt="Logo">
                  {currentUser?.f_name?.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </Col>
            <Col span={18}>
              <ImgCrop
                rotationSlider={true}
                zoomSlider={true}
                quality={1}
                modalTitle="Preview Logo"
              >
                <Upload
                  customRequest={onLogochange}
                  maxCount={1}
                  showUploadList={false}
                >
                  <Button size="large">Upload from computer</Button>
                  <EditOutlined
                    className="edit-upload"
                    style={{ color: "white" }}
                  />
                </Upload>
              </ImgCrop>
            </Col>
          </Row>
        </Form.Item>
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item
              name="f_name"
              label="First Name"
              className={styles.item}
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="l_name"
              label="Last Name"
              className={styles.item}
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="email"
          label="Email"
          className={styles.item}
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input a valid email!",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Button
          htmlType="submit"
          size="large"
          className={styles.btn}
          loading={loading}
        >
          Save Changes
        </Button>
      </Form>
    </>
  );
}
