import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../../api";

const initialState = {
  onboarding: {
    data: [],
    loading: false,
    errors: null,
  },
  data: {
    data: {},
    loading: false,
    errors: null,
  },
  assistant: {
    data: {},
    loading: false,
    errors: null,
  },
};

export const getOnboardingModules = createAsyncThunk(
  "onboarding/index",
  async (data, thunk) => {
    try {
      let response = await API.onboarding.index(data.nodeId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const markOnboardingTask = createAsyncThunk(
  "onboarding/create",
  async (data, thunk) => {
    try {
      let response = await API.onboarding.create(data.nodeId, data.taskId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getDataWidget = createAsyncThunk(
  "onboarding/data",
  async (data, thunk) => {
    try {
      let response = await API.widgets.data(data.nodeId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getAssistantWidget = createAsyncThunk(
  "onboarding/assistant",
  async (data, thunk) => {
    try {
      let response = await API.widgets.assistant(data.nodeId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getOnboardingModules.pending]: (state) => {
      state.onboarding.loading = true;
    },
    [getOnboardingModules.fulfilled]: (state, action) => {
      state.onboarding.loading = false;
      state.onboarding.data = action.payload.modules;
    },
    [getOnboardingModules.rejected]: (state, action) => {
      state.onboarding.loading = false;
      state.onboarding.errors = action.payload?.errors;
    },
    [getDataWidget.pending]: (state) => {
      state.data.loading = true;
    },
    [getDataWidget.fulfilled]: (state, action) => {
      state.data.loading = false;
      state.data.data = action.payload;
    },
    [getDataWidget.rejected]: (state, action) => {
      state.data.loading = false;
      state.data.errors = action.payload?.errors;
    },
    [getAssistantWidget.pending]: (state) => {
      state.assistant.loading = true;
    },
    [getAssistantWidget.fulfilled]: (state, action) => {
      state.assistant.loading = false;
      state.assistant.data = action.payload;
    },
    [getAssistantWidget.rejected]: (state, action) => {
      state.assistant.loading = false;
      state.assistant.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors } = widgetSlice.actions;

export const widgetOnboardingSelector = (state) => state.widget.onboarding;
export const widgetDataSelector = (state) => state.widget.data;
export const widgetAssistantSelector = (state) => state.widget.assistant;

export default widgetSlice.reducer;
