import {
  CloseCircleOutlined,
  SendOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Col, Input, Row, Space, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import assistant from "../../assets/img/assistant.svg";
import { Loader } from "../../components/loader/Loader";
import { UserAvatar } from "../nav/UserAvatar";
import styles from "./Assistant.module.css";
import {
  chatsSelector,
  createChat,
  getAllChats,
  resetChats,
  updateChat,
} from "./assistantSlice";

const Models = {
  "gpt-4o": 1,
  "gpt-4-turbo": 2,
  "gpt-3.5-turbo-0125": 3,
  "gpt-3.5-turbo-1106": 4,
};

const { TextArea } = Input;

export function Chat(props) {
  const dispatch = useDispatch();
  const chatRef = useRef(null);
  const chatEndRef = useRef(null);
  const chatInputRef = useRef(null);
  const timerRef = useRef(null);
  const { data, loading, errors } = useSelector(chatsSelector);

  const [query, setQuery] = useState("");
  const [chats, setChats] = useState([]);
  const [answerId, setAnswerId] = useState();
  const [answerStarted, setAnswerStarted] = useState(false);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const showError = (msg) => {
    message.error({
      content: msg,
      icon: <CloseCircleOutlined />,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsUserScrolling(true);
    };

    const container = chatRef.current;
    container.addEventListener("wheel", handleScroll);

    return () => {
      container.removeEventListener("wheel", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      showError(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (props?.currentConversation?.id) {
      dispatch(getAllChats({ conversationId: props.currentConversation.id }));
    }
    console.log("Resetting chats");
    setChats([]);
    dispatch(resetChats());
    setIsUserScrolling(false);
    setAnswerId();
    chatInputRef.current.focus();
  }, [props.currentConversation.id, dispatch]);

  useEffect(() => {
    props.onStreaming(answerId ? true : false);
  }, [answerId]);

  useEffect(() => {
    if (
      props?.currentConversation?.id &&
      props.initPrompt &&
      chats.length === 0
    ) {
      sendQuery(props.initPrompt);
    }
  }, [props.currentConversation.id, props.initPrompt]);

  useEffect(() => {
    if (props.answerStream.length > 0) {
      if (!answerStarted) {
        setAnswerStarted(true);
      }
    }
  }, [props.answerStream]);

  useEffect(() => {
    setChats(data);
  }, [data]);

  useEffect(() => {
    if (chats.length > 0) {
      scrollToBottom();
    }
  }, [chats]);

  useEffect(() => {
    if (answerId) {
      scrollToBottom();
    }
  }, [answerId]);

  useEffect(() => {
    if (answerStarted) {
      timerRef.current = setInterval(() => {
        console.log("Scrolling");
        scrollToBottom();
      }, 500);
    }
  }, [answerStarted]);

  useEffect(() => {
    if (props.answerFinished) {
      updateAnswer();
      // Clear current timer
      setAnswerStarted(false);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [props.answerFinished]);

  const scrollToBottom = () => {
    //// DEPRECATE Change this, cos userscrolling doesnt't update in time for this to work
    if (!isUserScrolling) {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const updateAnswer = () => {
    let data = {
      message: props.answerStream,
      persona_id: props.answerPersonaId,
      assistant: true,
      model: Models["gpt-3.5-turbo-0125"],
      token_usage: props.tokenUsage,
    };
    dispatch(
      updateChat({
        conversationId: props.currentConversation.id,
        id: answerId,
        data: data,
      })
    ).then((res) => {
      if (res.type === "chats/update/fulfilled") {
        setAnswerId();
        props.onAnswerSaved();
        setChats((state) => [...state, res.payload]);
      } else {
        showError("Error updating answer. Try again later.");
      }
    });
  };

  const sendQuery = (q) => {
    if (answerStarted) {
      return;
    }
    if (q.length === 0) {
      message.error({
        content: "Your question cannot be empty",
        icon: <CloseCircleOutlined />,
      });
      return;
    }
    if (props?.currentConversation?.id) {
      setIsUserScrolling(false);
      dispatch(
        createChat({
          conversationId: props.currentConversation.id,
          data: { message: q },
        })
      ).then((res) => {
        if (res.type === "chats/create/fulfilled") {
          dispatch(
            createChat({
              conversationId: props.currentConversation.id,
              data: { message: "", system: "true" },
            })
          ).then((res) => {
            if (res.type === "chats/create/fulfilled") {
              setAnswerId(res.payload.id);
            } else {
              showError("Error updating answer. Try again later.");
            }
          });
          setChats([...chats, res.payload]);
        } else {
          showError("Error updating answer. Try again later.");
        }
      });
      props.onSend({ history: getChatHistory(), query: q });
      setQuery("");
    } else {
      props.onCreate(query);
    }
  };

  const getChatHistory = () => {
    let h = [];
    for (let i = 0; i < chats.length; i += 2) {
      h.push(chats.slice(i, i + 2).map((c) => c.message));
    }
    return h;
  };

  return (
    <>
      <div className={styles.chatArea} ref={chatRef}>
        {loading || !props.isConnected ? (
          <div className={styles.loader}>
            <Loader size={85} />
          </div>
        ) : !props.currentConversation.id ? (
          <div className={styles.paddedArea}>
            <div className={styles.containerArea}>
              <div className={styles.introContainer}>
                <Space size={24}>
                  <div>
                    <div>
                      <img src={assistant} alt="AI Assistant" />
                    </div>
                    <div className="accent mt-8 accent-color">AI Assistant</div>
                    <div className="h1 mt-8">
                      What would you like help with today?
                    </div>
                    <div className="mt-16 primary-color">
                      Unsure? Check out our{" "}
                      <span
                        className="link pointer underline "
                        onClick={() => props.onHelpClicked()}
                      >
                        list of ideas.
                      </span>
                    </div>
                  </div>
                </Space>
              </div>
            </div>
          </div>
        ) : (
          <>
            {chats.map((c) => (
              <div
                className={c.system ? styles.system : styles.user}
                key={c.id}
              >
                <div className={styles.chatTextBox}>
                  <Space size={24} align="top">
                    <div className={styles.avatar}>
                      {c.system ? <SmileOutlined /> : <UserAvatar />}
                    </div>
                    <Markdown>{c.message}</Markdown>
                  </Space>
                </div>
              </div>
            ))}
          </>
        )}
        {answerId && (
          <div className={styles.system}>
            <div className={styles.chatTextBox}>
              <Space size={24} align="top">
                <div className={styles.avatar}>
                  <SmileOutlined />
                </div>
                <div>
                  {props.answerStream.length > 0 ? (
                    <Markdown>{props.answerStream}</Markdown>
                  ) : (
                    <div>
                      <Loader size={42} />
                    </div>
                  )}
                </div>
              </Space>
            </div>
          </div>
        )}
        <div ref={chatEndRef}></div>
      </div>
      <div className={styles.queryContainer}>
        <div className={styles.paddedArea}>
          <div className={styles.containerArea}>
            {/* <div
            className={`${styles.btnCtrl}`}
            onClick={() => props.onStopStream()}
          >
            <Space size={8}>
              <StopOutlined />
              <div>Stop response</div>
            </Space>
          </div> */}
            <div
              className={`${styles.queryBox} ${
                inputFocused && styles.focused
              } mt-8`}
            >
              <Row gutter={8} align="middle">
                <Col xs={21} sm={21} md={21} lg={21} xl={22}>
                  <div className={styles.queryArea}>
                    <TextArea
                      ref={chatInputRef}
                      size="large"
                      placeholder={
                        chats.length === 0
                          ? "Ask a question or give your assistant a task."
                          : "Ask related questions or provide feedback for a better response."
                      }
                      bordered={false}
                      style={{ resize: "none" }}
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (e.shiftKey) {
                          } else {
                            e.preventDefault();
                            sendQuery(query);
                          }
                        }
                      }}
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => setInputFocused(false)}
                    />
                  </div>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={2}>
                  <div className={styles.btnArea}>
                    <div
                      className={styles.btn}
                      onClick={() => sendQuery(query)}
                    >
                      <SendOutlined />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mt-16 small ps-color ta-l">
              The AI Assistant may produce inaccurate information about people,
              places, or facts.
            </div>
            <div className="mt-40"></div>
          </div>
        </div>
      </div>
    </>
  );
}
