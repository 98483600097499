import { Avatar } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../features/auth/authSlice";
import { navSelector } from "../../features/nav/navSlice";
import styles from "./Logo.module.css";

export function Logo(props) {
  const { currentUser } = useSelector(authSelector);
  const { currentNode } = useSelector(navSelector);
  const [node, setNode] = useState();

  useEffect(() => {
    if (props.node) {
      setNode(props.node);
    } else {
      setNode(currentNode);
    }
  }, [currentNode, props.node]);

  return (
    <>
      {props.type === "custom" ? (
        <>
          {props.data.src ? (
            <Avatar
              className={
                props.size === "xxl"
                  ? styles.logoXXl
                  : props.size === "xl"
                  ? styles.logoXl
                  : props.size === "lg"
                  ? styles.logoLg
                  : styles.logo
              }
              src={props.data.src}
            />
          ) : (
            <Avatar
              className={`${
                props.size === "xxl"
                  ? styles.logoXXl
                  : props.size === "xl"
                  ? styles.logoXl
                  : props.size === "lg"
                  ? styles.logoLg
                  : styles.logo
              } ${styles.empty}`}
            >
              {props.data.name.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </>
      ) : props.type === "node" ? (
        <>
          {node?.logo ? (
            <Avatar
              className={props.size === "lg" ? styles.logoLg : styles.logo}
              src={node.logo}
            />
          ) : (
            <Avatar
              className={`${
                props.size === "lg" ? styles.logoLg : styles.logo
              } ${styles.emptyNode}`}
            >
              {node?.name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </>
      ) : (
        <>
          {currentUser.avatar ? (
            <Avatar
              className={props.size === "lg" ? styles.logoLg : styles.logo}
              src={currentUser.avatar}
            />
          ) : (
            <Avatar
              className={`${
                props.size === "lg" ? styles.logoLg : styles.logo
              } ${styles.empty}`}
            >
              {currentUser?.f_name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </>
      )}
    </>
  );
}
