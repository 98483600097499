import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/img/bg/login.svg";
import tracker from "../../tracker";
import styles from "./Auth.module.css";
import { Header } from "./Header";
import { forgotPassword } from "./authSlice";

export function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(forgotPassword(values)).then((res) => {
      setLoading(false);
      if (res.type === "user/forgotPassword/fulfilled") {
        setSuccess(true);
      } else {
        message.error({
          content: res.payload.errors[0],
          icon: <CloseCircleOutlined />,
        });
      }
    });
  };

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      />
      <Header />

      <div className={styles.content}>
        <div className="h1">Reset Password</div>
        {success ? (
          <>
            <div className={`mt-16 copy ${styles.subTitle}`}>
              If account exists, an email will be sent with further
              instructions.
            </div>
            <Button
              size="large"
              className="mt-40 w-100"
              onClick={() => navigate("/login")}
            >
              Back to homepage
            </Button>
          </>
        ) : (
          <>
            <div className={`mt-16 copy ${styles.subTitle}`}>
              To have your password reset, enter your email address. We will
              send an email containing a link to reset your password.
            </div>
            <div>
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  className="mt-40"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email",
                    },
                  ]}
                >
                  <Input placeholder="Type your email address" type="text" />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="mt-40 w-100"
                  loading={loading}
                >
                  Send Email
                </Button>
              </Form>
            </div>
          </>
        )}
        <div className={`mt-24`}>
          <span>Still having trouble?&nbsp;</span>
          <span className="link">
            <a
              href="mailto:hello@humanitas.ai"
              target="_blank"
              rel="noreferrer"
            >
              Contact support
            </a>
          </span>
        </div>
      </div>
    </>
  );
}
