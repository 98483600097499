import {
  CloseCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Divider,
  Image,
  Input,
  List,
  Modal,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/loader/Loader";
import tracker from "../../tracker";
import { navSelector } from "../nav/navSlice";
import { EditItem } from "./EditItem";
import styles from "./Registry.module.css";
import { getAllNeeds, searchCatalogue, searchSelector } from "./registrySlice";

const PER_PAGE = 24;

export function SearchCatalogue(props) {
  const dispatch = useDispatch();
  const { results, loading, errors, totalCount } = useSelector(searchSelector);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [searched, setSearched] = useState("");
  const [addOpened, setAddOpened] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const { currentNode } = useSelector(navSelector);
  const [emptyState, setEmptyState] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    setData(data.concat(results));
  }, [results]);

  const search = () => {
    if (query.length > 0) {
      setEmptyState(false);
      setSearched(query);
      setCurrentPage(1);
      dispatch(searchCatalogue({ query: query, page: 1, per: PER_PAGE }));
      setData([]);
    } else {
      setEmptyState(true);
    }

    //// TRACK
    tracker.track(`registry-search-click`, {
      query: query,
    });
    ////
  };

  const shortenText = (text) => {
    if (text.length > 35) {
      return text.substring(0, 32) + "...";
    }
    return text;
  };

  const openAdd = (c) => {
    setCurrentItem({
      status: true,
      quantity: 1,
      frequency: 1,
      brands: [],
      sizes: [],
      catalogue: c,
    });
    setAddOpened(true);
  };

  return (
    <>
      <div className={styles.searchBox}>
        <Input
          allowClear
          placeholder="Search"
          size="large"
          onChange={(e) => setQuery(e.target.value)}
          onPressEnter={() => search()}
          suffix={
            <Button
              size="large"
              type={query.length > 0 ? "primary" : "default"}
              className={styles.searchBtn}
              loading={loading}
              icon={<SearchOutlined />}
              onClick={() => search()}
            ></Button>
          }
        />
      </div>
      <div className={styles.searchContent}>
        {emptyState ? (
          <div className={styles.emptyBox}>
            <div className={styles.howTitle}>
              How to add and customize items:
            </div>
            <div className="mt-24">
              <Space size={24} align="top">
                <div>
                  <Badge count={1} color="#F79A87" />
                </div>
                <div className={styles.howContent}>Search for item.</div>
              </Space>
            </div>
            <div className="mt-24">
              <Space size={24} align="top">
                <div>
                  <Badge count={2} color="#8BA3D1" />
                </div>
                <div className={styles.howContent}>
                  Specify the size, brand preferences and quantity.
                </div>
              </Space>
            </div>
            <div className="mt-24">
              <Space size={24} align="top">
                <div>
                  <Badge count={3} color="#EDDE49" />
                </div>
                <div className={styles.howContent}>
                  Change the status to “Inactive” if you don’t need it at the
                  moment.
                </div>
              </Space>
            </div>
            <div className="mt-24">
              <Space size={24} align="top">
                <div>
                  <Badge count={4} color="#9BE91B" />
                </div>
                <div className={styles.howContent}>
                  Write down any additional important information in the
                  “Additional Comments” section.
                </div>
              </Space>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.exp}>
              {loading && currentPage === 1 ? (
                <>Searching...</>
              ) : totalCount > 0 ? (
                <>
                  {totalCount} Search {totalCount === 1 ? "Result" : "Results"}{" "}
                  for "{searched}"
                </>
              ) : (
                <div>
                  Sorry, no results were found for “{searched}”
                  <div className={styles.zeroResult}>
                    <div className={styles.suggest}>Search Suggestions:</div>
                    <ul className={styles.list}>
                      <li className="mt-8">Check your spelling</li>
                      <li className="mt-8">Try more general words</li>
                      <li className="mt-8">
                        Try different words that mean the same thing
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            {(!loading || currentPage > 1) && (
              <InfiniteScroll
                dataLength={data.length}
                next={() => {
                  let p = currentPage + 1;
                  setCurrentPage(p);
                  dispatch(
                    searchCatalogue({ query: query, page: p, per: PER_PAGE })
                  );
                }}
                hasMore={data.length < totalCount}
                loader={
                  <div className="mt-40 ta-c">
                    <Loader size="60" />
                  </div>
                }
                endMessage={
                  <div className="mt-40">
                    <Divider>No more results</Divider>
                  </div>
                }
                style={{ overflowX: "hidden" }}
              >
                <List
                  grid={{
                    gutter: [24, 24],
                    column: 6,
                  }}
                  dataSource={data}
                  renderItem={(r) => (
                    <div className={styles.resultCard}>
                      <Image
                        src={r.image}
                        className={styles.img}
                        preview={false}
                      />
                      <div className={styles.prodName}>
                        {shortenText(r.name)}
                      </div>
                      <div className={styles.prodTax}>
                        {r.taxonomy.join(" -> ")}
                      </div>
                      <Button
                        size="large"
                        className="mt-8 w-100"
                        onClick={() => openAdd(r)}
                      >
                        <Space>
                          <div>Add to List</div>
                          <PlusOutlined />
                        </Space>
                      </Button>
                    </div>
                  )}
                />
              </InfiniteScroll>
            )}
          </div>
        )}
      </div>
      <Modal
        destroyOnClose
        open={addOpened}
        footer={null}
        closable={false}
        width={1000}
        style={{ top: 80 }}
      >
        <EditItem
          type="add"
          onSuccess={() => {
            dispatch(getAllNeeds({ nodeId: currentNode.id }));
            setAddOpened(false);
          }}
          onCancel={() => setAddOpened(false)}
          item={currentItem}
        />
      </Modal>
    </>
  );
}
