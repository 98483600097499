import { ArrowRightOutlined, CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/buttons/BackButton";
import { DragUpload } from "../../../components/files/DragUpload";
import tracker from "../../../tracker";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import { analyzeData } from "../dataSlice";

const FILE_TYPE = 1;

export function AddReceipt() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);
  const [disabled, setDisabled] = useState(true);
  const [showAnalyze, setShowAnalayze] = useState(false);
  const [uploadedIds, setUploadedIds] = useState([]);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  const analyze = () => {
    dispatch(
      analyzeData({
        nodeId: currentNode.id,
        data: { file_type: FILE_TYPE, ids: uploadedIds },
      })
    ).then((res) => {
      if (res.type === "data/analyze/fulfilled") {
        navigate("/data/receipts/analyze");
      }
    });
  };

  return (
    <div className="py-24">
      <div className="px-40">
        {showAnalyze ? (
          <>
            <BackButton nolink onClick={() => setShowAnalayze(false)} />
            <div className={`${styles.title} h1 mt-24`}>
              <CopyOutlined />
              &nbsp;&nbsp;Add Receipts
            </div>
            <div className={`${styles.subTitle}`}>
              Upload receipts for supplies your organization has purchased or
              received. Our AI will look for patterns and suggest an inventory
              list for your In-Kind Registry.
            </div>
            <div className={styles.receiptBox}>
              <div className="subtitle">Upload More?</div>
              <div className="mt-24">
                <Button size="large" onClick={() => setShowAnalayze(false)}>
                  Yes
                </Button>
              </div>
              <div className={`${styles.last} h2 mt-40`}>
                Last step: Analyze receipts and review the changes to your
                In-Kind Gift registry.
              </div>
              <div>
                <Button
                  size="large"
                  type="primary"
                  className="mt-24"
                  onClick={() => analyze()}
                >
                  Analyze&nbsp;
                  <ArrowRightOutlined />
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <BackButton />
            <div className={`${styles.title} h1 mt-24`}>
              <CopyOutlined />
              &nbsp;&nbsp;Add Receipts
            </div>
            <div className={`${styles.subTitle}`}>
              Upload receipts or an itemized list of supplies your organization
              regularly needs. Our AI will look for patterns and suggest an
              inventory list for your In-Kind Registry.
            </div>
            <div className={styles.receiptBox}>
              <DragUpload
                type={1}
                onDisabled={(val) => setDisabled(val)}
                onChangeUploadedIds={(ids) => setUploadedIds(ids)}
              />
              <div className="mt-24">
                <Button
                  size="large"
                  type="primary"
                  disabled={disabled}
                  onClick={() => setShowAnalayze(true)}
                >
                  Next&nbsp;
                  <ArrowRightOutlined />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
