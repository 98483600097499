import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "../../../components/authorize/Authorize";
import { Loader } from "../../../components/loader/Loader";
import tracker from "../../../tracker";
import { Permissions } from "../../nav/Nav";
import { navSelector } from "../../nav/navSlice";
import styles from "../Profile.module.css";
import { getInfo, profileSelector, updateInfo } from "../profileSlice";

export function ContactInfo(props) {
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);
  const { details, initLoading, loading } = useSelector(profileSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentNode.id) {
      dispatch(getInfo({ id: currentNode.id }));
    }
  }, [dispatch, currentNode.id]);

  useEffect(() => {
    if (details.id) {
      form.setFieldsValue(details.handles);
    }
  }, [details, form]);

  const handleSubmit = (values) => {
    dispatch(
      updateInfo({
        id: details.id,
        data: { handles: values },
      })
    ).then((res) => {
      if (res.type === "node/update/fulfilled") {
        message.success({
          content: "Contact updated successfully.",
          icon: <CheckCircleOutlined />,
        });
      }
    });

    //// TRACK
    tracker.track("profileContact-save-click");
    ////
  };

  return (
    <>
      <div>
        <div className="subtitle desc-color">Contact Info</div>
      </div>
      {initLoading ? (
        <div className={styles.loadingContainer}>
          <Loader size={65} />
        </div>
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          requiredMark={false}
          colon={false}
          layout="vertical"
          className={`${styles.form} mt-24`}
          disabled={
            currentNode?.id &&
            !currentNode?.permissions?.includes(Permissions.data)
          }
        >
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item name="web" label="Website">
              <Input />
            </Form.Item>
          </Authorize>

          <Authorize permission={Permissions.data} placement="right">
            <Form.Item label="Social media handles" className="mt-24">
              <Row gutter={8} className="mt-4">
                <Col span={6}>
                  <Form.Item noStyle label={null} className="mt-24">
                    <Input disabled value="Facebook" />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item noStyle name="fb" label={null} className="mt-24">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} className="mt-8">
                <Col span={6}>
                  <Form.Item noStyle label={null} className="mt-24">
                    <Input disabled value="Twitter" />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item noStyle name="tw" label={null} className="mt-24">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} className="mt-8">
                <Col span={6}>
                  <Form.Item noStyle label={null} className="mt-24">
                    <Input disabled value="LinkedIn" />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item noStyle name="in" label={null} className="mt-24">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} className="mt-8">
                <Col span={6}>
                  <Form.Item noStyle label={null} className="mt-24">
                    <Input disabled value="Youtube" />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item noStyle name="yt" label={null} className="mt-24">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Authorize>

          <Authorize permission={Permissions.data} placement="right">
            <Form.Item className="mt-40">
              <Button htmlType="submit" size="large" loading={loading}>
                {new URLSearchParams(window.location.search).get("src") ===
                "onboarding"
                  ? "Confirm profile"
                  : "Save Changes"}
              </Button>
            </Form.Item>
          </Authorize>
        </Form>
      )}
    </>
  );
}
