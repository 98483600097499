import {
  CheckOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Spin, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Authorize } from "../../components/authorize/Authorize";
import { Loader } from "../../components/loader/Loader";
import tracker from "../../tracker";
import { Permissions } from "../nav/Nav";
import { navSelector } from "../nav/navSlice";
import styles from "./Data.module.css";
import { getStatus, statusSelector } from "./dataSlice";

export function Data() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);
  const { data, loading, errors } = useSelector(statusSelector);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getStatus({ nodeId: currentNode.id }));
  }, [dispatch, currentNode]);

  useEffect(() => {
    if (data.receipt) {
      if (data.receipt.processing_c > 0) {
        navigate("/data/receipts/analyze");
      } else if (data.receipt.unapproved_c > 0) {
        navigate("/data/receipts/approve");
      }
    }
  }, [data, navigate]);

  const getPlural = (count, word) => {
    if (count === 1) {
      return `${count} ${word}`;
    } else {
      return `${count} ${word}s`;
    }
  };

  const getCount = (count1, count2, count3) => {
    let icon =
      count3 > 0 ? (
        <ExclamationCircleOutlined />
      ) : count2 > 0 ? (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: "16px" }} spin />}
        />
      ) : count1 > 0 ? (
        <CheckOutlined style={{ color: "#7EAF2C" }} />
      ) : (
        <ExclamationCircleOutlined />
      );
    let text = "";
    if (count3 === 0) {
      text += count1 > 0 ? `${getPlural(count1, "source")} connected. ` : "";
      text += count2 > 0 ? `${getPlural(count2, "source")} analyzing. ` : "";
      text +=
        count1 === 0 && count2 === 0 && count3 === 0
          ? `No sources yet connected. `
          : "";
    } else {
      text = `${getPlural(count3, "source")} pending connection.`;
    }

    return (
      <Space size={8} className={count3 > 0 && "error-txt"}>
        <div>{icon}</div>
        <div>{`${text}`}</div>
      </Space>
    );
  };

  return (
    <>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <div className="pt-40 pb-56">
          <div className="px-40">
            <div className={`${styles.title} h1`}>
              <CopyOutlined />
              &nbsp;&nbsp;Data Sources
            </div>
            <div className={`${styles.subTitle}`}>
              Securely connecting your organization's data helps empower our AI
              technology to save you time and connect you with new donors.
            </div>
            <Space size={48} wrap>
              {/* <div className={styles.card}>
                <div className={styles.name}>
                  <FileTextOutlined />
                  &nbsp;&nbsp;Receipts
                </div>
                <div className={`mt-20 ${styles.msg}`}>
                  {data?.receipt?.total_c === 0 ? (
                    <Space size={8}>
                      <div>
                        <ExclamationCircleOutlined />
                      </div>
                      <div>No sources yet connected.</div>
                    </Space>
                  ) : (
                    <Space size={8}>
                      <div>
                        <CheckOutlined style={{ color: "#9BE91B" }} />
                      </div>
                      <div>{data?.receipt?.total_c} sources connected.</div>
                    </Space>
                  )}
                </div>
                <div className={`${styles.desc} mt-24`}>
                  Teach us about your recurring supply needs. From this data
                  we'll build an In-Kind Gift Registry that our retail partners
                  will use to help you fundraise.
                </div>
                <Row className="mt-24">
                  <Col span={12}>
                    <Button
                      size="large"
                      onClick={() => navigate("/data/receipts/all")}
                      disabled={data?.receipt?.total_c === 0}
                    >
                      See All
                    </Button>
                  </Col>
                  <Col span={12} className="ta-r">
                    <Button
                      size="large"
                      onClick={() => navigate("/data/receipts/add")}
                    >
                      Connect&nbsp;
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
              </div> */}
              <div className={styles.card}>
                <div className="subtitle">
                  <Space size={16}>
                    <FilePdfOutlined />
                    <div>Annual Reports</div>
                  </Space>
                </div>
                <div className={`mt-16 small`}>
                  {getCount(
                    data?.annual?.processed_c,
                    data?.annual?.processing_c,
                    data?.annual?.uploaded_c
                  )}
                </div>
                <div className={`${styles.desc} mt-16`}>
                  Share more about your organization's impacts and progress to
                  teach your AI assistant to answer questions on your website.
                </div>
                <Row className="mt-16">
                  <Col span={12}>
                    <Button
                      size="large"
                      onClick={() => navigate("/data/annual/all")}
                      disabled={data?.annual?.total_c === 0}
                    >
                      See All
                    </Button>
                  </Col>
                  <Col span={12} className="ta-r">
                    <Authorize permission={Permissions.data}>
                      <Button
                        size="large"
                        onClick={() => navigate("/data/annual/add")}
                        disabled={
                          currentNode?.id &&
                          !currentNode?.permissions?.includes(Permissions.data)
                        }
                      >
                        <Space size={16}>
                          Connect
                          <PlusOutlined />
                        </Space>
                      </Button>
                    </Authorize>
                  </Col>
                </Row>
              </div>

              <div className={styles.card}>
                <div className="subtitle">
                  <Space size={16}>
                    <FolderOpenOutlined />
                    <div>Other</div>
                  </Space>
                </div>
                <div className={`mt-16 small`}>
                  {getCount(
                    data?.other?.processed_c,
                    data?.other?.processing_c,
                    data?.other?.uploaded_c
                  )}
                </div>
                <div className={`${styles.desc} mt-16`}>
                  Bring any document into play. From impact reports to blog
                  posts, each unique file refines your AI Assistant, tuning it
                  to assist you better.
                </div>
                <Row className="mt-16">
                  <Col span={12}>
                    <Button
                      size="large"
                      onClick={() => navigate("/data/other/all")}
                      disabled={data?.other?.total_c === 0}
                    >
                      See All
                    </Button>
                  </Col>
                  <Col span={12} className="ta-r">
                    <Authorize permission={Permissions.data}>
                      <Button
                        size="large"
                        onClick={() => navigate("/data/other/add")}
                        disabled={
                          currentNode?.id &&
                          !currentNode?.permissions?.includes(Permissions.data)
                        }
                      >
                        <Space size={16}>
                          Connect
                          <PlusOutlined />
                        </Space>
                      </Button>
                    </Authorize>
                  </Col>
                </Row>
              </div>

              {currentNode?.id && (
                <div className={styles.card}>
                  <div className="subtitle">
                    <Space size={16}>
                      <FileTextOutlined />
                      <div>990 Forms</div>
                    </Space>
                  </div>
                  <div className={`mt-16 small`}>
                    {getCount(
                      data?.irs?.processed_c,
                      data?.irs?.processing_c,
                      data?.irs?.uploaded_c
                    )}
                  </div>
                  <div className={`${styles.desc} mt-16`}>
                    Connect these tax forms to quickly orient your AI Assistant
                    so it can save you time writing grant responses, emails,
                    blog posts, and much more.
                  </div>
                  <Row className="mt-16">
                    <Col span={12}>
                      <Button
                        size="large"
                        onClick={() => navigate("/data/irs/all")}
                        disabled={data?.irs?.total_c === 0}
                      >
                        See All
                      </Button>
                    </Col>
                    <Col span={12} className="ta-r">
                      <Authorize permission={Permissions.data}>
                        <Button
                          size="large"
                          onClick={() => navigate("/data/irs/add")}
                          disabled={
                            currentNode?.id &&
                            !currentNode?.permissions?.includes(
                              Permissions.data
                            )
                          }
                        >
                          <Space size={16}>
                            Connect
                            <PlusOutlined />
                          </Space>
                        </Button>
                      </Authorize>
                    </Col>
                  </Row>
                </div>
              )}
            </Space>
          </div>
        </div>
      )}
    </>
  );
}
