import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { Permissions } from "../../features/nav/Nav";
import { navSelector } from "../../features/nav/navSlice";

export function Authorize(props) {
  const { currentNode } = useSelector(navSelector);

  const getRole = (p) => {
    if (p === Permissions.data) {
      return "Admins and Editors";
    } else if (p === Permissions.team) {
      return "Admins";
    }
  };

  return (
    <>
      {currentNode?.id &&
      !currentNode?.permissions?.includes(props.permission) ? (
        <Tooltip
          title={`Only ${getRole(props.permission)} can perform this action.`}
          placement={props.placement || "top"}
        >
          {props.children}
        </Tooltip>
      ) : (
        props.children
      )}
    </>
  );
}
