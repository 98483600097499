import { ArrowRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import bg from "../../assets/img/bg/login.svg";
import tracker from "../../tracker";
import styles from "./Auth.module.css";
import { Header } from "./Header";
import { authSelector, clearErrors, login } from "./authSlice";

export function Login() {
  const navigate = useNavigate();
  const { currentUser, loading, errors } = useSelector(authSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  useEffect(() => {
    if (currentUser.id) {
      //// TRACK
      tracker.identify(`${currentUser.id}`, {
        $name: `${currentUser.f_name} ${currentUser.l_name}`,
        "User ID": currentUser.id,
      });
      ////
      navigate("/home");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      />
      <Header />

      <div className={styles.content}>
        <div className="h1">Log in</div>
        <div className={`mt-16 copy ${styles.subTitle}`}>
          Your multi-purpose AI is ready to maximize your impact. Your mission
          is our mission.
        </div>
        <div>
          <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
            <Form.Item
              name="email"
              label="Email"
              className="mt-40"
              rules={[
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input placeholder="Type your email address" type="email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              className="mt-16"
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Type your password"
              />
            </Form.Item>
            <div className="link underline mt-16">
              <Link to="/forgot_password">Forgot your password?</Link>
            </div>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="mt-40 w-100"
              loading={loading}
            >
              Login&nbsp;
              <ArrowRightOutlined />
            </Button>
          </Form>
        </div>
        <div className={`mt-24`}>
          <span>New to Humanitas?&nbsp;</span>
          <span className="link underline">
            <Link to="/search">Create an account</Link>
          </span>
        </div>
      </div>
    </>
  );
}
