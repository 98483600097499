import { CheckCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Form, Input, Row, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "../../../components/authorize/Authorize";
import { Loader } from "../../../components/loader/Loader";
import tracker from "../../../tracker";
import { Permissions } from "../../nav/Nav";
import { navSelector } from "../../nav/navSlice";
import styles from "../Profile.module.css";
import { getInfo, profileSelector, updateInfo } from "../profileSlice";

export function BasicInfo(props) {
  const dispatch = useDispatch();
  const { details, initLoading, loading } = useSelector(profileSelector);
  const [logoFile, setLogoFile] = useState();
  const [logo, setLogo] = useState();
  const { currentNode } = useSelector(navSelector);
  const [form] = Form.useForm();

  const onLogochange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.file);
    reader.onload = () => {
      setLogo(reader.result);
    };
    setLogoFile(e.file);
  };

  useEffect(() => {
    if (currentNode.id) {
      dispatch(getInfo({ id: currentNode.id }));
    }
  }, [dispatch, currentNode.id]);

  const handleSubmit = (values) => {
    delete values["address"];
    let obj = {
      ...values,
      ein: values["ein"].replace("-", ""),
    };
    if (logoFile) {
      let fd = new FormData();
      fd.append("avatar", logoFile);
      for (let key in obj) {
        fd.append(key, obj[key]);
      }
      dispatch(
        updateInfo({
          id: details.id,
          data: fd,
        })
      ).then((res) => {
        if (res.type === "node/update/fulfilled") {
          const o =
            new URLSearchParams(window.location.search).get("src") ===
            "onboarding";
          message.success({
            content: o
              ? "Profile confirmed. Return home for next steps."
              : "Profile updated successfully.",
            icon: <CheckCircleOutlined />,
          });
          props.onUpdated(res.payload);
        }
      });
    } else {
      dispatch(
        updateInfo({
          id: details.id,
          data: obj,
        })
      ).then((res) => {
        if (res.type === "node/update/fulfilled") {
          const o =
            new URLSearchParams(window.location.search).get("src") ===
            "onboarding";
          message.success({
            content: o
              ? "Profile confirmed. Return home for next steps."
              : "Profile updated successfully.",
            icon: <CheckCircleOutlined />,
          });
          props.onUpdated(res.payload);
        }
      });
    }

    //// TRACK
    tracker.track("profileBasic-save-click");
    ////
  };

  useEffect(() => {
    if (details.id) {
      form.setFieldsValue({
        ...details,
        ein: `${details.ein.slice(0, 2)}-${details.ein.slice(
          2,
          details.ein.length
        )}`,
      });
    }
  }, [details, form]);

  return (
    <>
      <div>
        <div className="subtitle desc-color">Basic Info</div>
      </div>
      {initLoading ? (
        <div className={styles.loadingContainer}>
          <Loader size={65} />
        </div>
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          requiredMark={false}
          colon={false}
          layout="vertical"
          className={`${styles.form} mt-24`}
          disabled={
            currentNode?.id &&
            !currentNode?.permissions?.includes(Permissions.data)
          }
        >
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item
              name="name"
              label="Organization Name"
              rules={[
                {
                  required: true,
                  message: "Please input your nonprofit's Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Authorize>
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item label="Logo" className="mt-24">
              <Row align="middle" gutter={40}>
                <Col span={6}>
                  {logo || details.logo ? (
                    <Avatar
                      className={styles.logo}
                      src={logo ? logo : details.logo}
                    />
                  ) : (
                    <Avatar
                      className={`${styles.logo} ${styles.empty}`}
                      alt="Logo"
                    >
                      {details?.name?.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                </Col>
                <Col span={18}>
                  <ImgCrop
                    rotationSlider={true}
                    zoomSlider={true}
                    quality={1}
                    modalTitle="Preview Logo"
                  >
                    <Upload
                      customRequest={onLogochange}
                      maxCount={1}
                      showUploadList={false}
                    >
                      <Button size="large">Upload from computer</Button>
                      <EditOutlined
                        className="edit-upload"
                        style={{ color: "white" }}
                      />
                    </Upload>
                  </ImgCrop>
                </Col>
              </Row>
            </Form.Item>
          </Authorize>
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item
              name="ein"
              label="EIN"
              className="mt-24"
              rules={[
                {
                  required: true,
                  message: "Please input your nonprofit's EIN!",
                },
              ]}
            >
              <Input disabled placeholder="XX-XXXXXXX" />
            </Form.Item>
            <div className="mt-4 small sub-color">
              If you need to change your EIN please{" "}
              <a
                href="mailto:hello@humanitas.ai"
                target="_blank"
                rel="noreferrer"
                className="dark-color pointer"
              >
                contact support
              </a>
              .
            </div>
          </Authorize>
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item
              name="about"
              label="Mission"
              className="mt-24"
              rules={[
                {
                  required: true,
                  message:
                    "Please input a short description about your nonprofit!",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={1000}
                autoSize={{ minRows: 4 }}
                placeholder="Share with us the overall mission of your non-profit"
              />
            </Form.Item>
          </Authorize>
          <Authorize permission={Permissions.data} placement="right">
            <Form.Item className="mt-40">
              <Button htmlType="submit" size="large" loading={loading}>
                {new URLSearchParams(window.location.search).get("src") ===
                "onboarding"
                  ? "Confirm profile"
                  : "Save Changes"}
              </Button>
            </Form.Item>
          </Authorize>
        </Form>
      )}
    </>
  );
}
