import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  conversations: {
    data: [],
    loading: false,
    errors: null,
  },
  chats: {
    data: [],
    loading: false,
    errors: null,
  },
};

export const getAllConversations = createAsyncThunk(
  "conversations/getAll",
  async (data, thunk) => {
    try {
      let response = await API.conversations.index(data.nodeId, data.page);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createConversation = createAsyncThunk(
  "conversations/create",
  async (data, thunk) => {
    try {
      let response = await API.conversations.create(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateConversation = createAsyncThunk(
  "conversations/update",
  async (data, thunk) => {
    try {
      let response = await API.conversations.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteConversation = createAsyncThunk(
  "conversations/delete",
  async (data, thunk) => {
    try {
      let response = await API.conversations.delete(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getAllChats = createAsyncThunk(
  "chats/getAll",
  async (data, thunk) => {
    try {
      let response = await API.chats.index(data.conversationId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createChat = createAsyncThunk(
  "chats/create",
  async (data, thunk) => {
    try {
      let response = await API.chats.create(data.conversationId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateChat = createAsyncThunk(
  "chats/update",
  async (data, thunk) => {
    try {
      let response = await API.chats.update(
        data.conversationId,
        data.id,
        data.data
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const assistantSlice = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    resetChats: (state) => {
      state.chats = {
        data: [],
        loading: false,
        errors: null,
      };
    },
  },
  extraReducers: {
    [getAllConversations.pending]: (state) => {
      state.conversations.loading = true;
      state.conversations.data = [];
    },
    [getAllConversations.fulfilled]: (state, action) => {
      state.conversations.loading = false;
      state.conversations.data = action.payload.conversations;
    },
    [getAllConversations.rejected]: (state, action) => {
      state.conversations.loading = false;
      state.conversations.errors = action.payload?.errors;
    },
    [getAllChats.pending]: (state) => {
      state.chats.loading = true;
    },
    [getAllChats.fulfilled]: (state, action) => {
      state.chats.loading = false;
      state.chats.data = action.payload.chats;
    },
    [getAllChats.rejected]: (state, action) => {
      state.chats.loading = false;
      state.chats.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors, resetChats } = assistantSlice.actions;

export const conversationsSelector = (state) => state.assistant.conversations;
export const chatsSelector = (state) => state.assistant.chats;

export default assistantSlice.reducer;
