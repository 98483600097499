import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  details: {},
  initLoading: true,
  loading: false,
  errors: null,
  admins: {
    data: [],
    loading: false,
    errors: null,
  },
};

export const getInfo = createAsyncThunk("node/details", async (data, thunk) => {
  try {
    let response = await API.profile.get(data.id);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getAdmins = createAsyncThunk(
  "node/admins/index",
  async (data, thunk) => {
    try {
      let response = await API.profile.admins.get(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const addAdmin = createAsyncThunk(
  "node/admins/create",
  async (data, thunk) => {
    try {
      let response = await API.profile.admins.create(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "node/admins/update",
  async (data, thunk) => {
    try {
      let response = await API.profile.admins.update(
        data.id,
        data.adminId,
        data.data
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "node/admins/delete",
  async (data, thunk) => {
    try {
      let response = await API.profile.admins.delete(data.id, data.adminId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateInfo = createAsyncThunk(
  "node/update",
  async (data, thunk) => {
    try {
      let response = await API.profile.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createLocation = createAsyncThunk(
  "node/locations/create",
  async (data, thunk) => {
    try {
      let response = await API.profile.locationCreate(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
      state.admins.errors = null;
    },
  },
  extraReducers: {
    [getInfo.pending]: (state) => {
      state.initLoading = true;
    },
    [getInfo.fulfilled]: (state, action) => {
      state.initLoading = false;
      state.details = action.payload;
    },
    [getInfo.rejected]: (state, action) => {
      state.initLoading = false;
      state.errors = action.payload.errors
        ? action.payload.errors
        : ["Nonprofit Fetch Failed. Try again later."];
    },
    [updateInfo.pending]: (state) => {
      state.loading = true;
    },
    [updateInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.info = action.payload;
    },
    [updateInfo.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors
        ? action.payload.errors
        : ["Nonprofit Update Failed. Try again later."];
    },
    [createLocation.pending]: (state, action) => {
      state.loading = true;
    },
    [createLocation.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createLocation.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors
        ? action.payload.errors
        : ["Location Update Failed. Try again later."];
    },
    [getAdmins.pending]: (state) => {
      state.admins.loading = true;
    },
    [getAdmins.fulfilled]: (state, action) => {
      state.admins.loading = false;
      state.admins.data = action.payload.admins.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [getAdmins.rejected]: (state, action) => {
      state.admins.loading = false;
      state.admins.errors = action.payload.errors;
    },
    [addAdmin.pending]: (state) => {
      state.admins.loading = true;
    },
    [addAdmin.fulfilled]: (state, action) => {
      state.admins.loading = false;
      state.admins.data = action.payload.admins.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [addAdmin.rejected]: (state, action) => {
      state.admins.loading = false;
      state.admins.errors = action.payload.errors;
    },
    [updateAdmin.pending]: (state) => {
      state.admins.loading = true;
    },
    [updateAdmin.fulfilled]: (state, action) => {
      state.admins.loading = false;
      state.admins.data = action.payload.admins.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [updateAdmin.rejected]: (state, action) => {
      state.admins.loading = false;
      state.admins.errors = action.payload.errors;
    },
    [deleteAdmin.pending]: (state) => {
      state.admins.loading = true;
    },
    [deleteAdmin.fulfilled]: (state, action) => {
      state.admins.loading = false;
      state.admins.data = action.payload.admins.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [deleteAdmin.rejected]: (state, action) => {
      state.admins.loading = false;
      state.admins.errors = action.payload.errors;
    },
  },
});

export const { clearErrors } = profileSlice.actions;

export const profileSelector = (state) => state.profile;
export const adminsSelector = (state) => state.profile.admins;

export default profileSlice.reducer;
