import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";

export function Confirm(props) {
  return (
    <Modal
      destroyOnClose
      open={props.open}
      footer={null}
      closable={false}
      width={424}
    >
      <div className="modalContainer">
        <Row align="middle">
          <Col span={20} className="modalHead">
            {props.header}
          </Col>
          <Col span={4} className="ta-r modalClose">
            <CloseOutlined onClick={props.onCancel} />
          </Col>
        </Row>
        <div className="mt-8">
          <div className="h2">{props.title}</div>
        </div>
        <div className="mt-16 modalBody">{props.desc} </div>
        <div className="mt-24">
          <Row gutter={16}>
            <Col span={12}>
              <Button
                danger
                size="large"
                className="w-100"
                onClick={props.onConfirm}
              >
                {props.btn}
              </Button>
            </Col>
            <Col span={12}>
              <Button size="large" className="w-100" onClick={props.onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}
