import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Modal,
  Row,
  Slider,
  Space,
  Tooltip,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import lock1 from "../../assets/img/lock1.svg";
import lock2 from "../../assets/img/lock2.svg";
import { Loader } from "../../components/loader/Loader";
import { socket } from "../../socket";
import tracker from "../../tracker";
import { authSelector, updateHasSeen, updateUser } from "../auth/authSlice";
import { getStatus, statusSelector } from "../data/dataSlice";
import { markOnboardingTask } from "../home/widgets/widgetSlice";
import { navSelector } from "../nav/navSlice";
import styles from "./Assistant.module.css";
import { Chat } from "./Chat";
import { Help } from "./Help";
import { Troubleshoot } from "./Troubleshoot";
import { Welcome } from "./Welcome";
import {
  conversationsSelector,
  createConversation,
  deleteConversation,
  getAllConversations,
  updateConversation,
} from "./assistantSlice";

const { TextArea } = Input;

export function Assistant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentNode } = useSelector(navSelector);
  const { currentUser } = useSelector(authSelector);
  const { data: status, loading: statusLoading } = useSelector(statusSelector);

  const { data, loading, errors } = useSelector(conversationsSelector);

  const [history, setHistory] = useState([]);
  const [currentConversation, setCurrentConversation] = useState({ id: null });
  const [isConnected, setIsConnected] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [answerStream, setAnswerStream] = useState("");
  const [temperature, setTemperature] = useState();
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [customOpened, setCustomOpened] = useState(false);
  const [helpOpened, setHelpOpened] = useState(false);
  const [troubleshootOpened, setTroubleshootOpened] = useState(false);
  const [welcomeOpened, setWelcomeOpened] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);
  const [chosenPrompt, setChosenPrompt] = useState(null);
  const [answerFinished, setAnswerFinished] = useState(false);
  const [answerPersonaId, setAnswerPersonaId] = useState(1);
  const [tokenUsage, setTokenUsage] = useState({});

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  const showError = (msg) => {
    message.error({
      content: msg,
      icon: <CloseCircleOutlined />,
    });
  };

  useEffect(() => {
    dispatch(getStatus({ nodeId: currentNode.id }));
  }, [dispatch, currentNode]);

  useEffect(() => {
    if (currentUser.id) {
      setTemperature(currentUser.temperature);
    }
  }, [currentUser]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      showError(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    setCurrentConversation({ id: null });
    setAnswerStream("");
    dispatch(getAllConversations({ nodeId: currentNode.id, page: 1 }));
  }, [dispatch, currentNode.id]);

  useEffect(() => {
    setHistory(data);
  }, [data]);

  useEffect(() => {
    if (
      !currentUser?.has_seen?.includes(1) &&
      status?.annual?.processed_c > 0
    ) {
      setWelcomeOpened(true);
    }
  }, [status, currentUser]);

  useEffect(() => {
    function onConnect() {
      console.log("Connected Socket");
      setIsConnected(true);
    }

    function onConnectError(err) {
      console.log(err.message);
    }

    function onDisconnect() {
      console.log("Disconnected Socket");
      setIsConnected(false);
    }

    function onAnswer(val) {
      if (val.includes("[FIN]")) {
        setAnswerFinished(true);
      } else if (val.includes("[PERSONA_ID]")) {
        let split = val.split(":");
        setAnswerPersonaId(split.length > 1 ? split[1] : 1);
      } else {
        setAnswerStream((state) => state + val);
      }
    }

    function onTokenUsageChange(val) {
      if (val?.totalTokens) {
        setTokenUsage({
          p: val?.promptTokens,
          c: val?.completionTokens,
          t: val?.totalTokens,
        });
      }
    }

    function onNameChangeSuccess(val) {
      if (val?.name) {
        setCurrentConversation((state) => {
          return { ...state, name: val.name };
        });
        setHistory((state) => {
          return state.map((s) => {
            if (val?.name) {
              if (s.id === val.id) {
                return { ...s, name: val.name };
              }
            }
            return s;
          });
        });
      }
    }

    socket.io.opts.query = {
      uid: localStorage.getItem("uid"),
      "access-token": localStorage.getItem("access-token"),
      client: localStorage.getItem("client"),
    };
    if (currentNode?.id) {
      socket.io.opts.query = {
        ...socket.io.opts.query,
        nodeId: currentNode.id,
        nodeName: currentNode.name,
      };
    } else {
      socket.io.opts.query = {
        ...socket.io.opts.query,
        nodeName: `${currentUser.f_name}'s nonprofit`,
      };
    }
    socket.connect();
    socket.on("connect", onConnect);
    socket.on("connect_error", onConnectError);
    socket.on("disconnect", onDisconnect);
    socket.on("assistant-answer", onAnswer);
    socket.on("token-usage", onTokenUsageChange);
    socket.on("name-change-success", onNameChangeSuccess);

    return () => {
      socket.disconnect();
      socket.off("connect", onConnect);
      socket.off("connect_error", onConnectError);
      socket.off("disconnect", onDisconnect);
      socket.off("assistant-answer", onAnswer);
      socket.off("name-change-success", onNameChangeSuccess);
    };
  }, [currentNode]);

  const openNew = (prompt) => {
    //// TRACK
    tracker.track("assistant-newConversation-create", {
      personaId: 1,
      personaName: "Auto",
    });
    ////
    let params = {
      name: "New chat",
      // model: "gpt-3.5-turbo-0125",
      persona_id: 1, // Defaults to Auto
    };
    if (currentNode?.id) {
      params = { ...params, node_id: currentNode.id };
    }
    dispatch(createConversation(params)).then((res) => {
      if (res.type === "conversations/create/fulfilled") {
        setHistory([res.payload].concat(history));
        setCurrentConversation(res.payload);
        if (prompt) {
          setChosenPrompt(prompt);
        }
      } else {
        showError("Error opening new conversation. Try again later.");
      }
    });
  };

  const shortenText = (text) => {
    if (text.length > 36) {
      return text.substring(0, 33) + "...";
    }
    return text;
  };

  const destroy = (id) => {
    dispatch(deleteConversation({ id: id }));
    if (currentConversation.id === id) {
      setCurrentConversation({ id: null });
    }
    setHistory((state) => {
      return state.filter((s) => s.id !== id);
    });
  };

  return (
    <>
      {statusLoading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <div className={styles.chatContainer}>
          {status?.annual?.processed_c +
            status?.other?.processed_c +
            status?.irs?.processed_c ===
            0 && (
            <div className={styles.overlayContainer}>
              <div className={styles.lockContainer}>
                {status?.annual?.processing_c +
                  status?.other?.processing_c +
                  status?.irs?.processing_c ===
                0 ? (
                  <div>
                    <Row align="middle">
                      <Col span={20}>
                        <div className="accent accent-color">AI Assistant</div>
                      </Col>
                      <Col span={4}></Col>
                    </Row>
                    <div className="mt-8 h2">
                      Your AI Assistant needs data sources to work for you
                    </div>
                    <div className="mt-16 sub-color">
                      To get your AI Assistant up and running, connect your data
                      sources. Without this step, your AI Assistant won't be
                      able to function.
                    </div>
                    <div className="mt-40 ta-c">
                      <img src={lock1} alt="Lock" />
                    </div>
                    <div className="mt-40">
                      <Button
                        type="primary"
                        size="large"
                        className="w-100"
                        onClick={() => navigate("/data")}
                      >
                        <Space size={16} align="center">
                          <div>Connect data sources</div>
                          <ArrowRightOutlined />
                        </Space>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Row align="middle">
                      <Col span={20}>
                        <div className="accent accent-color">AI Assistant</div>
                      </Col>
                      <Col span={4}></Col>
                    </Row>
                    <div className="mt-8 h2">
                      The AI Assistant is analyzing your data
                    </div>
                    <div className="mt-16 sub-color">
                      We're crunching the numbers from your first data source.
                      Hang tight - your AI Assistant will be ready soon!
                    </div>
                    <div className="ta-c mt-40">
                      <img src={lock2} alt="Lock" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={styles.sidebar}>
            <div className={styles.options}>
              <Button
                size="large"
                type="dashed"
                className="w-100"
                onClick={() => {
                  if (streaming) {
                    message.error({
                      content:
                        "You can change chats once your current answer is completed",
                      icon: <CloseCircleOutlined />,
                    });
                    return;
                  }
                  setCurrentConversation({ id: null });
                }}
              >
                <Space size={16}>
                  <div>New chat</div>
                  <PlusOutlined />
                </Space>
              </Button>
            </div>
            <div className={styles.list}>
              {history.map((h) => (
                <div
                  className={`small ${styles.question} ${
                    h.id === currentConversation?.id ? styles.selected : ""
                  }`}
                  key={h.id}
                  onClick={() => {
                    if (streaming) {
                      message.error({
                        content:
                          "You can change your chat once current answer is completed",
                        icon: <CloseCircleOutlined />,
                      });
                      return;
                    }
                    setCurrentConversation(h);
                    //// TRACK
                    tracker.track("assistant-history-choose", {
                      conversationId: h.id,
                      conversationName: h.name,
                    });
                    ////
                  }}
                >
                  <Space size={16}>
                    <div className={styles.icon}>
                      <MessageOutlined />
                    </div>
                    <div>{shortenText(h.name)}</div>
                  </Space>
                  {currentConversation?.id === h.id && (
                    <div className={styles.optContainer}>
                      <div
                        className={styles.opt}
                        onClick={(e) => {
                          e.stopPropagation();
                          destroy(h.id);
                          //// TRACK
                          tracker.track("assistant-history-del", {
                            conversationId: h.id,
                            conversationName: h.name,
                          });
                          ////
                        }}
                      >
                        <DeleteOutlined />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.chatBox}>
            <div className={styles.header}>
              <Row align="middle">
                <Col span={6}></Col>
                <Col span={18} className="ta-r">
                  <Space size={16}>
                    <div style={{ height: "40px", marginRight: "8px" }}>
                      <Button
                        type="dashed"
                        size="large"
                        onClick={() => {
                          setHelpOpened(true);
                          //// TRACK
                          tracker.track("assistant-help-click");
                          ////
                        }}
                      >
                        What can my assistant do?
                      </Button>
                    </div>
                    <div className={styles.ellipsis}>
                      <Dropdown
                        menu={{
                          className: styles.ellipsisOptions,
                          items: [
                            {
                              key: "troubleshoot",
                              onClick: () => {
                                setTroubleshootOpened(true);
                                //// TRACK
                                tracker.track("assistant-troubleshoot-click");
                                ////
                              },
                              label: <>Troubleshoot</>,
                            },
                            {
                              key: "welcome",
                              onClick: () => {
                                setWelcomeOpened(true);
                                //// TRACK
                                tracker.track("assistant-welcomeVideo-click");
                                ////
                              },
                              label: <>Welcome video</>,
                            },
                          ],
                        }}
                        trigger={["click"]}
                      >
                        <QuestionCircleOutlined />
                      </Dropdown>
                    </div>
                    <div className={styles.ellipsis}>
                      <Dropdown
                        menu={{
                          className: styles.ellipsisOptions,
                          items: [
                            {
                              key: "settings",
                              onClick: () => {
                                setTemperature(currentUser.temperature);
                                setSettingsOpened(true);
                                //// TRACK
                                tracker.track("assistant-settings-click");
                                ////
                              },
                              label: <>Settings</>,
                            },
                          ],
                        }}
                        trigger={["click"]}
                      >
                        <EllipsisOutlined rotate={90} />
                      </Dropdown>
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>
            <Chat
              isConnected={isConnected}
              currentConversation={currentConversation}
              answerStream={answerStream}
              answerFinished={answerFinished}
              answerPersonaId={answerPersonaId}
              onCreate={(prompt) => openNew(prompt)}
              onAnswerSaved={() => {
                setAnswerStream("");
                setAnswerFinished(false);
                setAnswerPersonaId(1);
              }}
              initPrompt={chosenPrompt}
              tokenUsage={tokenUsage}
              onSend={(data) => {
                socket.emit("assistant-query", {
                  ...data,
                  temperature: currentUser.temperature / 100,
                  conversationId: currentConversation.id,
                  conversationPersonaId: currentConversation.custom
                    ? 4
                    : currentConversation.persona_id,
                  custom: currentConversation.custom,
                });
                setChosenPrompt(null);
              }}
              onStreaming={(status) => setStreaming(status)}
              // onStopStream={() => socket.emit("assistant-query", { cmd: "stop" })}
              onHelpClicked={() => {
                setHelpOpened(true);
                //// TRACK
                tracker.track("assistant-chatHelp-click");
                ////
              }}
            />
          </div>
        </div>
      )}
      <Modal
        destroyOnClose
        open={settingsOpened}
        footer={null}
        closable={false}
        width={424}
        onCancel={() => setSettingsOpened(false)}
      >
        <div>
          <Row align="middle">
            <Col span={20}>
              <div className="accent accent-color">Settings</div>
            </Col>
            <Col span={4} className="ta-r">
              <CloseOutlined onClick={() => setSettingsOpened(false)} />
            </Col>
          </Row>
          <div className="mt-8">
            <div className="h2">AI Assistant Settings</div>
          </div>
          <div className="mt-16 sub-color">
            Updating these settings changes how your AI Assistant responds in
            the chat and across any browser extensions.
          </div>
          <div className="mt-24">
            <Row gutter={16}>
              <Col span={12}>
                <div>
                  <Space size={16}>
                    <div className="bold-body">Creativity</div>
                    <Tooltip title="This refers to how original the AI's answers are. A lower creativity score means the AI Assistant's answers will be more straightforward and less conversational.">
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  </Space>
                </div>
              </Col>
              <Col span={12} className="ta-r">
                <span className="small sub-color">{temperature}%</span>
              </Col>
            </Row>
            <div className="mt-12">
              <Slider
                defaultValue={temperature}
                step={10}
                tooltip={{ open: false }}
                onChange={(val) => setTemperature(val)}
              />
            </div>
          </div>
          <div className="mt-40">
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  onClick={() => {
                    dispatch(updateUser({ temperature: temperature })).then(
                      (res) => {
                        if (res.type === "user/update/fulfilled") {
                          setSettingsOpened(false);
                        }
                      }
                    );
                    //// TRACK
                    tracker.track("assistant-settings-update", {
                      temperature: temperature,
                    });
                    ////
                  }}
                >
                  Save
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className="w-100"
                  onClick={() => setSettingsOpened(false)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        open={customOpened}
        closable={false}
        footer={null}
        onCancel={() => setCustomOpened(false)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(val) =>
            dispatch(
              updateConversation({
                id: currentConversation.id,
                data: val,
              })
            ).then((res) => {
              if (res.type === "conversations/update/fulfilled") {
                setSettingsOpened(false);
                setCustomOpened(false);
                setCurrentConversation(res.payload);
                setHistory((state) => {
                  return state.map((s) => {
                    if (s.id === res.payload.id) {
                      s.custom = res.payload.custom;
                    }
                    return s;
                  });
                });
              } else {
                message.error({
                  content: "Error while deleting file, try again later",
                  icon: <CloseCircleOutlined />,
                });
              }
            })
          }
          initialValues={{ custom: currentConversation.custom }}
        >
          <Form.Item
            name="custom"
            label="Custom prompt to override default prompt"
            rules={[
              {
                required: true,
                message: "Please input a custom prompt",
              },
            ]}
          >
            <TextArea rows={10} />
          </Form.Item>
          <div className="ta-r">
            {!currentConversation.id && (
              <div className="mt-16">
                (Can only set custom prompt once you start a new conversation)
              </div>
            )}
            <Button
              className="mt-24"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={!currentConversation.id}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        destroyOnClose
        open={helpOpened}
        footer={null}
        closable={false}
        width={840}
        style={{ top: 56 }}
      >
        <Help
          onCancel={() => setHelpOpened(false)}
          onPromptChoose={(prompt) => {
            openNew(prompt);
            setHelpOpened(false);
          }}
        />
      </Modal>
      <Modal
        destroyOnClose
        open={troubleshootOpened}
        footer={null}
        closable={false}
        width={648}
        style={{ top: 56 }}
      >
        <Troubleshoot onCancel={() => setTroubleshootOpened(false)} />
      </Modal>
      <Modal
        destroyOnClose
        open={welcomeOpened}
        footer={null}
        closable={false}
        width={840}
        style={{ top: 56 }}
        afterOpenChange={(open) => setLoadVideo(open)}
      >
        <Welcome
          showVideo={loadVideo}
          onCancel={() => {
            setWelcomeOpened(false);
            if (
              new URLSearchParams(window.location.search).get("src") ===
              "onboarding"
            ) {
              dispatch(updateHasSeen({ id: 1 }));
              dispatch(
                markOnboardingTask({ nodeId: currentNode.id, taskId: 4 })
              );
              message.success({
                content: "Your AI Assistant is ready to help",
                icon: <CheckCircleOutlined />,
              });
            }
          }}
        />
      </Modal>
    </>
  );
}
