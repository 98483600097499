import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  file: {
    data: {},
    loading: false,
    errors: null,
  },
  status: {
    data: {},
    loading: false,
    errors: null,
  },
  files: {
    data: [],
    loading: false,
    errors: null,
  },
  search: {
    data: [],
    loading: false,
    errors: null,
  },
};

export const getUploadURL = createAsyncThunk(
  "data/presign",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.presign(data.type);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createFile = createAsyncThunk(
  "data/create",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.create(data.nodeId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getFile = createAsyncThunk("data/show", async (data, thunk) => {
  try {
    let response = await API.data_sources.show(data.nodeId, data.dataId);
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const updateFile = createAsyncThunk(
  "data/update",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.update(
        data.nodeId,
        data.dataId,
        data.data
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getFiles = createAsyncThunk(
  "data/details",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.index(data.nodeId, data.fileType);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteFile = createAsyncThunk(
  "data/delete",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.delete(data.nodeId, data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getStatus = createAsyncThunk(
  "data/status",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.status(data.nodeId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const analyzeData = createAsyncThunk(
  "data/analyze",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.analyze(data.nodeId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const searchFiles = createAsyncThunk(
  "data/search",
  async (data, thunk) => {
    try {
      let response = await API.data_sources.search(data.nodeId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    clearSearchData: (state) => {
      state.search.data = [];
    },
  },
  extraReducers: {
    [getStatus.pending]: (state) => {
      state.status.loading = true;
    },
    [getStatus.fulfilled]: (state, action) => {
      state.status.loading = false;
      state.status.data = action.payload;
    },
    [getStatus.rejected]: (state, action) => {
      state.status.loading = false;
      state.status.errors = action.payload?.errors;
    },
    [getFiles.pending]: (state) => {
      state.files.loading = true;
      state.files.data = [];
    },
    [getFiles.fulfilled]: (state, action) => {
      state.files.loading = false;
      state.files.data = action.payload.data_sources.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [getFiles.rejected]: (state, action) => {
      state.files.loading = false;
      state.files.errors = action.payload?.errors;
    },
    [searchFiles.pending]: (state) => {
      state.search.data = [];
      state.search.loading = true;
    },
    [searchFiles.fulfilled]: (state, action) => {
      state.search.loading = false;
      state.search.data = action.payload;
    },
    [searchFiles.rejected]: (state, action) => {
      state.search.loading = false;
      state.search.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors, clearSearchData } = dataSlice.actions;

export const fileSelector = (state) => state.data.file;
export const statusSelector = (state) => state.data.status;
export const filesSelector = (state) => state.data.files;
export const searchSelector = (state) => state.data.search;

export default dataSlice.reducer;
