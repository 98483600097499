import { Avatar } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import styles from "./Nav.module.css";
import { navSelector } from "./navSlice";

export function UserAvatar(props) {
  const { currentUser } = useSelector(authSelector);
  const { currentNode } = useSelector(navSelector);
  const [node, setNode] = useState();

  useEffect(() => {
    if (props.node) {
      setNode(props.node);
    } else {
      setNode(currentNode);
    }
  }, [currentNode, props.node]);

  return (
    <>
      {props.type === "node" ? (
        <>
          {node?.logo ? (
            <Avatar className={styles.avatar} src={node.logo} />
          ) : (
            <Avatar className={`${styles.avatar} ${styles.emptyNode}`}>
              {node?.name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </>
      ) : (
        <>
          {currentUser.avatar ? (
            <Avatar className={styles.avatar} src={currentUser.avatar} />
          ) : (
            <Avatar className={`${styles.avatar} ${styles.empty}`}>
              {currentUser?.f_name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </>
      )}
    </>
  );
}
