import { ArrowRightOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../components/buttons/BackButton";
import { DragUpload } from "../../../components/files/DragUpload";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import { analyzeData } from "../dataSlice";

const FILE_TYPE = 2;

export function AddAnnual() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { year } = useParams();
  const [disabled, setDisabled] = useState(true);
  const [uploadedIds, setUploadedIds] = useState([]);
  const { currentNode } = useSelector(navSelector);

  const analyze = () => {
    dispatch(
      analyzeData({
        nodeId: currentNode.id,
        data: { file_type: FILE_TYPE, ids: uploadedIds },
      })
    ).then((res) => {
      if (res.type === "data/analyze/fulfilled") {
        navigate("/data/annual/all");
      }
    });
  };

  return (
    <>
      <div className="pt-24 pb-56">
        <div className="px-40">
          <BackButton />
          <div className={`${styles.title} h1 mt-24`}>
            <FilePdfOutlined />
            &nbsp;&nbsp;Connect Annual Reports
          </div>
          <div className={`${styles.subTitle}`}>
            Connect your organization's annual reports to help train your AI
            assistant in saving you time on grant applications, reports, and
            more.
          </div>
          <div className={styles.receiptBox}>
            <DragUpload
              type={2}
              onDisabled={(val) => setDisabled(val)}
              restrict={[
                "application/pdf",
                "image/png",
                "image/jpeg",
                "image/jpg",
              ]}
              defaultYear={year}
              onChangeUploadedIds={(ids) => setUploadedIds(ids)}
            />
            <div className="mt-40">
              <Button
                size="large"
                type="primary"
                disabled={disabled}
                onClick={() => analyze()}
              >
                <Space size={16}>
                  Connect
                  <ArrowRightOutlined />
                </Space>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
