export const CATEGORIES = [
  {
    label: "Newsletters",
    value: 1,
  },
  {
    label: "Program Descriptions",
    value: 2,
  },
  {
    label: "Blog Posts",
    value: 3,
  },
  {
    label: "Pitch Decks",
    value: 4,
  },
  {
    label: "Grant Applications",
    value: 5,
  },
  {
    label: "Letter Templates",
    value: 6,
  },
  {
    label: "Impact Reports",
    value: 7,
  },
];
