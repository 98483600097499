import {
  DownOutlined,
  LogoutOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import { Loader } from "../../components/loader/Loader";
import { Logo } from "../../components/logo/Logo";
import { Sider } from "../../components/sider/Sider";
import tracker from "../../tracker";
import { Account } from "../account/Account";
import { Assistant } from "../assistant/Assistant";
import { Verify } from "../auth/Verify";
import { authSelector, logout, validate } from "../auth/authSlice";
import { Data } from "../data/Data";
import { AddAnnual } from "../data/annual/AddAnnual";
import { AllAnnual } from "../data/annual/AllAnnual";
import { AddIrs } from "../data/irs/AddIrs";
import { AllIrs } from "../data/irs/AllIrs";
import { AddOther } from "../data/other/AddOther";
import { AllOther } from "../data/other/AllOther";
import { AddReceipt } from "../data/receipts/AddReceipt";
import { AllReceipt } from "../data/receipts/AllReceipt";
import { AnalyzeReceipt } from "../data/receipts/AnalyzeReceipt";
import { ApproveReceipt } from "../data/receipts/ApproveReceipt";
import { NotFound } from "../errors/NotFound";
import { Home } from "../home/Home";
import { Profile } from "../profile/Profile";
import { Registry } from "../registry/Registry";
import styles from "./Nav.module.css";
import { changeCurrentNode, getAdminNodes, navSelector } from "./navSlice";

export const Permissions = {
  assistant: 1,
  data: 2,
  team: 3,
};

export const PermissionLabels = {
  1: "AI Assistant",
  2: "Data management",
  3: "Team management",
};

export function Nav() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { nodes, currentNode, loading } = useSelector(navSelector);
  const { currentUser } = useSelector(authSelector);
  const [optionsOpened, setOptionsOpened] = useState(false);
  const [switcherOpened, setSwitcherOpened] = useState(false);

  useEffect(() => {
    dispatch(getAdminNodes());
  }, [dispatch]);

  useEffect(() => {
    if (switcherOpened) {
      setOptionsOpened(false);
    }
  }, [switcherOpened]);

  useEffect(() => {
    if (optionsOpened) {
      setSwitcherOpened(false);
    }
  }, [optionsOpened]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
    }
  }, [location, navigate]);

  useEffect(() => {
    if (currentUser?.id) {
      //// TRACK
      tracker.page();
      ///
    }
  }, [currentUser, location]);

  useEffect(() => {
    dispatch(validate());
  }, [dispatch]);

  useEffect(() => {
    if (currentNode?.id && currentUser?.id) {
      //// TRACK
      tracker.identify(`${currentUser.id}`, {
        $name: `${currentUser.f_name} ${currentUser.l_name}`,
        "User ID": currentUser.id,
        "Node ID": currentNode.id,
        "Node Name": currentNode.name,
      });
    }

    if (!loading && !currentNode?.id && currentUser?.id) {
      if (
        location.pathname.includes("/profile") ||
        location.pathname === "/registry"
      ) {
        navigate("/home");
      }
    }
  }, [currentNode, currentUser]);

  const authorizeRedirect = (permission, children) => {
    if (currentNode?.id && !currentNode?.permissions?.includes(permission)) {
      navigate("/home");
    } else {
      return children;
    }
  };

  const getContent = () => {
    let p = location.pathname;

    if (p.includes("/data/annual/all")) {
      return <AllAnnual />;
    } else if (p.includes("/data/annual/add")) {
      return authorizeRedirect(Permissions.data, <AddAnnual />);
    }

    if (p.includes("/data/other/all")) {
      return <AllOther />;
    } else if (p.includes("/data/other/add")) {
      return authorizeRedirect(Permissions.data, <AddOther />);
    }

    if (p.includes("/profile")) {
      return <Profile />;
    }

    if (p.includes("/account")) {
      return <Account />;
    }

    switch (location.pathname) {
      case "/home":
        return <Home />;
      case "/data":
        return <Data />;
      case "/data/receipts/add":
        return <AddReceipt />;
      case "/data/receipts/analyze":
        return <AnalyzeReceipt />;
      case "/data/receipts/approve":
        return <ApproveReceipt />;
      case "/data/receipts/all":
        return <AllReceipt />;
      case "/data/irs/add":
        return authorizeRedirect(Permissions.data, <AddIrs />);
      case "/data/irs/all":
        return <AllIrs />;
      case "/assistant":
        return <Assistant />;
      case "/registry":
        return <Registry />;
      case "/registry/add":
        return <Registry />;
      default:
        return <NotFound></NotFound>;
    }
  };

  return (
    <>
      {!currentUser?.id || loading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : !currentUser?.verified ? (
        <Verify />
      ) : (
        <Layout
          onClick={() => {
            setOptionsOpened(false);
            setSwitcherOpened(false);
          }}
        >
          <Layout.Header className={styles.header}>
            <Row align="middle">
              <Col span={5}>
                <div>
                  <img
                    src={logo}
                    className={styles.logo}
                    alt="Humanitas"
                    onClick={() => navigate("/")}
                  />
                </div>
              </Col>
              <Col span={10}></Col>
              <Col span={9} className="ta-r">
                <Space size={16} className="pr-16">
                  <Dropdown
                    trigger={["click"]}
                    open={optionsOpened}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOptionsOpened((state) => !state);
                    }}
                    menu={{
                      theme: "dark",
                      className: styles.options,
                      onClick: () => {
                        setOptionsOpened((state) => !state);
                      },
                      items: [
                        {
                          type: "group",
                          label: (
                            <Row align="middle">
                              <Col span={12}>
                                <div className={styles.profileOption}>
                                  <div>
                                    <Logo size="lg" />
                                  </div>
                                  <div className={styles.profileName}>
                                    {currentUser.f_name}
                                  </div>
                                </div>
                              </Col>
                              <Col span={12} className="ta-r">
                                <Button
                                  size="large"
                                  onClick={() => {
                                    setOptionsOpened(false);
                                    navigate("/account/basic");
                                  }}
                                >
                                  Profile
                                </Button>
                              </Col>
                            </Row>
                          ),
                        },
                        {
                          type: "divider",
                        },
                        // {
                        //   key: "/feedback",
                        //   onClick: () =>
                        //     window.open(
                        //       "https://humanitas.canny.io/beta",
                        //       "_blank"
                        //     ),
                        //   label: (
                        //     <Space
                        //       align="center"
                        //       size={8}
                        //       className={styles.navOption}
                        //     >
                        //       <MessageOutlined />
                        //       <div>
                        //         <div className={styles.navOptionName}>
                        //           Provide feedback
                        //         </div>
                        //       </div>
                        //     </Space>
                        //   ),
                        // },
                        {
                          key: "/support",
                          onClick: () =>
                            window.open("mailto:hello@humanitas.ai", "_blank"),
                          label: (
                            <Space
                              align="center"
                              size={8}
                              className={styles.navOption}
                            >
                              <QuestionCircleOutlined />
                              <div>
                                <div className={styles.navOptionName}>
                                  Contact support
                                </div>
                              </div>
                            </Space>
                          ),
                        },
                        {
                          type: "divider",
                        },
                        {
                          key: "/logout",
                          onClick: () => {
                            dispatch(logout());
                            //// TRACK
                            tracker.reset();
                            ////
                          },
                          label: (
                            <Space
                              align="center"
                              size={8}
                              className={styles.navOption}
                            >
                              <LogoutOutlined />
                              <div>
                                <div className={styles.navOptionName}>
                                  Logout
                                </div>
                              </div>
                            </Space>
                          ),
                        },
                      ],
                    }}
                  >
                    <div className={`${styles.navDropdown}`}>
                      <div
                        className={`${styles.navUser} ${
                          optionsOpened ? styles.opened : ""
                        }`}
                      >
                        <SettingOutlined className={styles.navIcon} />
                      </div>
                    </div>
                  </Dropdown>
                  <Dropdown
                    trigger={["click"]}
                    open={switcherOpened}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSwitcherOpened((state) => !state);
                    }}
                    menu={{
                      theme: "dark",
                      className: styles.options,
                      onClick: () => {
                        setSwitcherOpened((state) => !state);
                      },
                      items: [
                        {
                          type: "group",
                          label: (
                            <div className={styles.navGroup}>
                              Accounts&nbsp;({nodes.length + 1})
                            </div>
                          ),
                        },
                        {
                          type: "divider",
                        },
                        ...nodes
                          // .filter((n) => n.id !== currentNode.id)
                          .map((n) => {
                            return {
                              key: `switch_${n.id}`,
                              onClick: () => dispatch(changeCurrentNode(n.id)),
                              label: (
                                <Space
                                  align="center"
                                  size={8}
                                  className={`${styles.navOption} ${
                                    currentNode.id === n.id
                                      ? styles.selected
                                      : ""
                                  }`}
                                >
                                  <Logo type="node" node={n} />
                                  <div>
                                    <div className={styles.navOptionName}>
                                      {n.name}
                                    </div>
                                    <div className={styles.navOptionDesc}>
                                      Organization Account
                                    </div>
                                  </div>
                                </Space>
                              ),
                            };
                          }),
                        {
                          key: "/switch_user",
                          onClick: () => dispatch(changeCurrentNode()),
                          label: (
                            <Space
                              align="end"
                              size={8}
                              className={`${styles.navOption} ${
                                currentNode?.id === undefined
                                  ? styles.selected
                                  : ""
                              }`}
                            >
                              <Logo />
                              <div>
                                <div className={styles.navOptionName}>
                                  Personal Account
                                </div>
                              </div>
                            </Space>
                          ),
                        },
                        {
                          type: "divider",
                        },
                        {
                          key: "/add_node",
                          onClick: () => navigate("/search?src=platform"),
                          label: (
                            <Space
                              align="center"
                              size={8}
                              className={styles.navOption}
                            >
                              <div className="ta-c" style={{ width: "24px" }}>
                                <PlusOutlined />
                              </div>
                              <div>
                                <div className={styles.navOptionName}>
                                  Add organization
                                </div>
                              </div>
                            </Space>
                          ),
                        },
                      ],
                    }}
                  >
                    <div className={`${styles.navDropdown} desc-color`}>
                      <div
                        className={`${styles.navUser} ${
                          switcherOpened ? styles.opened : ""
                        }`}
                      >
                        <Space align="center" size={16}>
                          <div>
                            <Space align="center" size={12}>
                              <div>
                                <div className={styles.navName}>
                                  {currentNode.id
                                    ? currentNode.name
                                    : "Personal Account"}
                                </div>
                              </div>
                            </Space>
                          </div>
                          <div className={styles.navIcon}>
                            <DownOutlined />
                          </div>
                        </Space>
                      </div>
                    </div>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Layout.Header>
          <Layout className={styles.notHeader}>
            <Layout.Sider
              breakpoint="lg"
              collapsedWidth="0"
              className={styles.sider}
            >
              <Sider path={location.pathname} node={currentNode} />
            </Layout.Sider>
            <Layout.Content className={styles.content}>
              <div className={styles.container}>{getContent()}</div>
            </Layout.Content>
          </Layout>
        </Layout>
      )}
    </>
  );
}
