import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import bg from "../../assets/img/bg/setup.svg";
import { Loader } from "../../components/loader/Loader";
import styles from "./Auth.module.css";
import { Header } from "./Header";
import { verify, verifySend } from "./authSlice";

export function Verify() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (code?.length > 0) {
      dispatch(verify({ code: code })).then((res) => {
        if (res.type === "user/verify/fulfilled") {
          setLoading(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setError(true);
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <div
        className={styles.fullScreen}
        style={{ backgroundImage: `url(${bg})` }}
      />
      <Header noAction={true} />

      <div className={styles.content}>
        {loading ? (
          <div className={styles.loadingContainer}>
            <Loader size={85} />
          </div>
        ) : success ? (
          <>
            <div className="h1">Account confirmed</div>
            <div className={`mt-24 copy ${styles.subTitle}`}>
              Your account is now active. Dive in and start seeing how Humanitas
              can save you time and effort.{" "}
            </div>
            <div className="mt-40">
              <Button
                type="primary"
                onClick={() => navigate("/home")}
                size="large"
              >
                <Space size={16}>
                  Go to platform
                  <ArrowRightOutlined />
                </Space>
              </Button>
            </div>
          </>
        ) : error ? (
          <>
            <div className="h1">Invalid Verification</div>
            <div className={`mt-24 copy ${styles.subTitle}`}>
              Your confirmation link is either invalid or has expired.
            </div>
            <div className="mt-24 sub-color">
              <span
                className="link underline pointer primary-color"
                onClick={() =>
                  dispatch(verifySend()).then((res) => {
                    if (res.type === "user/verifySend/fulfilled") {
                      message.success({
                        content: "Confirmation link sent.",
                        icon: <CheckCircleOutlined />,
                      });
                    } else {
                      message.error({
                        content: "Something went wrong. Try again later.",
                        icon: <CloseCircleOutlined />,
                      });
                    }
                  })
                }
              >
                Click here
              </span>{" "}
              to resend confirmation link.
            </div>
            <div className="mt-40 sub-color">
              Still facing issues?{" "}
              <span
                className="link underline pointer primary-color"
                onClick={() =>
                  window.open("mailto:hello@humanitas.ai", "_blank")
                }
              >
                Contact support
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="h1">Almost there</div>
            <div className={`mt-24 copy ${styles.subTitle}`}>
              We've sent a confirmation link to your email. Click it to activate
              your account and start simplifying your nonprofit tasks with our
              AI tool. Remember, you're in control and we're here to help!
            </div>
            <div className="mt-24 sub-color">
              Didn't get the email?{" "}
              <span
                className="link underline pointer primary-color"
                onClick={() =>
                  dispatch(verifySend()).then((res) => {
                    if (res.type === "user/verifySend/fulfilled") {
                      message.success({
                        content: "Confirmation link sent.",
                        icon: <CheckCircleOutlined />,
                      });
                    } else {
                      message.error({
                        content: "Something went wrong. Try again later.",
                        icon: <CloseCircleOutlined />,
                      });
                    }
                  })
                }
              >
                Click here
              </span>{" "}
              to resend.
            </div>
            <div className="mt-40 sub-color">
              Can't find your confirmation link?{" "}
              <span
                className="link underline pointer primary-color"
                onClick={() =>
                  window.open("mailto:hello@humanitas.ai", "_blank")
                }
              >
                Contact support
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
}
