import { configureStore } from "@reduxjs/toolkit";
import assistantReducer from "./features/assistant/assistantSlice";
import authReducer from "./features/auth/authSlice";
import dataReducer from "./features/data/dataSlice";
import homeReducer from "./features/home/homeSlice";
import widgetReducer from "./features/home/widgets/widgetSlice";
import navReducer from "./features/nav/navSlice";
import profileReducer from "./features/profile/profileSlice";
import registryReducer from "./features/registry/registrySlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    nav: navReducer,
    home: homeReducer,
    widget: widgetReducer,
    profile: profileReducer,
    registry: registryReducer,
    data: dataReducer,
    assistant: assistantReducer,
  },
});
