import { CloseCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Tabs, message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import tracker from "../../tracker";
import { authSelector } from "../auth/authSlice";
import styles from "./Account.module.css";
import { BasicInfo } from "./info/BasicInfo";
import { Password } from "./info/Password";
import { Orgs } from "./orgs/Orgs";

export function Account() {
  const navigate = useNavigate();
  const { errors } = useSelector(authSelector);
  const { focus } = useParams();

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  return (
    <div className="py-40">
      <div className={`${styles.title} h1`}>
        <UserOutlined />
        &nbsp;&nbsp;Your Profile
      </div>
      <Tabs
        activeKey={focus?.length > 0 ? focus : "basic"}
        type="card"
        size="large"
        onTabClick={(val) => {
          navigate(`/account/${val}`);
          //// TRACK
          tracker.track(`account-${val}-tab`);
          ////
        }}
        items={[
          {
            label: `Basic Info`,
            key: "basic",
            children: (
              <div className={styles.content}>
                <BasicInfo onUpdated={() => console.log("Updated")} />
              </div>
            ),
          },
          {
            label: `Change Password`,
            key: "password",
            children: (
              <div className={styles.content}>
                <Password onUpdated={() => console.log("Updated")} />
              </div>
            ),
          },
          {
            label: `Organizations`,
            key: "orgs",
            children: (
              <div className={styles.content}>
                <Orgs onUpdated={() => console.log("Updated")} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
