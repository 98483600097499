import { HomeOutlined } from "@ant-design/icons";
import { Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import channelImg from "../../assets/img/channel.png";
import tracker from "../../tracker";
import { authSelector } from "../auth/authSlice";
import styles from "./Home.module.css";
import { Welcome } from "./Welcome";
import { WidgetAssistant } from "./widgets/widgetAssistant/WidgetAssistant";
import { WidgetData } from "./widgets/widgetData/WidgetData";
import { WidgetOnboarding } from "./widgets/widgetOnboarding/WidgetOnboarding";

export function Home() {
  const { currentUser } = useSelector(authSelector);
  const [welcomeOpened, setWelcomeOpened] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  return (
    <>
      <div className="p-40">
        <div className={styles.container}>
          <div className="mb-40">
            <div className="h1">
              <HomeOutlined />
              &nbsp;&nbsp;Welcome, {currentUser.f_name}
            </div>
          </div>
          <div className="channel" onClick={() => setWelcomeOpened(true)}>
            <Space size={16}>
              <div className="channel-logo">
                <img src={channelImg} alt="channel" width={92} />
              </div>
              <div>
                <div className="channel-head">
                  Start your journey with Humanitas
                </div>
                <div className="channel-text">
                  Watch our video overview and see how we're transforming the
                  way nonprofits work. Dive in and explore!
                </div>
              </div>
            </Space>
          </div>
          <div className="mt-40">
            <WidgetOnboarding />
          </div>
          <div className="mt-40">
            <div>
              <WidgetData />
            </div>
            <div className="mt-16">
              <WidgetAssistant />
            </div>
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        open={welcomeOpened}
        footer={null}
        closable={false}
        width={840}
        style={{ top: 56 }}
        afterOpenChange={(open) => setLoadVideo(open)}
      >
        <Welcome
          onCancel={() => setWelcomeOpened(false)}
          showVideo={loadVideo}
        />
      </Modal>
    </>
  );
}
