import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  needs: {
    data: [],
    loading: false,
    errors: null,
  },
  current: {
    data: {},
    loading: false,
    errors: null,
  },
  search: {
    results: [],
    loading: false,
    errors: null,
    totalCount: null,
  },
  catalogue: {
    data: {},
    loading: false,
    errors: null,
  },
};

export const getAllNeeds = createAsyncThunk(
  "registry/getAllNeeds",
  async (data, thunk) => {
    try {
      let response = await API.needs.index(data.nodeId, data.approved);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getNeed = createAsyncThunk(
  "registry/getNeed",
  async (data, thunk) => {
    try {
      let response = await API.needs.show(data.nodeId, data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createNeed = createAsyncThunk(
  "registry/createNeed",
  async (data, thunk) => {
    try {
      let response = await API.needs.create(data.nodeId, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateNeed = createAsyncThunk(
  "registry/updateNeed",
  async (data, thunk) => {
    try {
      let response = await API.needs.update(data.nodeId, data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteNeed = createAsyncThunk(
  "registry/deleteNeed",
  async (data, thunk) => {
    try {
      let response = await API.needs.delete(data.nodeId, data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const searchCatalogue = createAsyncThunk(
  "searchCatalogue",
  async (data, thunk) => {
    try {
      let response = await API.discover.search(
        "catalogue",
        data.query,
        data.page,
        data.per
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getCatalogue = createAsyncThunk(
  "getCatalogue",
  async (data, thunk) => {
    try {
      let response = await API.catalogue.show(data.id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const approveAnalyzed = createAsyncThunk(
  "registry/approveAnalyzed",
  async (data, thunk) => {
    try {
      let response = await API.needs.approveAnalyzed(data.nodeId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteAnalyzed = createAsyncThunk(
  "registry/deleteAnalyzed",
  async (data, thunk) => {
    try {
      let response = await API.needs.deleteAnalyzed(data.nodeId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const registrySlice = createSlice({
  name: "registry",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: {
    [getAllNeeds.pending]: (state) => {
      state.needs.loading = true;
    },
    [getAllNeeds.fulfilled]: (state, action) => {
      state.needs.loading = false;
      state.needs.data = action.payload.needs.map((n) => {
        return { ...n, key: n.id };
      });
    },
    [getAllNeeds.rejected]: (state, action) => {
      state.needs.loading = false;
      state.needs.errors = action.payload?.errors;
    },
    [getNeed.pending]: (state) => {
      state.current.loading = true;
    },
    [getNeed.fulfilled]: (state, action) => {
      state.current.loading = false;
      state.current.data = action.payload;
    },
    [getNeed.rejected]: (state, action) => {
      state.current.loading = false;
      state.current.errors = action.payload?.errors;
    },
    [createNeed.pending]: (state) => {
      state.current.loading = true;
    },
    [createNeed.fulfilled]: (state, action) => {
      state.current.loading = false;
      state.current.data = action.payload;
    },
    [createNeed.rejected]: (state, action) => {
      state.current.loading = false;
      state.current.errors = action.payload?.errors;
    },
    [updateNeed.pending]: (state) => {
      state.current.loading = true;
    },
    [updateNeed.fulfilled]: (state, action) => {
      state.current.loading = false;
      state.current.data = action.payload;
    },
    [updateNeed.rejected]: (state, action) => {
      state.current.loading = false;
      state.current.errors = action.payload?.errors;
    },
    [deleteNeed.pending]: (state) => {
      state.current.loading = true;
    },
    [deleteNeed.fulfilled]: (state, action) => {
      state.current.loading = false;
      state.current.data = action.payload;
    },
    [deleteNeed.rejected]: (state, action) => {
      state.current.loading = false;
      state.current.errors = action.payload?.errors;
    },
    [searchCatalogue.pending]: (state) => {
      state.search.loading = true;
    },
    [searchCatalogue.fulfilled]: (state, action) => {
      state.search.loading = false;
      state.search.results = action.payload.results;
      state.search.totalCount = action.payload.total_count;
    },
    [searchCatalogue.rejected]: (state, action) => {
      state.search.loading = false;
      state.search.errors = action.payload?.errors;
    },
    [getCatalogue.pending]: (state) => {
      state.catalogue.loading = true;
    },
    [getCatalogue.fulfilled]: (state, action) => {
      state.catalogue.loading = false;
      state.catalogue.data = action.payload;
    },
    [getCatalogue.rejected]: (state, action) => {
      state.catalogue.loading = false;
      state.catalogue.errors = action.payload?.errors;
    },
  },
});

export const { clearErrors } = registrySlice.actions;

export const needsSelector = (state) => state.registry.needs;
export const catalogueSelector = (state) => state.registry.catalogue;
export const searchSelector = (state) => state.registry.search;
export const currentSelector = (state) => state.registry.current;

export default registrySlice.reducer;
