import { CopyOutlined, HomeOutlined, SmileOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./Sider.module.css";

const homeItems = [
  {
    name: "Home",
    icon: <HomeOutlined />,
    link: "/home",
    user: true,
  },
];

const learningItems = [
  {
    name: "Data Sources",
    icon: <CopyOutlined />,
    link: "/data",
    user: true,
  },
];

const fundItems = [
  // {
  //   name: "In-Kind Registry",
  //   icon: <ShoppingCartOutlined />,
  //   link: "/registry",
  //   user: false,
  // },
  {
    name: "AI Assistant",
    icon: <SmileOutlined />,
    link: "/assistant",
    user: true,
  },
];

export function Sider(props) {
  const navigate = useNavigate();

  return (
    <>
      {props.node?.id !== undefined && (
        <>
          <div>
            <div className={styles.title}>Your Organization</div>
            <div
              className={`${styles.subTitle} ${
                props.path.includes("/profile") && styles.selected
              }`}
              onClick={() => navigate("/profile/basic")}
            >
              <div>
                {props.node.logo ? (
                  <Avatar
                    src={props.node.logo}
                    className={styles.nodeIcon}
                    alt="Logo"
                  />
                ) : (
                  <Avatar
                    className={`${styles.nodeIcon} ${styles.empty}`}
                    alt="Logo"
                  >
                    {props.node?.name?.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </div>
              <div className={styles.nodeName}>{props.node.name}</div>
            </div>
          </div>
          <div className={styles.divider} />
        </>
      )}
      <div>
        {homeItems.map((i) => {
          if (!i.user && !props.node?.id) return null;
          return (
            <div
              key={i.name}
              className={`${styles.subTitle} ${
                props.path.includes(i.link) && styles.selected
              }`}
              onClick={() => navigate(i.link)}
            >
              <div className={styles.icon}>{i.icon}</div>
              <div>{i.name}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.divider} />
      <div>
        <div className={styles.title}>Learning</div>
        <div>
          {learningItems.map((i) => {
            if (!i.user && !props.node?.id) return null;
            return (
              <div
                key={i.name}
                // className={`${styles.subTitle} ${
                //   i.link === props.path && styles.selected
                // }`}
                className={`${styles.subTitle} ${
                  props.path.includes(i.link) && styles.selected
                }`}
                onClick={() => navigate(i.link)}
              >
                <div className={styles.icon}>{i.icon}</div>
                <div>{i.name}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.divider} />
      <div>
        <div className={styles.title}>Fundraising</div>
        <div>
          {fundItems.map((i) => {
            if (!i.user && !props.node?.id) return null;
            return (
              <div
                key={i.name}
                className={`${styles.subTitle} ${
                  i.link === props.path && styles.selected
                }`}
                onClick={() => navigate(i.link)}
              >
                <span className={styles.icon}>{i.icon}</span>
                <span>{i.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
