import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import loadingAnimation from "./loader.json";

export function Loader(props) {
  const [size, setSize] = useState("100");

  useEffect(() => {
    if (props.size) {
      setSize(props.size);
    }
  }, [props]);

  return (
    <Lottie style={{ height: `${size}px` }} animationData={loadingAnimation} />
  );
}
