import { CloseOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Loader } from "../../components/loader/Loader";

export function Welcome(props) {
  return (
    <div>
      <Row align="middle">
        <Col span={20}>
          <div className="accent accent-color">Meet your assistant</div>
        </Col>
        <Col span={4} className="ta-r">
          <CloseOutlined onClick={() => props.onCancel()} />
        </Col>
      </Row>
      <div className="mt-8">
        <div className="h2">Welcome to your AI Assistant</div>
      </div>
      <div className="mt-16 sub-color">
        Get ready to streamline your tasks with your new AI Assistant. Watch the
        video to see what you can do. For more tips and tricks, check out our{" "}
        <a
          href="https://www.youtube.com/@humanitas-ai"
          className="link"
          target="_blank"
          rel="noreferrer"
        >
          YouTube channel
        </a>
        !
      </div>
      <div className="mt-24" style={{ height: "427px" }}>
        {props.showVideo ? (
          <iframe
            width="760"
            height="427"
            src="https://www.youtube-nocookie.com/embed/EYG1UtgEfGo?si=3uiymV_Yyva3TdfH?controls=1&autoplay=1&modestbranding=1&rel=0"
            title="Humanitas AI Assistant Introduction"
            frameBorder="0"
            allow="fullscreen; autoplay; encrypted-media"
          ></iframe>
        ) : (
          <div className="center">
            <Loader size={65} />
          </div>
        )}
      </div>
    </div>
  );
}
