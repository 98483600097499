import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Radio, Row, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Confirm } from "../../../components/confirm/Confirm";
import { Logo } from "../../../components/logo/Logo";
import { authSelector } from "../../auth/authSlice";
import { Permissions } from "../../nav/Nav";
import styles from "../Profile.module.css";

export function EditTeam(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { currentUser } = useSelector(authSelector);
  const [role, setRole] = useState();
  const [permissions, setPermissions] = useState([]);
  const [removeOpened, setRemoveOpened] = useState(false);
  const [rejectOpened, setRejectOpened] = useState(false);

  useEffect(() => {
    if (props.member?.permissions) {
      setPermissions(props.member?.permissions);
      if (
        [Permissions.assistant, Permissions.data, Permissions.team].every((e) =>
          props.member.permissions.includes(e)
        )
      ) {
        setRole(1);
      } else if (
        [Permissions.assistant, Permissions.data].every((e) =>
          props.member.permissions.includes(e)
        )
      ) {
        setRole(2);
      } else {
        setRole(3);
      }
    }
  }, [props.member, form]);

  const onChangeRole = (e) => {
    setRole(e.target.value);
    if (e.target.value === 1) {
      setPermissions([
        Permissions.assistant,
        Permissions.data,
        Permissions.team,
      ]);
    } else if (e.target.value === 2) {
      setPermissions([Permissions.assistant, Permissions.data]);
    } else {
      setPermissions([Permissions.assistant]);
    }
  };

  return (
    <>
      <Row align="middle">
        <Col span={20} className="modalHead">
          <div className="accent accent-color">Team members</div>
        </Col>
        <Col span={4} className="ta-r">
          <CloseOutlined onClick={() => props.onCancel()} />
        </Col>
      </Row>
      <div className="mt-8">
        <div className="h2">
          {props.member?.request
            ? "Approve new member request"
            : props.member?.id
            ? "Edit team member privileges"
            : "Invite new team member"}
        </div>
      </div>
      {props.member?.id ? (
        <>
          <div className="mt-40">
            <div className={styles.box}>
              <Row align="middle">
                <Col span={props.member?.user?.id === currentUser.id ? 16 : 24}>
                  <Space size={16}>
                    <div>
                      <Logo
                        size="xl"
                        type="custom"
                        data={{
                          name: props.member?.user?.f_name,
                          src: props.member?.user?.avatar,
                        }}
                      />
                    </div>
                    <div>
                      <div className="bold-body">
                        <Space size={16}>
                          <div>
                            {props.member?.user?.f_name}&nbsp;
                            {props.member?.user?.l_name}
                          </div>
                          {props.member?.user?.id === currentUser.id && (
                            <div className="tag yellow">You</div>
                          )}
                        </Space>
                      </div>
                      <div className="mt-4">{props.member?.user?.email}</div>
                    </div>
                  </Space>
                </Col>
                {props.member?.user?.id === currentUser.id && (
                  <Col span={8} className="ta-r">
                    <Button
                      size="large"
                      onClick={() => navigate("/account/basic")}
                    >
                      <Space size={16}>
                        Edit my profile
                        <ArrowRightOutlined />
                      </Space>
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </div>
          <div className={`mt-40 ${styles.roleContainer}`}>
            <div className="bold-body">Select role:</div>
            <Radio.Group
              onChange={onChangeRole}
              value={role}
              className="mt-24"
              disabled={props.member?.user?.id === currentUser.id}
            >
              <Space direction="vertical" size={32}>
                <Radio value={1}>
                  <Space direction="vertical" size={4}>
                    <div className="bold-body">Admin</div>
                    <div>AI Assistant, Data management, Team management</div>
                  </Space>
                </Radio>
                <Radio value={2}>
                  <Space direction="vertical" size={4}>
                    <div className="bold-body">Editor</div>
                    <div>AI Assistant, Data management</div>
                  </Space>
                </Radio>
                <Radio value={3}>
                  <Space direction="vertical" size={4}>
                    <div className="bold-body">Viewer</div>
                    <div>AI Assistant</div>
                  </Space>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="mt-40">
            {props.member?.request ? (
              <Row>
                <Col span={12}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => props.onSubmit({ permissions: permissions })}
                  >
                    Approve team member
                  </Button>
                </Col>
                <Col span={12} className="ta-r">
                  <Button
                    size="large"
                    danger={true}
                    onClick={() => setRejectOpened(true)}
                  >
                    Reject team member
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={12}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => props.onSubmit({ permissions: permissions })}
                    disabled={props.member?.user?.id === currentUser.id}
                  >
                    Save changes
                  </Button>
                </Col>
                <Col span={12} className="ta-r">
                  {props.member?.user?.id === currentUser.id ? (
                    <Tooltip title="You can manage your organizations on your personal profile.">
                      <Button size="large" disabled>
                        Remove team member
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button size="large" onClick={() => setRemoveOpened(true)}>
                      Remove team member
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="mt-40 width-456">
            <Form
              layout="vertical"
              onFinish={(values) => {
                let permissions =
                  values.role === 1
                    ? [
                        Permissions.assistant,
                        Permissions.data,
                        Permissions.team,
                      ]
                    : values.role === 2
                    ? [Permissions.assistant, Permissions.data]
                    : [Permissions.assistant];
                let p = { ...values, permissions: permissions };
                delete p.role;
                props.onSubmit(p);
              }}
              requiredMark={false}
              initialValues={{ role: 1 }}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please input email" }]}
                className="mt-24"
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item name="role" label="Select role:" className="mt-40">
                <Radio.Group>
                  <Space direction="vertical" size={32} className="mt-24">
                    <Radio value={1}>
                      <Space direction="vertical" size={4}>
                        <div className="bold-body">Admin</div>
                        <div>
                          AI Assistant, Data management, Team management
                        </div>
                      </Space>
                    </Radio>
                    <Radio value={2}>
                      <Space direction="vertical" size={4}>
                        <div className="bold-body">Editor</div>
                        <div>AI Assistant, Data management</div>
                      </Space>
                    </Radio>
                    <Radio value={3}>
                      <Space direction="vertical" size={4}>
                        <div className="bold-body">Viewer</div>
                        <div>AI Assistant</div>
                      </Space>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Button
                size="large"
                type="primary"
                className="mt-40"
                htmlType="submit"
              >
                Send invite
              </Button>
            </Form>
          </div>
        </>
      )}
      <Confirm
        open={removeOpened}
        header="Team Members"
        title="Remove team member?"
        desc="They will have to be added again."
        btn="Remove"
        onConfirm={() => props.onDelete()}
        onCancel={() => setRemoveOpened(false)}
      />
      <Confirm
        open={rejectOpened}
        header="Team Members"
        title="Reject team member request?"
        desc="This cannot be undone."
        btn="Reject"
        onConfirm={() => props.onDelete()}
        onCancel={() => setRejectOpened(false)}
      />
    </>
  );
}
