import mixpanelPlugin from "@analytics/mixpanel";
import Analytics from "analytics";

const tracker = Analytics({
  app: "hayagriva",
  plugins: [
    mixpanelPlugin({
      token:
        process.env.NODE_ENV === "production"
          ? "e8086cf9fe465392f30f9fd7ad7c399d"
          : "8acd96101a155aaf93050946c4a98223",
      options: { debug: false, persistence: "localStorage" },
    }),
  ],
});

/* export the instance for usage in your app */
export default tracker;
