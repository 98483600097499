import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../../components/buttons/BackButton";
import { DragUpload } from "../../../components/files/DragUpload";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import { analyzeData } from "../dataSlice";
import { CATEGORIES } from "./categories";

const FILE_TYPE = 3;

export function AddOther() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [step, setStep] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [category, setCategory] = useState(null);
  const [uploadedIds, setUploadedIds] = useState([]);

  useEffect(() => {
    if (id) {
      setCategory(parseInt(id));
      setStep(2);
    }
  }, [id]);

  const analyze = () => {
    dispatch(
      analyzeData({
        nodeId: currentNode.id,
        data: { file_type: FILE_TYPE, ids: uploadedIds },
      })
    ).then((res) => {
      if (res.type === "data/analyze/fulfilled") {
        navigate("/data/other/all");
      }
    });
  };

  return (
    <>
      <div className="pt-24 pb-56">
        <div className="px-40">
          <BackButton />
          <div className={`${styles.title} h1 mt-24`}>
            <FolderOpenOutlined />
            &nbsp;&nbsp;Connect Other
          </div>
          <div className={`${styles.subTitle}`}>
            Bring any document into play. From impact reports to blog posts,
            each unique file refines your AI Assistant, tuning it to assist you
            better.
          </div>
          <div className={styles.receiptBox}>
            {step === 2 ? (
              <>
                <Row align="middle">
                  <Col span={20}>
                    <div className="h2">
                      Select up to 3 documents that represent{" "}
                      {CATEGORIES.filter((c) => c.value === category)[0].label}
                    </div>
                  </Col>
                  <Col span={4} className="ta-r">
                    <div className="sub-color">(Step 2 of 2)</div>
                  </Col>
                </Row>
                <div className={`mt-16 width-600 copy`}>
                  Make sure all facts are up to date and accurate. Outdated
                  information may result in the AI Assistant making inaccurate
                  statements.
                </div>
                <div className="mt-40">
                  <DragUpload
                    type={3}
                    sec_file_type={category}
                    onDisabled={(val) => setDisabled(val)}
                    restrict={[
                      "application/pdf",
                      "image/png",
                      "image/jpeg",
                      "image/jpg",
                    ]}
                    onChangeUploadedIds={(ids) => setUploadedIds(ids)}
                  />
                </div>
                <div className="mt-40">
                  <Space size={24}>
                    <Button
                      size="large"
                      onClick={() => setStep(1)}
                      disabled={!disabled}
                    >
                      <Space size={16}>
                        <ArrowLeftOutlined />
                        Back
                      </Space>
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      disabled={disabled}
                      onClick={() => analyze()}
                    >
                      <Space size={16}>
                        Connect
                        <ArrowRightOutlined />
                      </Space>
                    </Button>
                  </Space>
                </div>
              </>
            ) : (
              <>
                <Row align="middle">
                  <Col span={20}>
                    <div className="h2">
                      Which type of document would you like to connect?
                    </div>
                  </Col>
                  <Col span={4} className="ta-r">
                    <div className="sub-color">(Step 1 of 2)</div>
                  </Col>
                </Row>
                <div className="mt-24">
                  <Form layout="vertical" form={form}>
                    <Form.Item name="sec_file_type" label="Select category">
                      <Select
                        options={CATEGORIES}
                        placeholder="Select a Type of Data"
                        className={styles.categorySelect}
                        onChange={(val) => setCategory(val)}
                      />
                    </Form.Item>
                    <div className="mt-40">
                      <Button
                        size="large"
                        type="primary"
                        disabled={!category}
                        onClick={() => setStep(2)}
                      >
                        <Space size={16}>
                          Next
                          <ArrowRightOutlined />
                        </Space>
                      </Button>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
