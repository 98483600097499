import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Modal,
  Progress,
  Row,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Authorize } from "../../../components/authorize/Authorize";
import { BackButton } from "../../../components/buttons/BackButton";
import { Loader } from "../../../components/loader/Loader";
import { Permissions } from "../../nav/Nav";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import {
  analyzeData,
  deleteFile,
  filesSelector,
  getFile,
  getFiles,
  updateFile,
} from "../dataSlice";

const END_YEAR = 2019;
const FILE_TYPE = 2;
let AllTimers = {};

export function AllAnnual() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, errors } = useSelector(filesSelector);
  const [files, setFiles] = useState([]);
  const [unconnectedFiles, setUnconnectedFiles] = useState([]);
  const [years, setYears] = useState([]);
  const [options, setOptions] = useState([]);
  const { currentNode } = useSelector(navSelector);
  const [currentFile, setCurrentFile] = useState();
  const [confirmOpened, setConfirmOpened] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(getFiles({ nodeId: currentNode.id, fileType: FILE_TYPE }));
  }, [dispatch, currentNode]);

  useEffect(() => {
    let start = new Date().getFullYear();
    let y = [];
    let o = [];
    while (start >= END_YEAR) {
      y.push({ val: start, show: false });
      o.push({ key: start, label: start });
      start = start - 1;
    }
    setOptions(o);
    setYears(y);
  }, []);

  useEffect(() => {
    setFiles(data.filter((d) => d.status !== 1));
    setUnconnectedFiles(data.filter((d) => d.status === 1));

    data
      .filter((d) => d.status === 2)
      .forEach((f) => {
        console.log("Timer started for", f.id);
        AllTimers[f.id] = setInterval(() => {
          dispatch(getFile({ nodeId: currentNode.id, dataId: f.id })).then(
            (res) => {
              if (res.type === "data/show/fulfilled") {
                setFiles((state) => {
                  return state.map((s) => {
                    if (s.id === res.payload.id) {
                      s = res.payload;
                    }
                    return s;
                  });
                });
                if (res.payload.status === 3) {
                  console.log("Timer ended for", f.id);
                  clearInterval(AllTimers[f.id]);
                }
              }
            }
          );
        }, 5000);
      });
    return () => {
      console.log("All timers ended");
      clearAllTimers();
    };
  }, [data, currentNode.id, dispatch]);

  const clearAllTimers = () => {
    for (let t in AllTimers) {
      clearInterval(AllTimers[t]);
    }
    AllTimers = {};
  };

  const destroy = () => {
    dispatch(deleteFile({ nodeId: currentNode.id, id: currentFile.id })).then(
      (res) => {
        if (res.type === "data/delete/fulfilled") {
          setUnconnectedFiles((state) => {
            return state.filter((s) => s.id !== currentFile.id);
          });
          setFiles((state) => {
            return state.filter((s) => s.id !== currentFile.id);
          });
          setConfirmOpened(false);
          message.success({
            content: "File Deleted Successfully",
            icon: <CheckCircleOutlined />,
          });
        }
      }
    );
  };

  const showStatus = (year) => {
    if (loading) {
      return <></>;
    }
    let s = files.filter((d) => d.year === year);
    if (s && s.length > 0) {
      if (s.filter((f) => f.status === 2).length > 0) {
        return (
          <Space size={12}>
            <div>Analyzing...</div>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: "18px" }} spin />}
            />
          </Space>
        );
      } else if (s.filter((f) => f.status === 3).length > 0) {
        return (
          <Space size={12}>
            <div>{s.filter((f) => f.status === 3).length}&nbsp;Connected</div>
            <CheckCircleOutlined
              className="success-color"
              style={{ fontSize: "18px" }}
            />
          </Space>
        );
      }
    }
    return (
      <>
        Not Connected
        {/* &nbsp;&nbsp;<ExclamationCircleOutlined style={{ color: "#E85036" }} /> */}
      </>
    );
  };

  const showProgress = (year) => {
    let s = files.filter((d) => d.year === year);
    let analyzing = s.filter((f) => f.status === 2);
    if (analyzing.length > 0) {
      let average =
        analyzing.reduce((total, item) => total + item.progress, 0) /
        analyzing.length;
      return (
        <div className={styles.progressContainer}>
          <Progress percent={average} showInfo={false} strokeColor="#1E5CCC" />
        </div>
      );
    }
    return <div className={styles.progressContainer}></div>;
  };

  const showMore = (year) => {
    setYears(
      years.map((y) => {
        if (y.val === year) {
          y.show = !y.show;
          return y;
        }
        return y;
      })
    );
  };

  const showList = (year) => {
    if (loading) {
      return <Spin indicator={<Loader size={30} />} />;
    }
    let s = files.filter((d) => d.year === year);
    let y = years.filter((d) => d.val === year);
    if (s && s.length > 0) {
      return (
        <div className="pr-16">
          {y[0].show ? (
            <Space
              size={12}
              align="bottom"
              className="pointer"
              onClick={() => showMore(y[0].val)}
            >
              Hide
              <UpOutlined />
            </Space>
          ) : (
            <Space
              size={12}
              align="bottom"
              className="pointer"
              onClick={() => showMore(y[0].val)}
            >
              Show
              <DownOutlined />
            </Space>
          )}
        </div>
      );
    }
    return (
      <>
        <Authorize permission={Permissions.data}>
          <Button
            size="large"
            onClick={() => navigate(`/data/annual/add/${year}`)}
            disabled={
              currentNode?.id &&
              !currentNode?.permissions?.includes(Permissions.data)
            }
          >
            Connect&nbsp;
            <PlusOutlined />
          </Button>
        </Authorize>
      </>
    );
  };

  const update = (id, y) => {
    dispatch(
      updateFile({
        nodeId: currentNode.id,
        dataId: id,
        data: { year: y },
      })
    ).then((res) => {
      if (res.type === "data/update/fulfilled") {
        setUnconnectedFiles((state) => {
          return state.map((s) => {
            if (s.id === id) {
              return { ...s, year: res.payload.year };
            }
            return s;
          });
        });
      } else if (res.type === "data/update/rejected") {
        message.error({
          content: res.payload.errors[0],
          icon: <CloseCircleOutlined />,
        });
      }
    });
  };

  const analyze = () => {
    dispatch(
      analyzeData({
        nodeId: currentNode.id,
        data: {
          file_type: FILE_TYPE,
          ids: unconnectedFiles.map((f) => f.id),
        },
      })
    ).then((res) => {
      if (res.type === "data/analyze/fulfilled") {
        dispatch(getFiles({ nodeId: currentNode.id, fileType: FILE_TYPE }));
      }
    });
  };

  return (
    <>
      <div className="py-24 mb-40">
        <div className="px-40">
          <BackButton />
          <div className={`${styles.title} mt-24`}>
            <Space size={56}>
              <Space size={16}>
                <div className="h1">
                  <FilePdfOutlined />
                </div>
                <div className="h1">Annual Reports</div>
              </Space>
              <Authorize permission={Permissions.data}>
                <Button
                  size="large"
                  onClick={() => navigate("/data/annual/add")}
                  disabled={
                    currentNode?.id &&
                    !currentNode?.permissions?.includes(Permissions.data)
                  }
                >
                  <Space size={16}>
                    Connect
                    <PlusOutlined />
                  </Space>
                </Button>
              </Authorize>
            </Space>
          </div>
          <div className={`${styles.subTitle}`}>
            Connect these annual reports to help train your AI assistant in
            saving you time on grant applications, reports, and more.
          </div>
          {unconnectedFiles.length > 0 && (
            <div className={styles.errorContainer}>
              <div className="bold-body">
                <Space size={8} className={styles.errorTitle}>
                  <ExclamationCircleOutlined />
                  Connection incomplete
                </Space>
              </div>
              <div className="mt-16">
                Finish connecting the following files to use them in your AI
                Assistant.
              </div>
              <div>
                {unconnectedFiles.map((f) => (
                  <div key={f.id} className="mt-24">
                    <div>
                      <Row align="middle" gutter={8}>
                        <Col span={4}>
                          <div className={styles.errorFileLg}>
                            <Dropdown
                              menu={{
                                items: options,
                                onClick: (val) => update(f.id, val.key),
                              }}
                              trigger={["click"]}
                              className="pointer"
                            >
                              <div className={styles.year}>
                                <Row>
                                  <Col span={20}>
                                    {f.year ? f.year : "Year"}
                                  </Col>
                                  <Col span={4} className="ta-r">
                                    <DownOutlined />
                                  </Col>
                                </Row>
                              </div>
                            </Dropdown>
                          </div>
                        </Col>
                        <Col span={20}>
                          <div className={styles.errorFile}>
                            <Row align="middle">
                              <Col span={16}>
                                <Space size={12}>
                                  <FileOutlined />
                                  {f.name}
                                </Space>
                              </Col>
                              <Col span={6}>
                                <div>
                                  <a
                                    href={f.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View/Download&nbsp;&nbsp;
                                    <DownloadOutlined />
                                  </a>
                                </div>
                              </Col>
                              <Col span={2} className="ta-r">
                                <Authorize permission={Permissions.data}>
                                  <Button
                                    type="text"
                                    size="large"
                                    onClick={() => {
                                      setCurrentFile(f);
                                      setConfirmOpened(true);
                                    }}
                                    disabled={
                                      currentNode?.id &&
                                      !currentNode?.permissions?.includes(
                                        Permissions.data
                                      )
                                    }
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Authorize>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-24">
                <Authorize permission={Permissions.data}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => analyze()}
                    disabled={
                      (currentNode?.id &&
                        !currentNode?.permissions?.includes(
                          Permissions.data
                        )) ||
                      unconnectedFiles.filter((n) => n.year == null).length > 0
                    }
                  >
                    <Space size={16}>
                      Connect
                      <ArrowRightOutlined />
                    </Space>
                  </Button>
                </Authorize>
              </div>
            </div>
          )}
          <div>
            {years.map((y) => (
              <div className={styles.listBox} key={y.val}>
                <div>
                  <Row className={styles.year}>
                    <Col span={17} className={styles.listName}>
                      <Space size={56}>
                        <div> {y.val}</div>
                        <div className={styles.listStatus}>
                          {showStatus(y.val)}
                        </div>
                      </Space>
                    </Col>
                    <Col span={7} className={styles.show}>
                      {showList(y.val)}
                    </Col>
                  </Row>
                  <div>{showProgress(y.val)}</div>
                </div>
                {y.show && (
                  <div>
                    <div
                      className={styles.details}
                      style={{ transition: "0.2s", opacity: y.show ? 1 : 0 }}
                    >
                      {files
                        .filter((d) => d.year === y.val)
                        .map((m, i) => (
                          <div className={styles.fileBox} key={m.id}>
                            <div className={styles.sourceId}>
                              Source&nbsp;#{i + 1} -{" "}
                              {m.status === 2 ? (
                                <Space size={8}>
                                  <div>Analyzing...</div>
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{ fontSize: "14px" }}
                                        spin
                                      />
                                    }
                                  />
                                </Space>
                              ) : m.status === 3 ? (
                                <Space size={8}>
                                  <div>Connected</div>
                                  <CheckCircleOutlined
                                    className="success-color"
                                    style={{
                                      fontSize: "14px",
                                    }}
                                  />
                                </Space>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className={styles.fileContainer}>
                              <div className={styles.file}>
                                <Row align="middle">
                                  <Col span={17}>
                                    <Space size={12}>
                                      <div className={styles.icon}>
                                        <FileOutlined />
                                      </div>
                                      <div className={styles.name}>
                                        {m.name}
                                      </div>
                                    </Space>
                                  </Col>
                                  <Col span={6} className={styles.download}>
                                    <div>
                                      <a
                                        href={m.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        View/Download&nbsp;&nbsp;
                                        <DownloadOutlined />
                                      </a>
                                    </div>
                                  </Col>
                                  <Col span={1} className={styles.del}>
                                    {m.status !== 2 && (
                                      <Authorize permission={Permissions.data}>
                                        <Button
                                          type="text"
                                          size="large"
                                          onClick={() => {
                                            setCurrentFile(m);
                                            setConfirmOpened(true);
                                          }}
                                          disabled={
                                            currentNode?.id &&
                                            !currentNode?.permissions?.includes(
                                              Permissions.data
                                            )
                                          }
                                        >
                                          <DeleteOutlined />
                                        </Button>
                                      </Authorize>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                              {m.status === 2 ? (
                                <div className="mt-8">
                                  <Progress
                                    percent={m.progress}
                                    showInfo={false}
                                    strokeColor="#1E5CCC"
                                  />
                                </div>
                              ) : m.status === 3 ? (
                                <div className="mt-16">
                                  <div className={styles.summaryTitle}>
                                    <Space size={24}>
                                      Basic Summary
                                      <Tooltip title="For a high level summary, try asking your AI assistant in-depth questions about this data source.">
                                        <QuestionCircleOutlined />
                                      </Tooltip>
                                    </Space>
                                  </div>
                                  <div className={styles.summaryBox}>
                                    {m?.summary?.text}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ))}
                      <div className="mt-40">
                        <Space size={24}>
                          <div>Connect additional files?</div>
                          <Authorize permission={Permissions.data}>
                            <Button
                              size="large"
                              onClick={() =>
                                navigate(`/data/annual/add/${y.val}`)
                              }
                              disabled={
                                currentNode?.id &&
                                !currentNode?.permissions?.includes(
                                  Permissions.data
                                )
                              }
                            >
                              <Space size={16}>
                                Connect
                                <PlusOutlined />
                              </Space>
                            </Button>
                          </Authorize>
                        </Space>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        open={confirmOpened}
        footer={null}
        closable={false}
        width={424}
      >
        <div className="modalContainer">
          <Row align="middle">
            <Col span={20} className="modalHead">
              Data Sources
            </Col>
            <Col span={4} className="ta-r modalClose">
              <CloseOutlined onClick={() => setConfirmOpened(false)} />
            </Col>
          </Row>
          <div className="mt-8">
            <div className="h2">Delete data source?</div>
          </div>
          <div className="mt-16 modalBody">
            Your AI Assistant will no longer be able to access data from this
            file.
          </div>
          <div className="mt-24">
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  danger
                  type="primary"
                  size="large"
                  className="w-100"
                  onClick={() => destroy()}
                >
                  <Space size={16}>
                    <div>Delete file</div>
                    <div>
                      <DeleteOutlined />
                    </div>
                  </Space>
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  className="w-100"
                  onClick={() => {
                    setCurrentFile({});
                    setConfirmOpened(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}
