import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  DownOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Progress, Row, Space, Upload, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createFile,
  deleteFile,
  fileSelector,
  getUploadURL,
  updateFile,
} from "../../features/data/dataSlice";
import { navSelector } from "../../features/nav/navSlice";
import styles from "./Upload.module.css";

const { Dragger } = Upload;
const END_YEAR = 2019;
const TYPE_RECEIPT = 1;
const TYPE_ANNUAL = 2;
const TYPE_OTHER = 3;
const MAX_FILE_COUNT = 3;
const MAX_SIZE = 20000000;

export function DragUpload(props) {
  const [files, setFiles] = useState([]);
  const [years, setYears] = useState([]);
  const { currentNode } = useSelector(navSelector);
  const { errors } = useSelector(fileSelector);
  // const [numFiles, setNumFiles] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    let start = new Date().getFullYear();
    let years = [];
    while (start >= END_YEAR) {
      years.push({ key: start, label: start });
      start = start - 1;
    }
    setYears(years);
  }, []);

  useEffect(() => {
    if (files.filter((n) => n.percent === 100).length > 0) {
      if (props.type === TYPE_RECEIPT || props.type === TYPE_OTHER) {
        props.onDisabled(false);
      } else {
        if (files.filter((n) => n.year == null).length > 0) {
          props.onDisabled(true);
        } else {
          props.onDisabled(false);
        }
      }
    } else {
      props.onDisabled(true);
    }
  }, [files]);

  useEffect(() => {
    props.onChangeUploadedIds(
      files.filter((f) => f.percent === 100).map((f) => f.fileId)
    );
  }, [files]);

  const create = (f, id) => {
    dispatch(
      createFile({
        nodeId: currentNode.id,
        data: {
          name: f.file.name,
          year: props.defaultYear ? props.defaultYear : null,
          file_type: props.type,
          sec_file_type: props.sec_file_type ? props.sec_file_type : null,
          file_data: {
            id: id,
            storage: "cache",
            metadata: {
              filename: f.file.name,
              size: f.file.size,
              mime_type: f.file.type,
            },
          },
        },
      })
    ).then((res) => {
      if (res.type === "data/create/fulfilled") {
        let newFileList = [...files];
        newFileList.forEach((n) => {
          if (n.uid === f.file.uid) {
            n.fileId = res.payload.id;
            n.year = res.payload.year;
            n.percent = 100;
          }
          return n;
        });
        setFiles(newFileList);
      } else if (res.type === "data/create/rejected") {
        message.error({
          content: res.payload.errors[0],
          icon: <CloseCircleOutlined />,
        });
        setFiles((state) =>
          state.filter((n) => {
            return n.uid !== f.file.uid;
          })
        );
      }
    });
  };

  const update = (id, y) => {
    dispatch(
      updateFile({
        nodeId: currentNode.id,
        dataId: id,
        data: { year: y },
      })
    ).then((res) => {
      if (res.type === "data/update/fulfilled") {
        let newFileList = [...files];
        newFileList.forEach((n) => {
          if (n.fileId === id) {
            n.year = res.payload.year;
          }
          return n;
        });
        setFiles(newFileList);
      } else if (res.type === "data/update/rejected") {
        message.error({
          content: res.payload.errors[0],
          icon: <CloseCircleOutlined />,
        });
      }
    });
  };

  const uploadProps = {
    name: "file",
    accept:
      props.restrict && props.restrict.length > 0
        ? props.restrict.join(", ")
        : "*",
    multiple: true,
    showUploadList: false,
    maxCount: MAX_FILE_COUNT,
    fileList: files,
    beforeUpload: (f) => {
      if (f.size > MAX_SIZE) {
        message.error({
          content: "File size cannot exceed 20MB",
          icon: <CloseCircleOutlined />,
        });
        return Upload.LIST_IGNORE;
      }
      if (files.length === MAX_FILE_COUNT) {
        message.error({
          content: "Max 3 files allowed",
          icon: <CloseCircleOutlined />,
        });
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange: (info) => {
      if (info.file.size <= MAX_SIZE) {
        setFiles(info.fileList);
      }
    },
    customRequest: (f) => {
      dispatch(getUploadURL({ type: f.file.type })).then((res) => {
        axios
          .request({
            method: "PUT",
            url: res.payload.url,
            data: f.file,
            headers: res.payload.headers,
            noAuth: true,
            onUploadProgress: (p) => {
              let newFileList = [...files];
              newFileList.forEach((n) => {
                if (n.uid === f.file.uid) {
                  n.id = res.payload.id;
                  n.percent =
                    p.progress > 0.95 ? 95 : Math.round(p.progress * 100);
                }
                return n;
              });
              setFiles(newFileList);
            },
          })
          .then(() => {
            // Skip files that are above max file limit
            if (files.filter((a) => a.uid === f.file.uid).length > 0) {
              create(f, res.payload.id);
            }
          });
      });
    },
  };

  const destroy = (id) => {
    dispatch(deleteFile({ nodeId: currentNode.id, id: id })).then((res) => {
      if (res.type === "data/delete/fulfilled") {
        setFiles((state) =>
          state.filter((n) => {
            return n.fileId !== id;
          })
        );
      } else {
        message.error({
          content: "Error while deleting file, try again later",
          icon: <CloseCircleOutlined />,
        });
      }
    });
  };

  const showFile = (f) => {
    return (
      <div className={styles.fileBox} key={f.uid}>
        <Row>
          <Col span={23}>
            <Space size={12}>
              <div className={styles.icon}>
                <FileOutlined />
              </div>
              <div className={styles.name}>
                {f.name}&nbsp;&nbsp;&nbsp;&nbsp;
                {f.percent === 100 && (
                  <CheckCircleTwoTone twoToneColor="#9BE91B" />
                )}
              </div>
            </Space>
          </Col>
          <Col span={1} className="ta-r">
            {f.percent === 100 && (
              <DeleteOutlined
                className={styles.delete}
                onClick={() => destroy(f.fileId)}
              />
            )}
          </Col>
        </Row>

        <div
          style={{
            transition: "0.3s",
            opacity: f.percent !== 100 ? 1 : 0,
            height: f.percent !== 100 ? "inherit" : "0px",
            marginTop: f.percent !== 100 ? "12px" : "0px",
          }}
        >
          <Row align="middle">
            <Col span={23}>
              <Progress
                percent={f.percent}
                showInfo={false}
                strokeColor={f.percent === 100 ? "#7EAF2C" : "#1E5CCC"}
              />
            </Col>
            <Col span={1}>
              <div className={styles.percent}>{f.percent}&nbsp;%</div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <>
      <Dragger {...uploadProps}>
        <div className={styles.icon}>
          <CloudUploadOutlined />
        </div>
        <div className="subtitle">
          Drag and drop your files here or click here to browse
        </div>
        <div className="copy mt-16">
          Max 3 files
          {props.restrict && props.restrict.length > 0 && (
            <>
              ,{" "}
              {`Only ${props.restrict
                .map((r) => r.split("/")[1])
                .join(", ")} files accepted`}
            </>
          )}
        </div>
      </Dragger>
      <div>
        {files.map((f) => {
          return props.type === TYPE_RECEIPT || props.type === TYPE_OTHER ? (
            showFile(f)
          ) : f.percent !== 100 ? (
            showFile(f)
          ) : (
            <Row gutter={8} key={f.uid}>
              <Col span={4}>
                <Dropdown
                  menu={{
                    items: years,
                    onClick: (val) => update(f.fileId, val.key),
                  }}
                  trigger={["click"]}
                >
                  <div className={styles.year}>
                    <Row>
                      <Col span={20}>{f.year ? f.year : "Year"}</Col>
                      <Col span={4} className="ta-r">
                        <DownOutlined />
                      </Col>
                    </Row>
                  </div>
                </Dropdown>
              </Col>
              <Col span={20}>{showFile(f)}</Col>
            </Row>
          );
        })}
      </div>
    </>
  );
}
