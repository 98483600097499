import {
  BuildOutlined,
  CloseOutlined,
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, Carousel, Col, Row, Space } from "antd";
import { useRef, useState } from "react";
import channelImg from "../../assets/img/channel.png";
import tracker from "../../tracker";
import styles from "./Assistant.module.css";

const HelpPrompts = {
  write: [
    "Create a week of LinkedIn posts based on our work.",
    "Write an email to spur supporters into monthly donations.",
    "Can you help draft a persuasive blog post on an issue relevant to us?",
    "For a grant application, what is our organization’s mission?",
    "What narratives can better illustrate our work's significance?",
    "Help write a script for a video highlighting our annual accomplishments.",
    "Craft an email to colleagues advocating an AI-driven fundraising strategy.",
    "Write a succinct summary of our mission and latest achievements.",
  ],
  strategize: [
    "What are some novel fundraising strategies we could use?",
    "Suggest 5 new program ideas",
    "How can we communicate our mission effectively and reach more people?",
    "Can you pinpoint crucial messages for our forthcoming campaign?",
    "What methods can invigorate grassroots support and community involvement?",
    "How can we utilize social media for our cause?",
    "What ways can we gauge the success of our communications?",
    "How can we best collect and use testimonials?",
    "Generate some fundraising event ideas that are relevant to our cause",
  ],
  else: [
    "Show me a current staff list.",
    "Give me an overview of our budget.",
    "How much did we increase donations in 2022 compared to 2021?",
    "Re-write our annual report quotes, Shakespeare style.",
    "Critique my viewpoint from technical, legal, and ethical aspects.",
    "Summarize recent nonprofit research and provide reading sources.",
    "Role-play as a top Fundraising consultant.",
    "Provide SEO keywords for our website.",
    "Explain AI benefits for nonprofits with examples.",
  ],
};

export function Help(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [seeMore, setSeeMore] = useState(false);
  const carouselRef = useRef(null);

  const choosePrompt = (prompt) => {
    props.onPromptChoose(prompt);
    //// TRACK
    tracker.track("assistant-helpPrompt-click", { prompt: prompt });
    ////
  };

  const getHelpPrompts = () => {
    if (currentSlide === 1) {
      return HelpPrompts.strategize
        .slice(0, seeMore ? HelpPrompts.strategize.length : 3)
        .map((h, i) => (
          <Button key={i} size="large" onClick={() => choosePrompt(h)}>
            {h}
          </Button>
        ));
    } else if (currentSlide === 2) {
      return HelpPrompts.else
        .slice(0, seeMore ? HelpPrompts.else.length : 3)
        .map((h, i) => (
          <Button key={i} size="large" onClick={() => choosePrompt(h)}>
            {h}
          </Button>
        ));
    } else {
      return HelpPrompts.write
        .slice(0, seeMore ? HelpPrompts.write.length : 3)
        .map((h, i) => (
          <Button key={i} size="large" onClick={() => choosePrompt(h)}>
            {h}
          </Button>
        ));
    }
  };

  return (
    <div>
      <Row align="middle">
        <Col span={20} className="modalHead">
          <div className="accent accent-color">Ideas</div>
        </Col>
        <Col span={4} className="ta-r">
          <CloseOutlined onClick={() => props.onCancel()} />
        </Col>
      </Row>
      <div className="mt-8">
        <div className="h2">What can my assistant do?</div>
      </div>
      <div className="mt-16 modalBody">
        There's an unlimited number of applications for this software. Here are
        some ideas to start with:
      </div>

      <a
        href="https://www.youtube.com/watch?v=qg3b-ZFZgEk"
        target="_blank"
        rel="noreferrer"
        className={styles.channelHover}
      >
        <div className="channel mt-24">
          <Space size={16}>
            <div className="channel-logo">
              <img src={channelImg} alt="channel" width={92} />
            </div>
            <div>
              <div className="channel-head">See the assistant in action </div>
              <div className="channel-text">
                Explore our YouTube channel to see examples of what you can do
                with the AI Assistant.
              </div>
            </div>
          </Space>
        </div>
      </a>

      <div className={`${styles.helpPrompts} mt-24`}>
        <Carousel
          autoplay={false}
          dots={false}
          beforeChange={(_, next) => {
            setCurrentSlide(next);
            setSeeMore(false);
          }}
          ref={carouselRef}
        >
          <div>
            <div className={styles.chooser}>
              <Row>
                <Col span={2}>
                  <LeftOutlined
                    className="pointer"
                    onClick={() => carouselRef.current.prev()}
                  />
                </Col>
                <Col span={20} className={`ta-c ${styles.chooserTitle}`}>
                  <Space size={10}>
                    <EditOutlined />
                    <div>Write</div>
                  </Space>
                </Col>
                <Col span={2} className="ta-r">
                  <RightOutlined
                    className="pointer"
                    onClick={() => carouselRef.current.next()}
                  />
                </Col>
              </Row>
            </div>
            <div className={styles.chooserContent}>
              <div>
                <Space size={16} wrap>
                  {getHelpPrompts()}
                </Space>
                <div className="mt-24">
                  <span
                    className="link underline pointer dark-color"
                    onClick={() => setSeeMore((state) => !state)}
                  >
                    {seeMore ? "Hide ideas" : "See more ideas"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.chooser}>
              <Row>
                <Col span={2}>
                  <LeftOutlined
                    className="pointer"
                    onClick={() => carouselRef.current.prev()}
                  />
                </Col>
                <Col span={20} className={`ta-c ${styles.chooserTitle}`}>
                  <Space size={10}>
                    <BuildOutlined />
                    <div>Strategize</div>
                  </Space>
                </Col>
                <Col span={2} className="ta-r">
                  <RightOutlined
                    className="link underline pointer dark-color"
                    onClick={() => carouselRef.current.next()}
                  />
                </Col>
              </Row>
            </div>
            <div className={styles.chooserContent}>
              <div>
                <Space size={16} wrap>
                  {getHelpPrompts()}
                </Space>
                <div className="mt-24">
                  <span
                    className="link underline pointer dark-color"
                    onClick={() => setSeeMore((state) => !state)}
                  >
                    {seeMore ? "Hide ideas" : "See more ideas"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.chooser}>
              <Row>
                <Col span={2}>
                  <LeftOutlined
                    className="pointer"
                    onClick={() => carouselRef.current.prev()}
                  />
                </Col>
                <Col span={20} className={`ta-c ${styles.chooserTitle}`}>
                  <Space size={10}>
                    <SmileOutlined />
                    <div>Everything Else</div>
                  </Space>
                </Col>
                <Col span={2} className="ta-r">
                  <RightOutlined
                    className="pointer"
                    onClick={() => carouselRef.current.next()}
                  />
                </Col>
              </Row>
            </div>
            <div className={styles.chooserContent}>
              <div>
                <Space size={16} wrap>
                  {getHelpPrompts()}
                </Space>
                <div className="mt-24">
                  <span
                    className="link underline pointer dark-color"
                    onClick={() => setSeeMore((state) => !state)}
                  >
                    {seeMore ? "Hide ideas" : "See more ideas"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
