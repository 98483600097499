import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Space, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/buttons/BackButton";
import { Loader } from "../../../components/loader/Loader";
import tracker from "../../../tracker";
import { navSelector } from "../../nav/navSlice";
import styles from "../Data.module.css";
import {
  analyzeData,
  clearSearchData,
  createFile,
  deleteFile,
  searchFiles,
  searchSelector,
} from "../dataSlice";

const FILE_TYPE = 4;

export function AddIrs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentNode } = useSelector(navSelector);
  const { data, loading, errors } = useSelector(searchSelector);
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [checkedFiles, setCheckedFiles] = useState(null);
  const [prevCheckedFiles, setPrevCheckedFiles] = useState([]);

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
    }
  }, [errors]);

  useEffect(() => {
    dispatch(clearSearchData());
  }, []);

  useEffect(() => {
    if (step === 2) {
      dispatch(searchFiles({ nodeId: currentNode.id }));
    }
  }, [step, dispatch, currentNode]);

  useEffect(() => {
    if (step === 2) {
      let unconnectedFiles = data
        .filter((d) => d.data_source_id === null)
        .map((d) => {
          return { ...d, uploading: true };
        });
      setFiles(unconnectedFiles);
      if (unconnectedFiles.length > 0) {
        setCheckedFiles(unconnectedFiles.map((f) => f.object_id));
      }
    }
  }, [data]);

  useEffect(() => {
    if (checkedFiles) {
      checkedFiles
        .filter((x) => !prevCheckedFiles.includes(x))
        .forEach((f) => {
          let file = files.find((x) => x.object_id === f);
          // Added file
          setFiles((state) =>
            state.map((s) => {
              if (s.object_id === f) {
                return {
                  ...s,
                  uploading: true,
                };
              }
              return s;
            })
          );
          dispatch(
            createFile({
              nodeId: currentNode.id,
              data: {
                name: `Form 990 - ${file.tax_year}`,
                year: file.tax_year,
                file_type: FILE_TYPE,
                sec_file_type: file.return_type,
                object_id: file.object_id,
                meta: {
                  fiscal_month: file.tax_month,
                },
              },
            })
          ).then((res) => {
            if (res.type === "data/create/fulfilled") {
              setFiles((state) =>
                state.map((s) => {
                  if (s.object_id === res.payload.object_id) {
                    return {
                      ...s,
                      data_source_id: res.payload.id,
                      uploading: false,
                    };
                  }
                  return s;
                })
              );
            }
          });
        });
      prevCheckedFiles
        .filter((x) => !checkedFiles.includes(x))
        .forEach((f) => {
          let file = files.find((x) => x.object_id === f);
          if (file) {
            // Removed file
            setFiles((state) =>
              state.map((s) => {
                if (s.object_id === f) {
                  return {
                    ...s,
                    uploading: true,
                  };
                }
                return s;
              })
            );
            dispatch(
              deleteFile({ nodeId: currentNode.id, id: file.data_source_id })
            ).then((res) => {
              if (res.type === "data/delete/fulfilled") {
                setFiles((state) =>
                  state.map((s) => {
                    if (s.object_id === f) {
                      return {
                        ...s,
                        data_source_id: null,
                        uploading: false,
                      };
                    }
                    return s;
                  })
                );
              }
            });
          }
        });
      setPrevCheckedFiles(checkedFiles);
    }
  }, [checkedFiles]);

  const analyze = () => {
    let ids = [];
    if (checkedFiles && checkedFiles.length > 0) {
      checkedFiles.forEach((f) => {
        let id = files.find((x) => x.object_id === f).data_source_id;
        if (id) {
          ids.push(id);
        }
      });
    }
    dispatch(
      analyzeData({
        nodeId: currentNode.id,
        data: { file_type: FILE_TYPE, ids: ids },
      })
    ).then((res) => {
      if (res.type === "data/analyze/fulfilled") {
        navigate("/data/irs/all");
      }
    });
  };

  return (
    <>
      <div className="pt-24 pb-56">
        <div className="px-40">
          <BackButton />
          <div className={`${styles.title} h1 mt-24`}>
            <FileTextOutlined />
            &nbsp;&nbsp;Connect 990 Forms
          </div>
          <div className={`${styles.subTitle}`}>
            Connect these tax forms to help train your AI assistant in saving
            you time on grant applications, reports, and more.
          </div>
          <div className={styles.receiptBox}>
            {step === 2 ? (
              <>
                <div className="h2">
                  Search for your 990 forms in our database
                </div>
                <div className={`${styles.desc} mt-16`}>
                  We're only able to connect 990 forms that have been processed
                  by the IRS.
                </div>
                <div className={`${styles.irsBox} mt-24`}>
                  {loading ? (
                    <div className={styles.loader}>
                      <Loader size={50} />
                    </div>
                  ) : (
                    <>
                      <div className="bigger-text">
                        <span className="fw-600">{files.length}</span>{" "}
                        unconnected forms found.
                      </div>
                      {files.length > 0 ? (
                        <Checkbox.Group
                          className="mt-8"
                          value={checkedFiles}
                          onChange={(val) => setCheckedFiles(val)}
                        >
                          <Row>
                            {files.map((f) => (
                              <Col span={24} key={f.object_id} className="mt-8">
                                {f.uploading ? (
                                  <Checkbox value={f.object_id} disabled>
                                    <Space size={8}>
                                      {f.tax_year}
                                      <Spin
                                        indicator={
                                          <LoadingOutlined
                                            style={{ fontSize: 14 }}
                                            spin
                                          />
                                        }
                                      />
                                    </Space>
                                  </Checkbox>
                                ) : (
                                  <Checkbox value={f.object_id}>
                                    {f.tax_year}
                                  </Checkbox>
                                )}
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      ) : (
                        <div className={styles.support}>
                          If you believe this is an error{" "}
                          <a
                            href="mailto:hello@humanitas.ai"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="link">contact user support.</span>
                          </a>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="mt-24">
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => analyze()}
                    disabled={
                      checkedFiles?.length === 0 ||
                      files.filter((f) => f.uploading).length !== 0
                    }
                  >
                    <Space size={16}>
                      Connect
                      <ArrowRightOutlined />
                    </Space>
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="h2">
                  Search for your 990 forms in our database
                </div>
                <div className={`${styles.desc} mt-16`}>
                  We're only able to connect 990 forms that have been processed
                  by the IRS.
                </div>
                <div className="mt-24">
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => setStep(2)}
                  >
                    <Space size={16}>Search</Space>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
