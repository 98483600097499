import { io } from "socket.io-client";

let HOST = "http://localhost:8080";
if (process.env.NODE_ENV === "production") {
  HOST = "https://assistant.humanitas.ai";
}

export const socket = io(HOST, {
  autoConnect: false,
});
