import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useState } from "react";

export function Troubleshoot(props) {
  const [opened, setOpened] = useState({ 1: false, 2: false, 3: false });

  return (
    <div>
      <Row align="middle">
        <Col span={20}>
          <div className="accent accent-color">Troubleshooting</div>
        </Col>
        <Col span={4} className="ta-r">
          <CloseOutlined onClick={() => props.onCancel()} />
        </Col>
      </Row>
      <div className="mt-8">
        <div className="h2">Troubleshooting responses</div>
      </div>
      <div className="mt-16 sub-color">
        There are a few reasons this could be happening. The way to fix this
        depends on what kind of problem you're experiencing.
      </div>
      <div className="mt-24">
        <div>
          <Row
            className="pointer"
            onClick={() =>
              setOpened((state) => {
                return { ...state, 1: !state[1] };
              })
            }
          >
            <Col span={22}>
              <div className="h3">Irrelevant or repetitive response</div>
            </Col>
            <Col span={2} className="ta-r">
              {opened[1] ? <UpOutlined /> : <DownOutlined />}
            </Col>
          </Row>
          {opened[1] && (
            <div className="mt-16">
              <ul className="mt-16">
                <li>
                  Your AI Assistant might be stuck on one of your earlier
                  prompts.
                </li>
              </ul>
              <ul className="mt-16">
                <li>Try starting a new chat and asking again.</li>
              </ul>
            </div>
          )}
        </div>
        <div className="mt-24">
          <Row
            className="pointer"
            onClick={() =>
              setOpened((state) => {
                return { ...state, 2: !state[2] };
              })
            }
          >
            <Col span={22}>
              <div className="h3">Incorrect response</div>
            </Col>
            <Col span={2} className="ta-r">
              {opened[2] ? <UpOutlined /> : <DownOutlined />}
            </Col>
          </Row>
          {opened[2] && (
            <div className="mt-16">
              <ul className="mt-16">
                <li>
                  If the AI Assistant is interpreting your data wrong, try
                  uploading a version of the file without any graphics or
                  images.
                </li>
              </ul>
              <ul className="mt-16">
                <li>Your AI Assistant may need more data sources.</li>
              </ul>
            </div>
          )}
        </div>
        <div className="mt-24">
          <Row
            className="pointer"
            onClick={() =>
              setOpened((state) => {
                return { ...state, 3: !state[3] };
              })
            }
          >
            <Col span={22}>
              <div className="h3">Response is too short/long/formal etc.</div>
            </Col>
            <Col span={2} className="ta-r">
              {opened[3] ? <UpOutlined /> : <DownOutlined />}
            </Col>
          </Row>
          {opened[3] && (
            <div className="mt-16">
              <ul className="mt-16">
                <li>
                  You're in control of your assistant. Don't be afraid to
                  provide feedback.
                </li>
              </ul>
              <ul className="mt-16">
                <li>Be direct, commanding, and specific with your feedback.</li>
              </ul>
              <ul className="mt-16">
                <li>
                  If the problem continues, try starting a new chat with a more
                  specific prompt.
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
