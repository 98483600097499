import { PlusOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Space, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import tracker from "../../tracker";
import { Needs } from "./Needs";
import styles from "./Registry.module.css";
import { SearchCatalogue } from "./SearchCatalogue";
import { needsSelector } from "./registrySlice";

export function Registry() {
  const { data } = useSelector(needsSelector);
  const [active, setActive] = useState("all");

  useEffect(() => {
    //// TRACK
    tracker.page();
    ///
  }, []);

  return (
    <div className="py-40">
      <div className="px-40">
        <div className={`${styles.title} h1`}>
          <Space size={16}>
            <div className="h1">
              <ShoppingCartOutlined />
            </div>
            <div className="h1">In-Kind Gift Registry</div>
          </Space>
        </div>
        <div className={`${styles.subTitle}`}>
          Update your registry to reflect your organization's changing needs.
          Our retail partners will fill and deliver items according to your
          preferences.
        </div>
      </div>
      <Tabs
        defaultActiveKey="all"
        activeKey={active}
        type="card"
        size="large"
        onTabClick={(val) => {
          setActive(val);
          //// TRACK
          tracker.track(`registry-${val}-tab`);
          ////
        }}
        items={[
          {
            label: data.length ? `Your List (${data.length})` : "Your List",
            key: "all",
            children: (
              <div className={styles.content}>
                <Needs gotoTab={(val) => setActive(val)} />
              </div>
            ),
          },
          {
            label: (
              <Space align="center">
                <div>Add More</div>
                <PlusOutlined className="mr-0 fs-3" />
              </Space>
            ),
            key: "add",
            children: (
              <div>
                <SearchCatalogue />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
