import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Authorize } from "../../../components/authorize/Authorize";
import { Confirm } from "../../../components/confirm/Confirm";
import { Loader } from "../../../components/loader/Loader";
import { Logo } from "../../../components/logo/Logo";
import { authSelector } from "../../auth/authSlice";
import { Permissions } from "../../nav/Nav";
import { navSelector } from "../../nav/navSlice";
import styles from "../Profile.module.css";
import {
  addAdmin,
  adminsSelector,
  clearErrors,
  deleteAdmin,
  getAdmins,
  updateAdmin,
} from "../profileSlice";
import { EditTeam } from "./EditTeam";

export function Team(props) {
  const dispatch = useDispatch();
  const { data, loading, errors } = useSelector(adminsSelector);
  const { currentNode } = useSelector(navSelector);
  const { currentUser } = useSelector(authSelector);
  const [members, setMembers] = useState([]);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [currentMember, setCurrentMember] = useState();
  const [editOpened, setEditOpened] = useState(false);
  const [cancelOpened, setCancelOpened] = useState(false);
  const [currentInvite, setCurrentInvite] = useState();

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error({
        content: errors[0],
        icon: <CloseCircleOutlined />,
      });
      dispatch(clearErrors());
    }
  }, [errors]);

  useEffect(() => {
    if (currentNode.id) {
      dispatch(getAdmins({ id: currentNode.id }));
    }
  }, [dispatch, currentNode]);

  useEffect(() => {
    if (data) {
      setMembers(data.filter((d) => d.user.id && !d.request && true));
      setPendingInvitations(
        data.filter((d) => !d.user.id && !d.request && true)
      );
      setPendingRequests(data.filter((d) => d.request && true));
    }
  }, [data]);

  const openEdit = (a) => {
    setCurrentMember(a);
    setEditOpened(true);
  };

  const closeEdit = () => {
    setEditOpened(false);
    setCurrentMember(null);
  };

  const getRole = (p) => {
    if (p.includes(Permissions.data) && p.includes(Permissions.team)) {
      return "Admin";
    } else if (p.includes(Permissions.data)) {
      return "Editor";
    } else {
      return "Viewer";
    }
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "user",
      render: (u, a) => (
        <Space align="end">
          <Logo type="custom" data={{ name: u.f_name, src: u.avatar }} />
          <Space size={16}>
            <div>
              {u.f_name}&nbsp;{u.l_name}
            </div>
            {a.user.id === currentUser.id && (
              <div className="tag yellow">You</div>
            )}
          </Space>
        </Space>
      ),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: ["user", "email"],
    },
    {
      title: "Role",
      key: "permissions",
      dataIndex: ["permissions"],
      render: (p) => <>{getRole(p)}</>,
    },
  ];

  return (
    <>
      <Row align="bottom">
        <Col span={12}>
          <div className="subtitle desc-color">Team Members</div>
        </Col>
        <Col span={12} className="ta-r">
          <Authorize permission={Permissions.team}>
            <Button
              size="large"
              onClick={() => setEditOpened(true)}
              disabled={
                currentNode?.id &&
                !currentNode?.permissions?.includes(Permissions.team)
              }
            >
              <Space size={16}>
                Invite New Team Member
                <PlusOutlined />
              </Space>
            </Button>
          </Authorize>
        </Col>
      </Row>
      <div className="mt-16">
        <Table
          bordered
          loading={{
            indicator: <Loader size="65" />,
            spinning: loading,
          }}
          pagination={{ position: ["none", "none"] }}
          className={`table-striped`}
          columns={
            currentNode?.id &&
            currentNode?.permissions?.includes(Permissions.team)
              ? [
                  ...columns,
                  {
                    title: <div className="ta-c">Edit</div>,
                    key: "edit",
                    render: (a) => (
                      <div className="ta-c">
                        <FormOutlined
                          onClick={() => {
                            openEdit(a);
                          }}
                        />
                      </div>
                    ),
                  },
                ]
              : columns
          }
          dataSource={members}
        />
      </div>

      {currentNode?.permissions?.includes(Permissions.team) &&
        pendingInvitations.length > 0 && (
          <div className="mt-40">
            <div className="subtitle desc-color">Pending Invitations</div>
            {pendingInvitations.map((m) => (
              <div className={`${styles.pending} mt-16`} key={m.id}>
                <Row align="middle" gutter={16}>
                  <Col span={14}>
                    <div>
                      <Space size={16}>
                        <div className="bold-body ">{m.user.email}</div>
                        <div>
                          Invited&nbsp;
                          {new Date(m.created_at).toLocaleDateString()}
                        </div>
                      </Space>
                    </div>
                    <div className="mt-4">{getRole(m.permissions)}</div>
                  </Col>
                  <Col span={10} className="ta-r">
                    <Space size={16}>
                      <Button
                        size="large"
                        onClick={() =>
                          dispatch(
                            addAdmin({
                              id: currentNode.id,
                              data: { email: m.user.email },
                            })
                          ).then((res) => {
                            if (res.type === "node/admins/create/fulfilled") {
                              message.success({
                                content: "Invitation email sent successfully",
                                icon: <CheckCircleOutlined />,
                              });
                            }
                          })
                        }
                      >
                        Resend
                      </Button>
                      <Button
                        size="large"
                        onClick={() => {
                          setCurrentInvite(m);
                          setCancelOpened(true);
                        }}
                      >
                        Cancel invitation
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        )}

      {currentNode?.permissions?.includes(Permissions.team) &&
        pendingRequests.length > 0 && (
          <div className="mt-40">
            <div className="subtitle desc-color">Incoming Requests</div>
            {pendingRequests.map((m) => (
              <div className={`${styles.pending} mt-16`} key={m.id}>
                <Row align="middle" gutter={16}>
                  <Col span={14}>
                    <div>
                      <Space size={16}>
                        <div>
                          <Space size={16}>
                            <div className="bold-body">
                              Request from {m.user.f_name}&nbsp;{m.user.l_name}
                            </div>
                            <div>
                              Received&nbsp;
                              {new Date(m.created_at).toLocaleDateString()}
                            </div>
                          </Space>
                        </div>
                      </Space>
                    </div>
                  </Col>
                  <Col span={10} className="ta-r">
                    <Button
                      size="large"
                      onClick={() => {
                        setCurrentMember(m);
                        setEditOpened(true);
                      }}
                    >
                      Respond
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        )}

      <Modal
        destroyOnClose
        open={editOpened}
        closable={false}
        footer={null}
        width={840}
        style={{ top: 56 }}
      >
        <EditTeam
          member={currentMember}
          onCancel={() => closeEdit()}
          onSubmit={(val) => {
            if (currentMember?.id) {
              dispatch(
                updateAdmin({
                  id: currentNode.id,
                  adminId: currentMember.id,
                  data: val,
                })
              );
            } else {
              dispatch(addAdmin({ id: currentNode.id, data: val }));
            }
            closeEdit();
          }}
          onDelete={() => {
            dispatch(
              deleteAdmin({ id: currentNode.id, adminId: currentMember.id })
            );
            closeEdit();
          }}
        />
      </Modal>

      <Confirm
        open={cancelOpened}
        header="Team Members"
        title="Cancel team member invitation?"
        desc="This cannot be undone."
        btn="Cancel"
        onConfirm={() => {
          dispatch(
            deleteAdmin({ id: currentNode.id, adminId: currentInvite.id })
          );
          setCancelOpened(false);
        }}
        onCancel={() => setCancelOpened(false)}
      />
    </>
  );
}
