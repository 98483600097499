import React from "react";
import { Route, Routes } from "react-router-dom";
import { ForgotPassword } from "./features/auth/ForgotPassword";
import { Intro } from "./features/auth/Intro";
import { Login } from "./features/auth/Login";
import { RegisterNode } from "./features/auth/RegisterNode";
import { RegisterUser } from "./features/auth/RegisterUser";
import { ResetPassword } from "./features/auth/ResetPassword";
import { Search } from "./features/auth/Search";
import { Verify } from "./features/auth/Verify";
import { NotFound } from "./features/errors/NotFound";
import { Nav } from "./features/nav/Nav";

export const Router = () => (
  <Routes>
    <Route path="/intro" element={<Intro />} />
    <Route path="/login" element={<Login />} />
    <Route exact path="/search" element={<Search />} />
    <Route exact path="/search/:data" element={<Search />} />
    <Route exact path="/register" element={<RegisterUser />} />
    <Route exact path="/register/:data" element={<RegisterNode />} />
    <Route exact path="/forgot_password" element={<ForgotPassword />} />
    <Route exact path="/reset_password/:code" element={<ResetPassword />} />
    <Route exact path="/verify/:code" element={<Verify />} />
    <Route path="/" element={<Nav />} />
    <Route path="/home" element={<Nav />} />
    <Route path="/data/annual/add/:year" element={<Nav />} />
    <Route path="/data/other/add/:id" element={<Nav />} />
    <Route path="/data/*" element={<Nav />} />
    <Route path="/assistant/*" element={<Nav />} />
    <Route path="/registry/*" element={<Nav />} />
    <Route path="/profile" element={<Nav />} />
    <Route path="/profile/:focus" element={<Nav />} />
    <Route path="/account" element={<Nav />} />
    <Route path="/account/:focus" element={<Nav />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
