import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export function BackButton(props) {
  const navigate = useNavigate();

  return (
    <Button
      size="large"
      type="link"
      onClick={() => (props.nolink ? props.onClick() : navigate(-1))}
    >
      <ArrowLeftOutlined />
      &nbsp;Back
    </Button>
  );
}
