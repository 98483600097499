import axios from "axios";

let HOST = "http://localhost:3000/v2";
if (process.env.NODE_ENV === "production") {
  HOST = "https://indra.humanitas.ai/v2";
}

const API = {
  auth: {
    login: (data) =>
      axios.post(`${HOST}/sessions/sign_in`, data, {
        noAuth: true,
        storeCookies: true,
      }),
    logout: () =>
      axios.delete(`${HOST}/sessions/sign_out`, {
        clearCookies: true,
      }),
    forgotPassword: (data) =>
      axios.post(
        `${HOST}/sessions/forgot_password`,
        {
          ...data,
        },
        {
          noAuth: true,
        }
      ),
    register: (data) =>
      axios.post(`${HOST}/sessions`, data, {
        noAuth: true,
        storeCookies: true,
      }),
    resetPassword: (data) =>
      axios.post(`${HOST}/sessions/verify_password`, data, {
        noAuth: true,
        storeCookies: true,
      }),
    validate: () => axios.get(`${HOST}/sessions/validate_token`),
    getUser: () => axios.get(`${HOST}/sessions`),
    update: (data) => axios.put(`${HOST}/sessions`, data),
    verify: (data) => axios.post(`${HOST}/verify`, data),
    verifySend: () => axios.post(`${HOST}/verify_send`),
    adminNodes: () => axios.get(`${HOST}/nodes`),
    requestedNodes: () => axios.get(`${HOST}/requested_nodes`),
    validateEmail: (nodeId, data) =>
      axios.post(`${HOST}/nodes/${nodeId}/validate_email`, data),
    validateCode: (data) => axios.post(`${HOST}/validate_code`, data),
    waitlist: (data) => axios.post(`${HOST}/waitlist`, data),
    has_seen: (id) => axios.post(`${HOST}/has_seen/${id}`),
  },
  profile: {
    get: (id) => axios.get(`${HOST}/nodes/${id}`),
    update: (id, data) => axios.put(`${HOST}/nodes/${id}`, data),
    locationCreate: (id, data) =>
      axios.post(`${HOST}/nodes/${id}/locations`, data),
    admins: {
      get: (id) => axios.get(`${HOST}/nodes/${id}/admins`),
      create: (id, data) => axios.post(`${HOST}/nodes/${id}/admins`, data),
      extRequest: (id, data) =>
        axios.post(`${HOST}/nodes/${id}/ext_admin_request`, data),
      request: (id, data) =>
        axios.post(`${HOST}/nodes/${id}/admin_request`, data),
      claim: (id, data) => axios.post(`${HOST}/nodes/${id}/claim`, data),
      update: (id, adminId, data) =>
        axios.put(`${HOST}/nodes/${id}/admins/${adminId}`, data),
      delete: (id, adminId) =>
        axios.delete(`${HOST}/nodes/${id}/admins/${adminId}`),
    },
  },
  onboarding: {
    index: (nodeId) =>
      axios.get(
        `${HOST}/onboarding?${nodeId !== undefined ? `node_id=${nodeId}` : ``}`
      ),
    create: (nodeId, taskId) =>
      axios.post(
        `${HOST}/onboarding/${taskId}?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
  },
  discover: {
    search: (scope, query, page, per) =>
      axios.get(
        `${HOST}/search?scope=${scope}&query=${query}&page=${page}&per=${per}`
      ),
    publicSearch: (query, page, per) =>
      axios.get(
        `${HOST}/public_search?&query=${query}&page=${page}&per=${per}`
      ),
  },
  needs: {
    index: (nodeId, approved) =>
      axios.get(`${HOST}/nodes/${nodeId}/needs?approved=${approved}`),
    show: (nodeId, id) => axios.get(`${HOST}/nodes/${nodeId}/needs/${id}`),
    create: (nodeId, data) => axios.post(`${HOST}/nodes/${nodeId}/needs`, data),
    update: (nodeId, id, data) =>
      axios.put(`${HOST}/nodes/${nodeId}/needs/${id}`, data),
    delete: (nodeId, id) => axios.delete(`${HOST}/nodes/${nodeId}/needs/${id}`),
    approveAnalyzed: (nodeId) =>
      axios.put(`${HOST}/nodes/${nodeId}/needs_approve_analyzed`),
    deleteAnalyzed: (nodeId) =>
      axios.delete(`${HOST}/nodes/${nodeId}/needs_destroy_analyzed`),
  },
  catalogue: {
    show: (id) => axios.get(`${HOST}/catalogue/${id}`),
  },
  data_sources: {
    presign: (type) => axios.get(`${HOST}/presign?content_type=${type}`),
    create: (nodeId, data) =>
      axios.post(
        `${HOST}/data_sources?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`,
        data
      ),
    index: (nodeId, fileType) =>
      axios.get(
        `${HOST}/data_sources?file_type=${fileType}&${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
    show: (nodeId, dataId) =>
      axios.get(
        `${HOST}/data_sources/${dataId}?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
    update: (nodeId, dataId, data) =>
      axios.put(
        `${HOST}/data_sources/${dataId}?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`,
        data
      ),
    delete: (nodeId, id) =>
      axios.delete(
        `${HOST}/data_sources/${id}?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
    status: (nodeId) =>
      axios.get(
        `${HOST}/data_sources_status?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
    analyze: (nodeId, data) =>
      axios.post(
        `${HOST}/data_sources_analyze?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`,
        data
      ),
    search: (nodeId) =>
      axios.get(
        `${HOST}/data_search_filings?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
  },
  conversations: {
    index: (nodeId, page) =>
      axios.get(
        `${HOST}/conversations?page=${page}&${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
    create: (data) => axios.post(`${HOST}/conversations`, data),
    update: (id, data) => axios.put(`${HOST}/conversations/${id}`, data),
    delete: (id) => axios.delete(`${HOST}/conversations/${id}`),
  },
  chats: {
    index: (conversationId) =>
      axios.get(`${HOST}/conversations/${conversationId}/chats`),
    create: (conversationId, data) =>
      axios.post(`${HOST}/conversations/${conversationId}/chats`, data),
    update: (conversationId, id, data) =>
      axios.put(`${HOST}/conversations/${conversationId}/chats/${id}`, data),
  },
  widgets: {
    data: (nodeId) =>
      axios.get(
        `${HOST}/widget_data?${nodeId !== undefined ? `node_id=${nodeId}` : ``}`
      ),
    assistant: (nodeId) =>
      axios.get(
        `${HOST}/widget_assistant?${
          nodeId !== undefined ? `node_id=${nodeId}` : ``
        }`
      ),
  },
};

export default API;
